import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import './PaymentConfirmation.css';
import { closeModal, setHomeView } from '../../../../store/ui';
import { capitalizeFirstLetter } from '../../../../util/formatting';
import { format, parseISO } from 'date-fns';
import { ReactComponent as RightArrow } from '../../../../assets/svg/chevronRight.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/svg/close.svg';

const PaymentConfirmation = ({ confirmationId }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    
    const order = useSelector(state => 
        Object.values(state?.orders).find(order => order?.confirmationId === confirmationId) || null
    );
    const address = useSelector(state => state.addresses[order?.addressId]);
    const isVendor = useSelector(state => state.session?.user.userType === 'vendor');
    const vendor = useSelector(state => {
        if (isVendor) {
            return state.session.vendor;
        }
        return state.vendors[order?.vendorId] 
            ? state.vendors[order.vendorId]
            : state.session.vendor;
    });
    const options = order?.optionsSnapshot ? order.optionsSnapshot : {};
    const formatPhoneNumber = phoneNumber => phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

    let formattedDate = () => {
        const appointmentAtWithoutTimezone = order?.appointmentAt?.replace(/([-+]\d{2}:\d{2})/, '');
        if (order?.appointmentAt) {
            return format(parseISO(appointmentAtWithoutTimezone), "MMMM do, h:mmaaa");
        }
    };

    const viewAllOrders = () => {
        dispatch(closeModal());
        if (isVendor) {
            history.push('/dashboard/orders');
            dispatch(setHomeView('vendor'));
        } else {
            history.push('/orders');
        }
    };

    return (
        <div className="payment-confirmation-all">
            <div className="top-payment-confirmation">
                <div className="order-confirmation">
                    <div className="confirmation-header-container">
                        <img src="https://spencerheywood.com/images/servo/Pictures/web_optimized/servo_light_blue_logo.png" style={{height: '150px', width: '150px'}}/>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <h1 style={{fontSize: '24px', fontWeight:'bold'}}>Order Confirmation</h1>
                            {order && 
                                <p style={{color: 'var(--secondary-gray)', marginTop: '5px'}}>Order ID #{order?.id}</p>
                            }
                            <div className="service-details">
                                <h3 style={{marginBottom:'5px', fontSize:'15px'}}>Service</h3>
                                <p>{vendor?.name || 'Vendor Name'}</p>
                                <p>{formatPhoneNumber(vendor?.phoneNumber) || ''}</p>
                                <p>{vendor?.email || ''}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <CloseIcon onClick={() => dispatch(closeModal())} className="close-icon"/>
            </div>
            {order ? 
                <div className="payment-confirmation">
                    <div className="left-column">
                        <div className="appointment-details">
                            <h3>Appointment</h3>
                            <p>{address?.streetAddress} {address?.streetAddress2}</p>
                            <p style={{marginTop:'0px'}}>{address?.city} {address?.state}, {address?.zipCode}</p>
                            <p>{formattedDate()}</p>
                            <p>${order?.totalCollected} - Paid</p>
                        </div>
                        <div className="instructions">
                            <h3>Instructions</h3>
                            <p>{vendor?.appointmentInstructions || `If you have any special requirements or questions, feel free to reach out directly to ${vendor?.name}.`}</p>
                        </div>
                    </div>
                    <div className="right-column">
                        <div className="additional-details">
                            <h2 className='details-payment-confirmation'>Details</h2>
                            <div className="details-grid">
                                {options && Object.entries(options).map((entry, idx) => (
                                    <div className='index-item-info' key={idx}>
                                        <p>{capitalizeFirstLetter(entry[0])}</p>
                                        <p>{entry[1]}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="notes">
                            <h3>Notes</h3>
                            <p>{order?.customerNotes || "N/A"}</p>
                        </div>
                    </div>
                </div>
            : 
                <p style={{margin: '80px 0', fontSize: '18px', textAlign:'center', fontWeight:'bold', marginTop: '-30px'}}>Confirming Your Order...</p>
            }
            <button className='view-all-orders-button' onClick={viewAllOrders}>View Orders <RightArrow style={{margin: '0 -10px 0 5px'}}/></button>
        </div>
    );
};

export default PaymentConfirmation;