import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './DashboardGeneral.css';
import { updateVendor, updateVendorPicture } from '../../../../store/vendor';
import { formatPhoneNumber } from '../../../../util/formatting';
import { ReactComponent as StarSvg } from '../../../../assets/svg/reviewStar.svg';
import DashboardHeader from '../DashboardHeader';
import ImagesStepGallery from '../DashboardSetup/Steps/ImagesStep/ImagesStepGallery';
import { closeModal, openModal } from '../../../../store/ui';
import csrfFetch from '../../../../store/csrf';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import {
    ConnectAccountManagement,
    ConnectComponentsProvider,
    ConnectNotificationBanner,
    ConnectAccountOnboarding,
} from '@stripe/react-connect-js';
import ToolTip from '../../../common/ToolTip';

const DashboardGeneral = ({ vendor }) => {
    const dispatch = useDispatch();
    const vendorId = vendor?.id;
    const user = useSelector((state) => state?.session?.user);
    const [logoPreview, setLogoPreview] = useState(vendor?.logoImageUrl);
    const [bannerPreview, setBannerPreview] = useState(
        vendor?.thumbnailImageUrl
    );
    const [isUploading, setIsUploading] = useState(false);
    const [isConnected, setIsConnected] = useState(vendor?.stripeIntegrated);
    const [openForm, setOpenForm] = useState(null);
    const [formData, setFormData] = useState({
        name: vendor?.name,
        phoneNumber: vendor?.phoneNumber,
        email: vendor?.email,
    });

    const [tab, setTab] = useState('business');

    const [clientSecret, setClientSecret] = useState(null);
    const [stripeConnectInstance, setStripeConnectInstance] = useState(null);

    const [openServiceDescription, setOpenServiceDescription] = useState(null);
    const [serviceDescription, setServiceDescription] = useState({
        serviceDescription: vendor?.serviceDescription,
    });

    const toggleListingStatus = () => {
        const updatedStatus =
            vendor.listingStatus === 'active' ? 'inactive' : 'active';
        const vendorData = {
            listingStatus: updatedStatus,
            id: vendorId,
        };

        if (vendor.listingStatus === 'active') {
            dispatch(
                openModal('confirm-password-modal', {
                    onButtonClick: (confirmPassword) =>
                        dispatch(updateVendor(vendorData, confirmPassword)),
                    headerAndButtonText: 'Deactivate Listing',
                    confirmationText:
                        'Are you sure you would like to deactivate your listing status?',
                })
            );
        } else {
            dispatch(updateVendor(vendorData));
        }
    };

    const fetchClientSecret = async () => {
        try {
            const response = await csrfFetch(
                `/api/vendors/create_account_session`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ id: vendorId }),
                }
            );

            if (!response.ok) {
                const { error } = await response.json();
                console.error('An error occurred: ', error);
                return;
            } else {
                const { client_secret: secret } = await response.json();
                setClientSecret(secret);
            }
        } catch (error) {
            console.error('Error fetching client secret:', error);
        }
    };

    useEffect(() => {
        if (clientSecret && !stripeConnectInstance) {
            const fetchConnectInstance = async () => {
                try {
                    const instance = await loadConnectAndInitialize({
                        publishableKey:
                            process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
                        appearance: {
                            variables: {
                                colorPrimary: '#A3D3E5',
                                colorText: '#23254E',
                                colorBackground: '#FFFFFF',
                                buttonPrimaryColorBackground: '#23254E',
                                actionPrimaryColorText: '#F4BC46',
                                badgeSuccessColorBackground: '#63C466',
                                badgeSuccessColorBorder: '#63C466',
                                badgeWarningColorBorder: '#F4BC46',
                                // borderRadius: "8px",
                                // formBorderRadius: "8px",
                                // badgeBorderRadius: "8px",
                                // overlayBorderRadius: "8px",
                                fontFamily: 'sans-serif',
                                headingXlFontSize: '16px',
                                headingLgFontSize: '16px',
                                bodyMdFontSize: '14px',
                                bodySmFontSize: '14px',
                                labelMdFontSize: '14px',
                            },
                        },
                        fetchClientSecret: () => clientSecret,
                    });
                    setStripeConnectInstance(instance);
                    setIsConnected('onboarding');
                } catch (error) {
                    console.error(
                        'Error initializing Connect instance:',
                        error
                    );
                    setIsConnected('inactive');
                }
            };

            fetchConnectInstance();
        }
    }, [clientSecret, stripeConnectInstance]);

    useEffect(() => {
        fetchClientSecret();
    }, []);

    useEffect(() => {
        // Check if vendor.stripeIntegration is active
        if (vendor?.stripeIntegrated === 'active') {
            setIsConnected('active');
        }
        if (vendor?.stripeIntegrated === 'inactive') {
            setIsConnected('inactive');
        }
    }, [vendor, vendor?.stripeIntegrated]);

    useEffect(() => {
        if (vendor?.logoImageUrl) {
            setLogoPreview(vendor.logoImageUrl);
        }
        if (vendor?.thumbnailImageUrl) {
            setBannerPreview(vendor.thumbnailImageUrl);
        }
    }, [vendor]);

    useEffect(() => {
        setFormData({
            name: vendor?.name,
            phoneNumber: vendor?.phoneNumber,
            email: vendor?.email,
        });

        setServiceDescription({
            serviceDescription: vendor?.serviceDescription,
        });
    }, [vendor]);

    const handleServiceDescriptionSubmit = (e) => {
        e.preventDefault();
        const updatedServiceDescription = {
            ...serviceDescription,
            id: vendorId,
        };
        dispatch(updateVendor(updatedServiceDescription));
        setOpenServiceDescription(null);
    };

    const handleServiceDescriptionChange = (e) => {
        const value = e.target.value.slice(0, 500);
        setServiceDescription((prev) => ({
            ...prev,
            serviceDescription: value,
        }));
    };

    const serviceDescriptionForm = () => {
        return (
            <form className={openServiceDescription ? 'info-form' : 'minimize'}>
                <textarea
                    placeholder='Update service description'
                    value={serviceDescription.serviceDescription || ''}
                    onChange={handleServiceDescriptionChange}
                    rows={9}
                />
                <div className='save-service-description'>
                    <p>Max Characters: 500</p>
                    <button
                        className='edit-button info-submit-button'
                        onClick={handleServiceDescriptionSubmit}
                    >
                        Save
                    </button>
                </div>
            </form>
        );
    };

    const handleInfoSubmit = (e) => {
        e.preventDefault();
        let vendorData = {
            ...formData,
            id: vendorId,
        };
        dispatch(updateVendor(vendorData));
        setOpenForm(null);
    };

    const handleFormChange = (attribute, value) => {
        setFormData((prev) => ({
            ...prev,
            [attribute]: value,
        }));
    };

    const handleLogoImage = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setLogoPreview(reader.result); // Set preview immediately
                setIsUploading(true); // Start uploading indication
                const formData = new FormData();
                formData.append('vendor[logoImageUrl]', file);
                formData.append('id', vendorId);
                dispatch(updateVendorPicture(formData)).then(() => {
                    setIsUploading(false); // End uploading indication once uploaded
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleBannerImage = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setBannerPreview(reader.result); // Set preview immediately
                setIsUploading(true); // Start uploading indication
                const formData = new FormData();
                formData.append('vendor[thumbnailImageUrl]', file);
                formData.append('id', vendorId);
                dispatch(updateVendorPicture(formData)).then(() => {
                    setIsUploading(false); // End uploading indication once uploaded
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const infoForm = () => {
        return (
            <form className={openForm === 'Info' ? 'info-form' : 'minimize'}>
                <input
                    placeholder='Name'
                    type='text'
                    value={formData.name}
                    onChange={(e) => handleFormChange('name', e.target.value)}
                />
                <input
                    placeholder={vendor?.phoneNumber}
                    type='text'
                    value={formData.phoneNumber}
                    onChange={(e) =>
                        handleFormChange('phoneNumber', e.target.value)
                    }
                />
                <input
                    placeholder={vendor?.email}
                    type='text'
                    value={formData.email}
                    onChange={(e) => handleFormChange('email', e.target.value)}
                />
                <button
                    className='edit-button info-submit-button'
                    onClick={handleInfoSubmit}
                >
                    Save
                </button>
            </form>
        );
    };

    const businessTab = () => {
        return (
            <>
                <div className='general-left'>
                    <div className='general-business-info-header'>
                        <h2>Business Information</h2>
                        {openForm !== 'Info' && (
                            <button
                                className='general-info-edit-button'
                                onClick={() => setOpenForm('Info')}
                            >
                                Edit
                            </button>
                        )}
                    </div>
                    <div className='general-info-container'>
                        <div className={openForm === 'Info' ? 'minimize' : ''}>
                            <div className='general-business-info-line'>
                                <p className='general-attribute'>Name</p>
                                <p>{vendor?.name}</p>
                            </div>
                            <div className='general-business-info-line'>
                                <p className='general-attribute'>
                                    Phone Number
                                </p>
                                <p>{formatPhoneNumber(vendor?.phoneNumber)}</p>
                            </div>
                            <div className='general-business-info-line'>
                                <p className='general-attribute'>Email</p>
                                <p>{vendor?.email}</p>
                            </div>
                        </div>
                        {infoForm()}
                        {!openForm ? (
                            <div className='update-password-general-div user-account'>
                                <span
                                    className='fake-link'
                                    onClick={() =>
                                        dispatch(
                                            openModal('update-password-form', {
                                                user,
                                            })
                                        )
                                    }
                                >
                                    Update Password
                                </span>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className='general-service-description-header'>
                        <h2>Service Description</h2>
                        {openServiceDescription !== true && (
                            <button
                                className='general-info-edit-button'
                                onClick={() => setOpenServiceDescription(true)}
                            >
                                Edit
                            </button>
                        )}
                    </div>
                    <div className='general-info-container service-description-container'>
                        {openServiceDescription ? (
                            serviceDescriptionForm()
                        ) : (
                            <div className='service-description-general-tab'>
                                <p>
                                    {vendor?.serviceDescription ||
                                        'No service description provided.'}
                                </p>
                            </div>
                        )}
                    </div>
                    <div className='general-header-generic general-service-description-header'>
                        <h2>Direct Deposit</h2>
                    </div>
                    <div className='general-info-container bank-account-container'>
                        {vendor.stripeIntegrated === 'active' ? (
                            <div className='connected-stripe'>
                                <div className='status-circle'></div>
                                <div className='connected-stripe'>
                                    Connected
                                </div>
                            </div>
                        ) : (
                            <div className='not-connected-stripe'>
                                <div className='status-circle-red'></div>
                                <div className='not-connected-stripe'>
                                    Not Connected
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='general-header-generic '>
                        <h2 className='listing-status-header'>
                            Listing Status
                        </h2>
                        <button
                            className={`listing-status-button ${vendor?.listingStatus === 'active' ? 'deactivate-listing-button' : 'reactivate-listing-button'}`}
                            onClick={toggleListingStatus}
                        >
                            {vendor?.listingStatus === 'active'
                                ? 'Deactivate'
                                : 'Reactivate'}
                        </button>
                    </div>
                </div>
                <div className='direct-deposit-form'>
                    <div className='general-business-info-header'>
                        <h2>Direct Deposit Information</h2>
                    </div>
                    <div className='custom-island'>{financesTab()}</div>
                </div>
            </>
        );
    };

    const brandingTab = () => {
        return (
            <div className='general-right images-step'>
                <div>
                    <div className='general-header-gallery'>
                        <h2>Gallery</h2>
                    </div>
                    <ImagesStepGallery
                        vendor={vendor}
                        setIsUploading={setIsUploading}
                    />
                </div>
                <div className='general-right-bottom'>
                    <div>
                        <div className='general-header-generic banner-header'>
                            <h2>Banner</h2>
                            <ToolTip
                                message={
                                    'Choose an image that shows your service in action'
                                }
                            />
                        </div>
                        <div className='upload-banner-image-div'>
                            <li className='vendor-index-item'>
                                <div className='vendor-index-image-container'>
                                    <img
                                        src={bannerPreview}
                                        alt={`${vendor?.name} vendor banner`}
                                        onClick={() =>
                                            document
                                                .getElementById(
                                                    'thumbnailFileInput'
                                                )
                                                .click()
                                        }
                                    />
                                    <input
                                        type='file'
                                        id='thumbnailFileInput'
                                        accept='image/*'
                                        onChange={handleBannerImage}
                                        style={{ display: 'none' }}
                                    />
                                </div>
                                <div className='vendor-index-item-meta-info-container'>
                                    <img
                                        src={vendor?.logoImageUrl}
                                        alt={`${vendor?.name} logo`}
                                    />
                                    <div className='vendor-index-item-meta-info'>
                                        <h3>{vendor?.name}</h3>
                                        <span className='macro-review-container'>
                                            <p>0.0</p>
                                            <StarSvg className='review-star-svg' />
                                            <p>(0)</p>
                                        </span>
                                    </div>
                                </div>
                            </li>
                        </div>
                    </div>
                    <div>
                        <div className='general-header-generic'>
                            <h2>Logo</h2>
                        </div>
                        <div className='general-info-container general-info-container-logo'>
                            <div className='general-logo-image-div'>
                                <div className='general-logo-container'>
                                    <img
                                        className='provider-logo'
                                        src={logoPreview}
                                        alt={`${vendor?.name} logo`}
                                        onClick={() =>
                                            document
                                                .getElementById('logoFileInput')
                                                .click()
                                        }
                                    />
                                    <input
                                        type='file'
                                        id='logoFileInput'
                                        accept='image/*'
                                        onChange={handleLogoImage}
                                        style={{ display: 'none' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const financesTab = () => {
        return (
            <div className='account-management'>
                {stripeConnectInstance && (
                    <ConnectComponentsProvider
                        connectInstance={stripeConnectInstance}
                    >
                        {vendor.stripeIntegrated === 'active' ? (
                            <>
                                <ConnectNotificationBanner />
                                <ConnectAccountManagement />
                            </>
                        ) : (
                            <ConnectAccountOnboarding />
                        )}
                    </ConnectComponentsProvider>
                )}
            </div>
        );
    };

    return (
        <>
            <DashboardHeader pageTitle={'General Settings'}>
                <div style={{ display: 'flex', flexDirection: 'column' }}></div>
            </DashboardHeader>
            <div className='dashboard-general'>
                <div className='tab-selector'>
                    <ul>
                        <li
                            onClick={() => setTab('business')}
                            className={tab !== 'business' ? 'inactive' : ''}
                            style={{ cursor: 'pointer' }}
                        >
                            Business
                        </li>
                        <li
                            onClick={() => setTab('branding')}
                            className={tab !== 'branding' ? 'inactive' : ''}
                            style={{ cursor: 'pointer' }}
                        >
                            Visual Content
                        </li>
                    </ul>
                </div>
                <div className='dashboard-general-current-tab'>
                    {tab === 'business' && businessTab()}
                    {tab === 'branding' && brandingTab()}
                </div>
            </div>
        </>
    );
};

export default DashboardGeneral;
