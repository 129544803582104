import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updatePassword } from '../../../../store/users';
import './UpdatePasswordForm.css';
import UpdateFormErrors from '../UpdateFormErrors/UpdateFormErrors';
import { ModalHeader } from '../../../common/Modal';
import useLoading from '../../../../hooks/useLoading';

const UpdatePasswordForm = ({ props }) => {
    const { user } = props;

    const dispatch = useDispatch();

    const { isLoading, startLoading, stopLoading } = useLoading();
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const handleUpdatePassword = (e) => {
        e.preventDefault();

        if (isLoading) return;

        startLoading();

        let updatedPasswordData = {
            password: currentPassword,
            newPassword,
            userId: user.id,
        };

        dispatch(updatePassword(updatedPasswordData)).finally(() =>
            stopLoading()
        );
    };

    return (
        <form onSubmit={handleUpdatePassword} id='update-password-form'>
            <ModalHeader headerText={'Update Password'} />
            <input
                type='password'
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                placeholder='Current Password'
            />
            <input
                type='password'
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder='New Password'
            />
            <input
                type='password'
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                placeholder='Confirm New Password'
            />
            {newPassword !== confirmNewPassword && (
                <p className='errors'>
                    Confirm Password field must be the same as the Password
                    field
                </p>
            )}
            <UpdateFormErrors />
            <h3 className='suggestion'>Password Security Tips:</h3>
            <ul id='suggestions'>
                <li>1 letter</li>
                <li>1 number or special character (example: # ? ! &)</li>
                <li>10 characters</li>
            </ul>
            <button
                type='submit'
                disabled={
                    currentPassword === '' ||
                    newPassword === '' ||
                    confirmNewPassword === '' ||
                    newPassword !== confirmNewPassword
                }
                className='gray-out submit-button'
            >
                {isLoading ? 'Updating Password...' : 'Update Password'}
            </button>
        </form>
    );
};

export default UpdatePasswordForm;
