import { useEffect, useState } from 'react';
import './ProviderPricing.css';
import Selector from '../../../forms/formComponents/Selector';
import RadioButton from '../../../forms/formComponents/RadioButton';
import RangeSlider from '../../../forms/formComponents/RangeSlider';
// import Checkbox from '../../formComponents/Checkbox';
import { createBooking, updateBooking } from '../../../../store/bookings';
import { useDispatch, useSelector } from 'react-redux';
import CalculatorResults from './CalculatorResults/index';
import { setGuestInputs } from '../../../../store/session';
import { updateUserInputs } from '../../../../store/addresses';

const ProviderPricing = ({
    pricingOpen,
    vendor,
    onContinue,
    booking,
    activeAddress,
    setIsCalendarLoading,
}) => {
    const dispatch = useDispatch();
    const addressId = Number(activeAddress?.id);
    const basePrice = vendor?.minPrice;
    const formula = vendor?.pricingFormula ? vendor.pricingFormula : 'x';
    const inputs = vendor?.pricingInputs;
    const [inputValues, setInputValues] = useState({});
    const [parsedInputValues, setParsedInputValues] = useState({});
    const [userNotes, setUserNotes] = useState('');
    // const [checkboxValues, setCheckboxValues] = useState({});
    const [calculatedPrice, setCalculatedPrice] = useState(basePrice);
    const inputFloats = Object.values(inputValues).map((val) =>
        parseFloat(val)
    );
    const currentUserId = useSelector((state) => state.session.user?.id);
    const priceToDurationRate = vendor?.priceToDurationRate
        ? vendor.priceToDurationRate
        : 35;
    const duration = ((calculatedPrice / priceToDurationRate) * 60).toFixed(0);

    const countNotRecurringInputs = (inputs) => {
        if (inputs) {
            return Object.values(inputs).filter((input) => !input.recurring)
                .length;
        }
    };

    useEffect(() => {
        if (inputs && inputFloats.every((val) => !isNaN(val))) {
            let parsedFormula = formula.replace(
                /([A-Z_]+)/g,
                (match, alias) => {
                    return inputValues[alias] || '1';
                }
            );

            // Replace 'x' with basePrice
            // parsedFormula = parsedFormula.replace(/x/g, basePrice.toString());

            try {
                let finalPrice = eval(parsedFormula);
                setCalculatedPrice(
                    finalPrice < basePrice ? basePrice : finalPrice
                );
            } catch (e) {
                console.error('Error evaluating formula:', e);
            }
        }
    }, [inputValues, formula, vendor]);

    const inputTypeKey = {
        radio: RadioButton,
        select: Selector,
        range: RangeSlider,
    };

    // Used to calculate the price difference for add-on items like screen cleaning
    // useEffect(()=> {
    //     if(inputs){
    //         const newCheckboxValues = {}
    //         Object.values(inputs).forEach(input => {
    //             if(input.inputType === 'checkbox'){
    //                     newCheckboxValues[input.id] = Object.values(input.options)[0].value
    //             }
    //         })
    //         setCheckboxValues(newCheckboxValues)
    //     }
    // },[inputs])

    const handleInputChange = (input, parsedOption) => {
        if (
            input &&
            input.alias &&
            parsedOption &&
            parsedOption.label !== undefined &&
            parsedOption.name !== undefined
        ) {
            // Keep track of the user selected input value to use as prefill data in the future
            setInputValues((prevValues) => ({
                ...prevValues,
                [input.alias]: parsedOption.value,
            }));

            // Save a parsed version to display back to the user at summary
            setParsedInputValues((prevValues) => ({
                ...prevValues,
                [parsedOption.label]: parsedOption.name,
            }));
        }
    };

    const renderInput = (input) => {
        const InputComponent = inputTypeKey[input.inputType];
        const prefilledUserInputs = activeAddress?.userInputs;
        const prefilledUserInput = prefilledUserInputs?.[input.id]?.valueString;

        if (!InputComponent) {
            return <p>Unknown InputType</p>;
        } else {
            return (
                <InputComponent
                    name={input?.name}
                    options={input?.pricingInputOptions}
                    key={input?.id}
                    onChange={(value) => handleInputChange(input, value)}
                    prefilledUserInput={prefilledUserInput}
                />
            );
        }
    };

    const handleNotesChange = (e) => {
        setUserNotes(e.target.value);
    };

    const getUserInputs = () => {
        const userInputs = {};

        Object.entries(inputValues).forEach(([alias, value]) => {
            const inputId = Object.values(inputs).find(
                (input) => input.alias === alias
            ).id;
            const input = inputs[inputId];

            if (input.inputType === 'select' || input.inputType === 'radio') {
                // find the name in pricingInputOptions based on the value
                const name = Object.values(input.pricingInputOptions).find(
                    (option) => option.value === Number(value)
                ).name;
                userInputs[inputId] = name;
            } else if (input.inputType === 'range') {
                userInputs[inputId] = value;
            }
        });

        return userInputs;
    };

    const handleContinueClick = () => {
        let bookingData = {
            userId: currentUserId,
            addressId: addressId,
            optionsSnapshot: JSON.stringify(parsedInputValues),
            price: calculatedPrice,
            vendorId: vendor.id,
            status: 'priced',
            duration,
            customerNotes: userNotes,
        };
        let bookingObject = {
            booking: bookingData,
        };

        if (booking) {
            bookingObject.booking.id = booking.id;
            dispatch(updateBooking(bookingObject));
        } else {
            dispatch(createBooking(bookingObject));
        }

        const userInputs = getUserInputs();

        if (currentUserId) {
            dispatch(updateUserInputs(userInputs, addressId));
        } else {
            dispatch(setGuestInputs(userInputs));
        }

        onContinue({ bypass: true });
        if (parseInt(duration) === booking?.duration) setIsCalendarLoading(false);
    };

    return (
        <div className={`pricing-calculator ${pricingOpen ? '' : 'minimize'}`}>
            <div className='calculator-left'>
                <h3>Instant Pricing</h3>
                <div className='calculator-main'>
                    <form>
                        {inputs &&
                            Object.values(inputs).map((input) => {
                                if (!input?.recurring)
                                    return renderInput(input);
                                else return null;
                            })}
                        <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                        >
                            <label
                                htmlFor='service-notes'
                                style={{
                                    fontSize: '14px',
                                    margin: '10px 0',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {'Additional Instructions (Optional)'}
                            </label>
                            <textarea
                                id='service-notes'
                                className='textarea'
                                onChange={handleNotesChange}
                                value={userNotes}
                            ></textarea>
                        </div>
                    </form>
                </div>
            </div>
            <div className='calculator-right'>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                    }}
                >
                    <CalculatorResults
                        price={calculatedPrice?.toFixed(2)}
                        duration={(
                            calculatedPrice / priceToDurationRate
                        ).toFixed(1)}
                    />
                    <button
                        className='gray-out accept-button'
                        onClick={handleContinueClick}
                        disabled={
                            Object.values(inputValues).length !==
                            countNotRecurringInputs(inputs)
                        }
                    >
                        Continue
                    </button>
                    <div className='pricing-disclaimers'>
                        <p>
                            In cases where an adjustment is necessary, it will
                            be confirmed with you prior to your service.
                        </p>
                        {/* <br/> */}
                        <p style={{ marginTop: '10px' }}>
                            The provided time is an estimate, additional
                            technicians may decrease time significantly.{' '}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProviderPricing;
