import './DashboardHeader.css';

const DashboardHeader = ({ pageTitle, children }) => {
    return (
        <div className='dashboard-header'>
            <h2>{pageTitle}</h2>
            {children}
        </div>
    );
};

export default DashboardHeader;
