import { useEffect, useState } from 'react';
import VendorSelector from '../../VendorFormComponents/VendorSelector';
import VendorRangeSlider from '../../VendorFormComponents/VendorRangeSlider';
import VendorRadioButton from '../../VendorFormComponents/VendorRadioButton';
import { parseFormulaAndCalculatePrice } from '../../../../util/vendors/formulaParser';

const PricingCalculator = ({
  formula,
  minPrice,
  setAllStagedOptions,
  setOptionsNeedUpdating,
  inputValues,
  setInputValues,
  inputs,
}) => {
  const [calculatedPrice, setCalculatedPrice] = useState(minPrice);

  // recalculate price when inputValues, formula, minPrice change
  useEffect(() => {
    if (formula) {
      const finalPrice = parseFormulaAndCalculatePrice(
        formula,
        inputValues,
        minPrice
      );
      setCalculatedPrice(finalPrice);
    }
  }, [inputValues, formula, minPrice]);

  const RenderInput = (input) => {
    const inputTypeKey = {
      radio: VendorRadioButton,
      select: VendorSelector,
      range: VendorRangeSlider,
    };

    const InputComponent = inputTypeKey[input.inputType];

    const [stagedOptions, setStagedOptions] = useState(
      input?.pricingInputOptions
    );

    const isDiffValues = (optionId, val) =>
      input.pricingInputOptions[optionId].value !== parseFloat(val);

    const handleValueChange = (optionId, e) => {
      const newValue = e.target.value;

      setStagedOptions((prevOptions) => {
        const updatedOptions = { ...prevOptions };
        updatedOptions[optionId] = {
          ...updatedOptions[optionId],
          value: parseFloat(newValue),
        };
        return updatedOptions;
      });

      handleInputChange(input, { value: parseFloat(newValue) });

      if (input.inputType !== 'range')
        setOptionsNeedUpdating(isDiffValues(optionId, newValue));

      setAllStagedOptions((prev) => ({
        ...prev,
        ...{ [optionId]: parseFloat(newValue) },
      }));
    };

    const handleInputChange = (input, parsedOption) => {
      setInputValues((prevValues) => {
        const recurring = prevValues[input.alias].recurring;

        return {
          ...prevValues,
          [input.alias]: {
            value: parsedOption.value,
            recurring: recurring,
          },
        };
      });
    };

    return (
      <InputComponent
        name={input?.name}
        stagedOptions={stagedOptions}
        handleInputChange={(val) => handleInputChange(input, val)}
        handleValueChange={handleValueChange}
        key={input?.id}
        toolTip={input?.alias}
      />
    );
  };

  return (
    <div className='pricing-calculator'>
      <div className='pricing-calculator-top'>
        <h3>Pricing Calculator</h3>
        <span>${calculatedPrice?.toFixed(2)}</span>
      </div>
      <div className='pricing-calculator-bottom'>
        {inputs &&
          Object.values(inputs).map((input) => {
            if (!input?.recurring) return RenderInput(input);
            return null;
          })}
      </div>
    </div>
  );
};

export default PricingCalculator;
