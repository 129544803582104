import './DashboardPricing.css';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import DashboardHeader from '../DashboardHeader';
import PricingCalculator from './PricingCalculator';
import {
    fetchVendor,
    updatePricingOptions,
    updateVendor,
} from '../../../../store/vendor';
import { openModal } from '../../../../store/ui';
import { parseFormulaAndCalculatePrice } from '../../../../util/vendors/formulaParser';

const DashboardPricing = ({ vendor }) => {
    const dispatch = useDispatch();
    const [minPrice, setMinPrice] = useState(vendor?.minPrice || 35);
    const stagedFormula = vendor?.stagedFormula;
    const formula = stagedFormula || vendor.pricingFormula;
    const inputs = vendor?.pricingInputs;
    const defaultInputValuesObj = {};
    if (inputs) {
        for (const input of Object.values(inputs)) {
            defaultInputValuesObj[input.alias] = {
                value: 1,
                recurring: input.recurring,
            };
        }
    }
    const [inputValues, setInputValues] = useState(defaultInputValuesObj); // quick access to alias' value
    const [optionsNeedUpdating, setOptionsNeedUpdating] = useState(false);
    const [allStagedOptions, setAllStagedOptions] = useState({});
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [trigger, setTrigger] = useState(0);

    const isFormulaValid = (formula) => {
        if (
            parseFormulaAndCalculatePrice(formula, inputValues, minPrice) ===
            null
        )
            return false;
        return true;
    };

    useEffect(() => {
        // disable save button if nothing has been updated
        const isDisabled =
            (!stagedFormula || stagedFormula === vendor?.pricingFormula) &&
            !optionsNeedUpdating &&
            minPrice === vendor?.minPrice;
        setIsSaveDisabled(isDisabled);
    }, [
        stagedFormula,
        optionsNeedUpdating,
        minPrice,
        vendor?.minPrice,
        vendor?.pricingFormula,
    ]);

    const handleSave = () => {
        if (optionsNeedUpdating)
            dispatch(updatePricingOptions(vendor.id, allStagedOptions));
        if (minPrice !== vendor?.minPrice || stagedFormula) {
            const updatedVendor = {
                id: vendor.id,
                minPrice,
                pricingFormula: stagedFormula,
            };
            dispatch(updateVendor(updatedVendor));
        }
        setOptionsNeedUpdating(false);
    };

    const handleDiscardEdits = () => {
        dispatch(fetchVendor(vendor.id, 'updateSessionVendor')); // get rid of stagedFormula in redux
        setMinPrice(vendor?.minPrice);
        setTrigger((prevKey) => prevKey + 1);
        setAllStagedOptions({});
        setOptionsNeedUpdating(false);
    };

    return (
        <>
            <DashboardHeader pageTitle={'Pricing Preferences'}>
                <button disabled={isSaveDisabled} onClick={handleSave} className='gray-out-inverse'>
                    Save
                </button>
            </DashboardHeader>
            <div id='dashboard-pricing' key={trigger}>
                <PricingCalculator
                    minPrice={minPrice}
                    formula={formula}
                    setOptionsNeedUpdating={setOptionsNeedUpdating}
                    setAllStagedOptions={setAllStagedOptions}
                    inputValues={inputValues}
                    setInputValues={setInputValues}
                    inputs={inputs}
                />
                <div className='dashboard-pricing-right'>
                    <h3>Options</h3>
                    <div className='min-price-div'>
                        <span>Min Price</span>
                        <span>$</span>
                        <input
                            type='number'
                            value={minPrice}
                            onChange={(e) =>
                                setMinPrice(parseFloat(e.target.value))
                            }
                            step={0.5}
                        />
                    </div>
                    <span
                        onClick={() =>
                            dispatch(
                                openModal('pricing-formula', {
                                    formula,
                                    isFormulaValid,
                                })
                            )
                        }
                        className='fake-link'
                    >
                        Edit Formula
                    </span>
                    <span className='fake-link' onClick={handleDiscardEdits}>
                        Revert to Previous Save
                    </span>
                </div>
            </div>
        </>
    );
};

export default DashboardPricing;
