import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './RescheduleModal.css';
import { format, isSameDay, parseISO, isEqual } from 'date-fns';
import { fetchCalendarData } from '../../../../store/vendor';
import { ModalHeader } from '../../../common/Modal';
import { rescheduleOrder } from '../../../../store/orders';

const RescheduleModal = ({ props }) => {
    const { order } = props;

    const dispatch = useDispatch();

    const isVendor = useSelector(
        (state) => state.session?.user.userType === 'vendor'
    );
    const vendor = useSelector((state) => state.session?.vendor);
    const calendarData = useSelector((state) => {
        if (isVendor) {
            return vendor?.calendar?.calendarData;
        } else {
            return state?.vendors[order?.vendorId]?.calendar?.calendarData;
        }
    });

    const [isCalendarDataLoading, setIsCalendarDataLoading] = useState(true)
    const [isRescheduleLoading, setIsRescheduleLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [availableTimes, setAvailableTimes] = useState([]);
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    const [confirmed, setConfirmed] = useState(false);
    const [isSameDateTime, setIsSameDateTime] = useState(false);

    const availableDates = calendarData?.map((entry) =>
        parseISO(entry.start_time)
    );
    const startTimesList = calendarData?.map((entry) =>
        parseISO(entry.start_time)
    );

    const isMobile = window.innerWidth < 1235;

    const formattedHeader = isMobile
        ? format(startDate, 'EEE, MMM do @ h:mmaaa')
        : format(startDate, 'EEEE, MMMM do @ h:mmaaa');

    useEffect(() => {
        if (!isVendor && order?.vendorId) {
            setIsCalendarDataLoading(true);

            dispatch(
                fetchCalendarData(order.vendorId, false, order.duration)
            ).finally(() => setIsCalendarDataLoading(false));
        } else {
            setIsCalendarDataLoading(false)
        }
    }, [dispatch, order?.vendorId, isVendor]);

    useEffect(() => {
        if (order?.appointmentAt) {
            const appointmentDate = new Date(order.appointmentAt);
            setStartDate(appointmentDate);
            setIsSameDateTime(
                isEqual(appointmentDate, new Date(order.appointmentAt))
            );
        }
    }, [order]);

    useEffect(() => {
        if (calendarData?.length > 0) {
            const initialDate =
                parseISO(order?.appointmentAt) ||
                parseISO(calendarData[0].start_time);
            setStartDate(initialDate);
            updateAvailableTimes(initialDate);
        }
    }, [calendarData]);

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        };
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const updateAvailableTimes = (dateTime) => {
        const filteredData = startTimesList.filter((time) =>
            isSameDay(time, dateTime)
        );
        setAvailableTimes(filteredData);
        return filteredData;
    };

    const handleDateChange = (date) => {
        if (calendarData?.length > 0) {
            if (isSameDay(startDate, date)) {
                setStartDate(date);
            } else {
                setStartDate(updateAvailableTimes(date)[0]);
            }
        } else {
            setStartDate(date);
        }
        setIsSameDateTime(isEqual(date, new Date(order.appointmentAt)));
    };

    const handleReschedule = () => {
        if (isRescheduleLoading) return;

        setIsRescheduleLoading(true)

        const updatedOrder = {
            ...order,
            appointmentAt: startDate,
            fulfillmentStatus: 'rescheduled',
        };

        dispatch(rescheduleOrder(updatedOrder)).finally(() =>
            setIsRescheduleLoading(true)
        );
    };

    return (
        <div className='appointment-scheduling reschedule-modal-container'>
            {isCalendarDataLoading ? (
                <div className='scheduling-container-loading'>
                    <ModalHeader headerText={'Fetching Available Times ...'} />
                </div>
            ) : (
                <>
                    {!isVendor ? (
                        <div className='scheduling-container'>
                            <div className='scheduling-output'>
                                <ModalHeader
                                    headerText={'Reschedule Your Appointment'}
                                />
                                {formattedHeader}
                            </div>
                            <DatePicker
                                inline
                                selected={startDate}
                                onChange={handleDateChange}
                                showTimeSelect
                                dateFormat='Pp'
                                placeholderText='Select a Date'
                                monthsShown={isMobile ? 1 : 2}
                                disabledKeyboardNavigation
                                timeIntervals={1}
                                includeDates={availableDates}
                                includeTimes={availableTimes}
                            />
                            <button
                                className='gray-out scheduling-continue-button'
                                onClick={handleReschedule}
                                disabled={isSameDateTime}
                            >
                                {isRescheduleLoading
                                    ? 'Rescheduling...'
                                    : 'Reschedule'}
                            </button>
                        </div>
                    ) : (
                        <div className='scheduling-container'>
                            <div className='scheduling-output'>
                                <ModalHeader
                                    headerText={'Reschedule Your Appointment'}
                                />
                                {formattedHeader}
                            </div>
                            <DatePicker
                                inline
                                selected={startDate}
                                onChange={handleDateChange}
                                showTimeSelect
                                dateFormat='Pp'
                                placeholderText='Select a Date'
                                monthsShown={isMobile ? 1 : 2}
                                disabledKeyboardNavigation
                                timeIntervals={15}
                                minDate={new Date()}
                            />
                            <div className='appointment-confirmation-section'>
                                <input
                                    type='checkbox'
                                    id='confirm'
                                    checked={confirmed}
                                    onChange={(e) =>
                                        setConfirmed(e.target.checked)
                                    }
                                />
                                <label className='confirm-appointment-checkbox'>
                                    I have discussed the new appointment time
                                    with the customer
                                </label>
                            </div>
                            <button
                                className='gray-out scheduling-continue-button'
                                onClick={handleReschedule}
                                disabled={
                                    (isVendor && !confirmed) || isSameDateTime
                                }
                            >
                                {isRescheduleLoading
                                    ? 'Rescheduling...'
                                    : 'Reschedule'}
                            </button>
                            <p
                                style={{
                                    margin: '30px auto 10px auto',
                                    fontSize: '14px',
                                }}
                            >
                                The customer will be notified of this change
                            </p>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default RescheduleModal;
