import { useDispatch } from 'react-redux';
import './VendorAuthorization.css';
import { useGoogleLogin } from '@react-oauth/google';
import { ReactComponent as LinkIcon } from '../../../../../../assets/svg/link.svg';
import csrfFetch from '../../../../../../store/csrf';
import { setVendorCalendar } from '../../../../../../store/session';

const VendorAuthorization = () => {
  const dispatch = useDispatch();

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/calendar.events.owned https://www.googleapis.com/auth/calendar.events',
    prompt: 'consent',
    redirect_uri: 'https://bookservo.com/api/auth/google/callback',
    onSuccess: async (codeResponse) => {
      try {
        const response = await csrfFetch('/api/auth/google/callback', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            code: codeResponse.code,
          }),
        });

        if (response.ok) {
          let data = await response.json();
          dispatch(
            setVendorCalendar(data.calendar)
          );
        }
      } catch (error) {
        console.error('Error sending authorization code to backend:', error);
      }
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  const calendarButton = () => {
    return (
      <button
        className='connect-google-calendar'
        onClick={() => googleLogin()}
      >
        Connect <LinkIcon />
      </button>
    );
  };

  return calendarButton();
};

export default VendorAuthorization;
