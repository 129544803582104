import { useState } from 'react';
import { ModalHeader } from '..';
import Errors from '../../Errors';
import '../ConfirmModal/ConfirmModal.css';
import { closeModal } from '../../../../store/ui';
import { useDispatch } from 'react-redux';

export default function ConfirmPasswordModal({ props }) {
    const { headerAndButtonText, confirmationText, onButtonClick, isLoading = false } = props;

    const dispatch = useDispatch();

    const [confirmPassword, setConfirmPassword] = useState('');

    return (
        <div
            className='confirm-modal confirm-password-modal'
            id='update-password-form'
        >
            <ModalHeader headerText={headerAndButtonText} />
            <p>{confirmationText}</p>
            <input
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                type='password'
                placeholder='Confirm Password'
            />
            <Errors />
            <div className='button-div'>
                <button
                    onClick={() => dispatch(closeModal())}
                    className='cancel-button'
                    id='confirm-password-modal-button'
                >
                    Cancel
                </button>
                <button
                    onClick={() => onButtonClick(confirmPassword)}
                    className='gray-out  submit-action-button'
                    id='confirm-password-modal-button'
                    disabled={confirmPassword === ''}
                >
                    {isLoading
                        ? `${headerAndButtonText}...`
                        : headerAndButtonText}
                </button>
            </div>
        </div>
    );
}
