import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { resetPassword } from '../../../store/resetPassword'; 
import Errors from '../../common/Errors';
import './ForgotAndResetPassword.css';
import useLoading from '../../../hooks/useLoading';

const ResetPassword = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const { isLoading, startLoading, stopLoading } = useLoading();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isLoading) return;

        startLoading();
        
        const resetPasswordData = { password, token };
        dispatch(resetPassword(resetPasswordData, history)).finally(
            () => stopLoading
        );
    };

    return (
        <div className='forgot-and-reset-password-container'>
            <div className='forgot-and-reset-password password-reset'>
                <h2>Reset Your Password</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type='password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder='New Password'
                    />
                    <input
                        type='password'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder='Confirm New Password'
                    />
                    <Errors />
                    {password !== confirmPassword && (
                        <p className='errors'>
                            Confirm Password field must be the same as the
                            Password field
                        </p>
                    )}
                    <h3 className='suggestion'>Password Security Tips:</h3>
                    <ul id='suggestions'>
                        <li>1 letter</li>
                        <li>
                            1 number or special character (example: # ? ! &)
                        </li>
                        <li>10 characters</li>
                    </ul>
                    <button
                        id='reset-password-button'
                        className='gray-out'
                        type='submit'
                        disabled={
                            password !== confirmPassword ||
                            password === '' ||
                            confirmPassword === ''
                        }
                    >
                        {isLoading ? 'Processing...' : 'Reset Password'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;
