import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './ResetPasswordComplete.css'

const ResetPasswordComplete = () => {
  const history = useHistory();
  const homeView = useSelector((state) => state.ui?.homeView);

  const destination = homeView === 'vendor' ? '/dashboard/orders' : '/home';

  return (
    <div className='reset-password-complete'>
      <div className='reset-password-complete-content'>
        <h2>Password Updated</h2>
        <p>
          Your password has been reset successfully, and you are now logged in.
        </p>
        <button onClick={() => history.push(destination)}>
          View {homeView === 'vendor' ? 'Dashboard' : 'Services'}
        </button>
      </div>
    </div>
  );
};

export default ResetPasswordComplete;
