// TODO (next steps page will only be shown if currentStatus === under review)

const NextSteps = () => {
  return (
    <div className="next-steps">
      <h2>Next Steps</h2>
      <p>
        After clicking "Submit for Review" a member of our team will
        review your profile to confirm:
      </p>

      <ul>
	      <li>Application Completeness</li>
        <li>Business Legitimacy</li>
        <li>Appropriate Images</li>
      </ul>

      <p>Vendor Support is a big principle of ours at Servo.</p>
      <p>
        Our goal is to help you create the most accurate "Pricing" section of your listing as possible. 
        Our team may reach out to gather additional details on
        service pricing.
      </p>
      <p>
        Additionally, we may ask for a Certificate of Insurance to ensure Servo customers are protected.
      </p>

      {/* <p>We aim to complete reviews within 1-3 business days.</p> */}
      <p>Please don't hesitate to reach out if you have questions or need support at <a href="mailto:contact@bookservo.com">contact@bookservo.com</a></p>

      <p>Welcome to the Servo team!</p>
    </div>
  );
};

export default NextSteps;