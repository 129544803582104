import './SplashPage.css'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import VendorIndex from '../../vendors/VendorIndex';
import { fetchVendors } from '../../../store/vendor';
import { Helmet } from 'react-helmet';

const SplashPage = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(fetchVendors())
    }, [dispatch])

    const handleLearnMoreClick = () => {
        history.push('/vendor-onboarding')
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    const handleLogoClick = (target) => {
        if (process.env.NODE_ENV === 'production'){
            switch(target){
                case "bug_blasters_pest_control": 
                    history.push('/vendors/22')
                    break
                case "lily_maid_cleaning":
                    history.push('/vendors/41')
                    break
                case  "summit_pest_control":
                    history.push('/vendors/62')
                    break
                case "moxie_pest_control":
                    history.push('/vendors/39')
                    break
                case "southwest_exterminators":
                    history.push('/vendors/46')
                    break
                case "zion_cleaning_co":
                    history.push('/vendors/23')
                    break
                case "insight_window_cleaning":
                    history.push('/vendors/53')
                    break
                case "turbo_pest_control":
                    history.push('/vendors/52')
                    break
                case "pro_window_cleaning":
                    history.push('/vendors/42')
                    break
                case "zing_services":
                    history.push('/vendors/21')
                    break
                case "all_seasons_cleaning":
                    history.push('/vendors/59')
                    break
                case "shakespeare_pest_control":
                    history.push('/vendors/45')
                    break
                case "dynamic_superior_services":
                    history.push('/vendors/54')
                    break
                case "bin_blasters":
                    history.push('/vendors/66')
                case "pure_window_cleaning":
                    history.push('/vendors/65')
                default: 
                    history.push('/')
            }
        }
    }

    return (
        <main className="splash-page">
            <Helmet>
                <meta name="description" content='Servo offers the most convenient way to book local professionals for home improvement services like Pest Control, Window Cleaning, and Home Cleaning, in St. George, UT. Enjoy a pristine, well-maintained home with our reliable experts.' />
                <title>Servo - Book Local Professionals for Home Improvement Services</title>
            </Helmet>

            <div style={{
                height: '800px',
                width: '100%',
                backgroundColor: 'rgb(163 211 229)',
                backgroundImage:"url('https://spencerheywood.com/images/servo/Pictures/web_optimized/servo_web_banner.avif')", 
                backgroundSize: 'cover',
                backgroundPosition: '100% center',
                position: 'relative',
                zIndex: '10',
                marginTop: '-100px',
                boxShadow: '-5px 0 10px -5px rgba(0, 0, 0, 0.5)'
                }}>
                <div 
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 10,
                        position: 'absolute',
                        right: '5%',
                        top: '215px'
                    }}
                >
                    <h1 style={{whiteSpace:'pre', textAlign: 'right', fontWeight: 'bold', fontSize: '39px'}}>
                        Online Shopping{'\n'}
                        <span style={{
                            color: 'var(--primary-white)'
                        }}>
                            For Your Home
                        </span>
                    </h1>
                </div>
            </div>
            <div className="splash-page-section" style={{flexDirection:'column', paddingTop: '50px'}}>
                <h2 style={{marginBottom: '40px', color: 'var(--primary-blue)', padding: '0 40px'}}>We make convenience... <span style={{fontStyle:'italic'}}>more convenient.</span></h2>
                <div className="three-step-section">
                    <div className="splash-page-icon">
                        <img className="splash-page-icon-image" src="https://spencerheywood.com/images/servo/icons/icons%203/icon_clear_bkgd/icons-04.png" />
                        <h3 className="splash-page-h3">Online Quote</h3>
                        <p>No waiting, no surprises. Uncover your exact cost in moments.</p>
                    </div>
                    <div className="splash-page-icon">
                        <img className="splash-page-icon-image" src="https://spencerheywood.com/images/servo/icons/icons%203/icon_clear_bkgd/icons-08.png" />
                        <h3 className="splash-page-h3">Instant Schedule</h3>
                        <p>Select the time that works for you. It’s as simple as picking a day on your calendar.</p>
                    </div>
                    <div className="splash-page-icon">
                        <img className="splash-page-icon-image" src="https://spencerheywood.com/images/servo/icons/icons%203/icon_clear_bkgd/icons-09.png" />
                        <h3 className="splash-page-h3">Secure Pay</h3>
                        <p>Rest easy knowing your payment and personal details are protected.</p>
                    </div>
                </div>
            </div>
            <div className="home-main" style={{padding: '40px 0'}}>
                <VendorIndex category={"house_cleaning"}/>
                <VendorIndex category={"pest_control"}/>
                <VendorIndex category={"window_cleaning"}/>
                <VendorIndex category={"garbage_can_cleaning"}/>
            </div>
            <div className="splash-page-section logo-section">
                <div className="splash-page-logo-container" style={{maxWidth:'1200px'}}>
                    <img 
                        className="splash-page-logo" 
                        onClick={() => handleLogoClick('bug_blasters_pest_control')} 
                        src="https://spencerheywood.com/images/servo/Pictures/web_optimized/bug_blasters_pest_control_logo.png" 
                        alt="Logo for Bug Blasters Pest Control in St. George, Utah, featuring a stylized 'BB' with a target symbol and the text 'Bug Blasters Pest Control' in blue and gray."
                    />
                    {/* <img className="splash-page-logo" src="https://spencerheywood.com/images/servo/Pictures/web_optimized/pure-window-cleaing-logo.webp"/> */}
                    <img 
                        className="splash-page-logo" 
                        onClick={() => handleLogoClick('lily_maid_cleaning')} 
                        src="https://spencerheywood.com/images/servo/Pictures/web_optimized/lily_maid_cleaning_logo.jpeg"
                        alt="Logo for Lily Maid Cleaning in St. George, Utah, showing a teardrop shape with bubbles and the text 'Lily Maid Cleaning' in teal and gray."
                    />
                    <img 
                        className="splash-page-logo" 
                        onClick={() => handleLogoClick('summit_pest_control')} 
                        src="https://spencerheywood.com/images/servo/Pictures/web_optimized/summit_pest_control_logo.png"
                        atl="Logo for Summit Pest Control in St. George, Utah, with a stylized mountain and spider illustration, and the text 'Summit Pest Control' in brown."
                    />
                    <img 
                        className="splash-page-logo"
                        onClick={() => handleLogoClick('shakespeare_pest_control')} 
                        src="https://spencerheywood.com/images/servo/Pictures/web_optimized/shakespeare_pest_control_logo.webp" 
                        alt="Logo for Shakespeare Pest Control in St. George, Utah, featuring the text 'Shakespeare Pest Control' in black and pink cursive."
                    />
                    <img 
                        className="splash-page-logo" 
                        onClick={() => handleLogoClick('moxie_pest_control')} 
                        src="https://spencerheywood.com/images/servo/Pictures/web_optimized/moxie_pest_control_logo.png"
                        alt="Logo for a Moxie Pest Control in St. George, Utah, with a geometric design featuring a stylized 'M' in blue"
                    />
                    <img 
                        className="splash-page-logo" 
                        onClick={() => handleLogoClick('southwest_exterminators')} 
                        src="https://spencerheywood.com/images/servo/Pictures/web_optimized/southwest_exterminators_logo.png" 
                        alt="Logo for Southwest Exterminators in St. George, Utah, displaying an oval shape with the text 'Southwest Exterminators' and 'Service Excellence' in blue."
                    />
                    <img 
                        className="splash-page-logo" 
                        onClick={() => handleLogoClick('zion_cleaning_co')} style={{padding: '25px'}}
                        src="https://spencerheywood.com/images/servo/Pictures/web_optimized/zion_cleaning_logo.png" 
                        alt="Logo for Zion Cleaning Co. in St. George, Utah, depicting a mountain with a sun and the text 'Zion Cleaning Co.' in blue and orange."
                    />
                    <img 
                        className="splash-page-logo" 
                        onClick={() => handleLogoClick('insight_window_cleaning')} 
                        src="https://spencerheywood.com/images/servo/Pictures/web_optimized/insight_window_cleaning_logo.png" 
                        alt="Logo for Insight Window Cleaning in St. George, Utah, showing a square and the text 'Insight Window Cleaning' in blue and gray."
                    />
                    <img 
                        className="splash-page-logo" 
                        onClick={() => handleLogoClick('turbo_pest_control')} 
                        src="https://spencerheywood.com/images/servo/Pictures/web_optimized/turbo_pest_control_logo.jpeg" 
                        alt="Logo for Turbo Pest Control in St. George, Utah, featuring a yellow background with the text 'Turbo Pest Control' in black and red with arrow symbols."
                    />
                    <img 
                        className="splash-page-logo" 
                        onClick={() => handleLogoClick('pro_window_cleaning')} 
                        src="https://spencerheywood.com/images/servo/Pictures/web_optimized/pro_window_cleaning_logo.avif" 
                        alt="Logo for Pro Window Cleaning in St. George, Utah, displaying a window illustration and the text 'Pro Window Cleaning' in blue and black."
                    />
                    <img 
                        className="splash-page-logo" 
                        onClick={() => handleLogoClick('zing_services')} 
                        src="https://spencerheywood.com/images/servo/Pictures/web_optimized/zing-window-cleaning-logo.webp" 
                        alt="Logo for Zing Services in St. George, Utah, featuring a stylized 'Z' with a circular design and the text 'Zing Services' in blue and black."
                    />
                    <img 
                        className="splash-page-logo" 
                        onClick={() => handleLogoClick('all_seasons_cleaning')} 
                        src="https://spencerheywood.com/images/servo/Pictures/web_optimized/all_seasons_cleaning_logo.png"
                        alt="Logo for a All Seasons Cleaning in St. George, Utah, with a minimalist design featuring wavy lines and a circular shape."
                    />
                    <img
                        className='splash-page-logo'
                        onClick={() => handleLogoClick('dynamic_superior_services')}
                        src="https://spencerheywood.com/images/servo/Pictures/web_optimized/dynamic_superior_services_logo.jpeg"
                        alt="Logo for Dynamic Superior Services featuring stylized text 'Dynamic Superior Services' in St George, Utah, with a curved line underneath, suggesting movement and dynamic service."
                    />
                    <img
                        className='splash-page-logo'
                        onClick={() => handleLogoClick('pure_window_cleaning')}
                        src="https://spencerheywood.com/images/servo/Pictures/web_optimized/pure-window-cleaing-logo.webp"
                        alt="Pure Window Cleaning logo with a blue water drop integrated into the letter 'P'"
                    />
                    <img
                        className='splash-page-logo'
                        onClick={() => handleLogoClick('bin_blasters')}
                        src="https://spencerheywood.com/images/servo/Pictures/web_optimized/BBLogo-Color-RGB-1.png"
                        alt="Bin Blasters logo featuring a smiling green trash bin character spraying water with the text 'Bin Blasters' in blue letters"
                    />
                </div>
            </div>
            <div className="splash-page-section" style={{paddingBottom: '50px'}}>
                <img className="splash-page-image" src="https://spencerheywood.com/images/servo/Pictures/garbage_can_cleaning/test.avif/Servo%20Pictures-43.avif" />
                <div className="section-text">
                    <h2>Calling All Service Providers!</h2>
                    <h3 style={{fontWeight:'normal', lineHeight:'20px'}}>Do you run a service-oriented business in St. George? Grow your business with Servo.</h3>
                    <button style={{width: '150px', margin:'20px 0 0 0', backgroundColor:'var(--primary-yellow)'}} onClick={handleLearnMoreClick}>Learn More</button>
                    {/* <p style={{lineHeight:'25px', 'textUnderlineOffset': "8px"}}>Our online booking platform is dedicated to home services, offering your clients a seamless 3-click booking experience integrated into your existing flow. Take the first step to becoming one of our platform's first vendors. Click here to <a href='/vendor-onboarding'>learn more</a>.</p> */}
                </div>
            </div>
        </main>
    )
};

export default SplashPage;