import './ProviderGallery.css';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../../store/ui';

const ProviderGallery = ({ galleryImageUrls }) => {
  const dispatch = useDispatch();

  const handleImageClick = (idx) => {
    dispatch(openModal('carousel', { galleryImageUrls, idx }));
  };

  return (
    <div className='gallery-container'>
      <h3 className='gallery-header'>Gallery</h3>
      <div className='provider-gallery'>
        {galleryImageUrls?.map(({ blobId, url }, idx) => (
          <div
            key={blobId}
            className='image-container'
            onClick={() => handleImageClick(idx)}
          >
            <img className='provider-photo' src={url} alt='' />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProviderGallery;