import { useDispatch } from 'react-redux';
import { deleteCalendar } from '../../../../store/vendor_calendar';
import useLoading from '../../../../hooks/useLoading';
import { useEffect } from 'react';
import { openModal } from '../../../../store/ui';

export default function DisconnectCalendar({ props }) {
    const { vendor } = props;

    const dispatch = useDispatch();

    const { isLoading, startLoading, stopLoading } = useLoading();

    const handleDeleteCalendar = (confirmPassword) => {
        if (isLoading) return;

        startLoading();

        dispatch(deleteCalendar(vendor, confirmPassword)).finally(() =>
            stopLoading()
        );
    };

    useEffect(() => {
        dispatch(
            openModal('confirm-password-modal', {
                headerAndButtonText: 'Disconnect Calendar',
                confirmationText:
                    'Are you sure you want to disconnect your calendar?',
                onButtonClick: handleDeleteCalendar,
            })
        );
    }, [dispatch]);

    return null;
}
