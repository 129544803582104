import './HomeNav.css';
import { ReactComponent as HamburgerMenu } from './HamburgerMenu.svg';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../../store/session';
import { ReactComponent as LogoSvg } from '../../../../assets/svg/logo_master.svg';
import SwitchViewToggle from '../../SwitchViewToggle';
import SecondaryNav from './SecondaryNav/SecondaryNav';
import { setHomeView } from '../../../../store/ui';
import { getActiveAddress } from '../../../../store/addresses';

const HomeNav = ({ display, toggleMenu, toggleMobileMenu }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userAddress = useSelector(getActiveAddress);
  const homeView = useSelector((state) => state.ui?.homeView);
  const vendor = useSelector((state) => state.session?.vendor);
  const currentUser = useSelector((state) => state.session.user);

  if (!display) return null;

  const handleClickToHome = () => {
    if (currentUser.userType === 'vendor') {
      dispatch(setHomeView('vendor'))
      history.push(`/dashboard/orders`);
    } else {
      history.push('/home');
    }
  };

  const handleSignOut = () => {
    dispatch(logout());
  };

  const handleAddressClick = (e) => {
    e.preventDefault();
    history.push('/account');
  };

  return currentUser.userType === 'vendor' ? (
    <header>
      <div id='vendor-home-nav' className='home-nav'>
        <div className='home-nav-left'>
          <HamburgerMenu
            onClick={toggleMobileMenu}
            className='vendor-mobile-menu hamburger-menu'
          />
          <div className='logo-container' onClick={handleClickToHome}>
            <LogoSvg className='main-logo' alt='Servo Official Logo' />
          </div>
          <h2 className='vendor-name'>
            <div>Hello,</div>
            {vendor?.name}
          </h2>
          <h2
            id='vendor-mobile-display'
            style={{ position: 'absolute', right: '30px' }}
          >
            Vendor Portal
          </h2>
        </div>
        <div className='home-nav-right'>
          <SwitchViewToggle
            homeView={homeView}
            side={homeView === 'user' ? 'user' : ''}
            vendorId={vendor?.id}
          />
          <div className='address-or-logout-btn-div'>
            <span onClick={handleSignOut} className='vendor-logout'>
              Logout
            </span>
          </div>
        </div>
      </div>
      <SecondaryNav />
    </header>
  ) : (
    <header>
      <div className='home-nav'>
        <div className='home-nav-left'>
          <HamburgerMenu onClick={toggleMenu} />
          <div className='home-nav-left-inner-container'>
            <div className='logo-container' onClick={handleClickToHome}>
              <LogoSvg className='main-logo' alt='Servo Official Logo' />
            </div>
            <button className='location-btn'>ST GEORGE, UT</button>
          </div>
        </div>
        <div className='home-nav-right'>
          <div></div>
          <div className='address-or-logout-btn-div'>
            <a
              href='/account'
              onClick={handleAddressClick}
              className='user-address'
            >
              {userAddress?.streetAddress} <br /> {userAddress?.streetAddress2}
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HomeNav;
