import { useSelector } from 'react-redux';

const PricingFormulaTips = () => {
    const vendor = useSelector((state) => state.session.vendor);
    const aliases = Object.values(vendor.pricingInputs)
        .filter((input) => !input.recurring)
        .map((input) => input.alias);

    return (
        <div className='pricing-formula-tips'>
            <div className='pricing-formula-tips-top'>
                <div>
                    <div className='avail-inputs-div'>
                        <h3>Required Inputs</h3>
                        {/* <ToolTip message={'Formula must use all available inputs'} /> */}
                    </div>
                    <ul>
                        {aliases.map((alias) => (
                            <li key={alias}>{alias}</li>
                        ))}
                    </ul>
                </div>
                <div>
                    <h3>Valid Operators</h3>
                    <p>+ - * / ()</p>
                </div>
            </div>
            <h3 className='advanced-operators'>Advanced Operators</h3>
            <p>If else statements can be made using ternary syntax.</p>
            <span>Explanation: condition ? value_if_true : value_if_false</span>
            <span>For complex conditions, use "&&" for AND, "||" for OR</span>
            <p>Example: (NUM_FLOORS {'>'} 1 ? 50 : 25)</p>
            <span className='no-margin-span'>
                Reads "Is the home greater than 1 story tall?
            </span>
            <span>If yes, 50. If it's not bigger, then 25."</span>
            <p>
                Advanced Example: (SQUARE_FOOTAGE {'<'}= 3000 ? 120 :
                SQUARE_FOOTAGE {'<'}= 4000 ? 125 : SQUARE_FOOTAGE {'<'}= 5000 ?
                135 : 175)
            </p>
            <span className='no-margin-span'>
                This sets the value based on square footage ranges:
            </span>
            <ul id='square-footage-ranges'>
                <li>120 for up to 3000 sq ft,</li>
                <li>125 for 3001 to 4000 sq ft,</li>
                <li>135 for 4001 to 5000 sq ft,</li>
                <li>If the square footage exceeds 5000, 175.</li>
            </ul>
        </div>
    );
};

export default PricingFormulaTips;
