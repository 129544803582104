import { openModal } from '../../store/ui';
import './VendorOnboarding.css'
import {useHistory} from "react-router-dom"
import {useDispatch, useSelector} from 'react-redux'
import {ReactComponent as Checkmark} from '../../assets/svg/checkmark.svg'

const VendorOnboarding = () => {
    const dispatch = useDispatch()
    const history = useHistory();

    const userType = useSelector(state => state.session?.user?.userType)

    // const handleGetInTouchClick = () => history.push('/contact-us')

    return (
        <div className="vendor-onboarding-container">
            <div className="vendor-onboarding">
                <h1>Client Appointments Sent Directly To Your Calendar</h1>
                <p style={{fontSize: '20px', margin: '-25px 0 10px 0'}}>Without adding expenses to your business.</p>
                {/* <h2 style={{backgroundColor: 'var(--primary-yellow)', color: 'white', textAlign:'center', fontSize:'16px', padding: '5px 10px'}}>Currently onboarding for the following zip codes: <span style={{color: 'var(--primary-white)'}}>84790, 84780, 84770, 84765</span></h2> */}
                {/* <p style={{marginBottom: '50px', fontSize: '18px'}}>Designed for businesses ready to grow, Servo opens the door to additional work</p> */}
                <h3>Why Partner with Servo?</h3>
                <p style={{marginBottom: '40px'}}>Partnering with Servo provides a stream of job bookings that are integrated into your existing workflow.</p>
                <div className="comparison-chart" style={{marginBottom: '40px'}}>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                        <tr>
                            <th style={{ borderBottom: '1px solid #ddd', padding: '15px', textAlign: 'left', fontWeight:'bold', fontSize: '18px' }}>Features</th>
                            <th style={{ borderBottom: '1px solid #ddd', padding: '15px', textAlign: 'center', fontWeight:'bold', fontSize: '18px' }}>Servo</th>
                            <th style={{ borderBottom: '1px solid #ddd', padding: '15px', textAlign: 'center', fontWeight:'bold', fontSize: '18px' }}>Lead Generation Sites</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '15px' }}>Confirmed Work Orders</td>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '15px', textAlign: 'center' }}><Checkmark /></td>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '15px', textAlign: 'center' }}>❌</td>
                        </tr>
                        {/* <tr>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '15px' }}>CRM Integration</td>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '15px', textAlign: 'center' }}><Checkmark /></td>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '15px', textAlign: 'center' }}>❌</td>
                        </tr> */}
                        {/* <tr>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '15px' }}>Online Quoting</td>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '15px', textAlign: 'center' }}><Checkmark /></td>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '15px', textAlign: 'center' }}>❌</td>
                        </tr> */}
                        {/* <tr>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '15px' }}>No Upfront Costs</td>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '15px', textAlign: 'center' }}><Checkmark /></td>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '15px', textAlign: 'center' }}>❌</td>
                        </tr> */}
                        <tr>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '15px' }}>Commission-Only</td>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '15px', textAlign: 'center' }}><Checkmark /></td>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '15px', textAlign: 'center' }}>❌</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                        {userType !== 'vendor' && <button onClick={() => dispatch(openModal("vendor-sign-up-form"))} style={{margin: '30px auto 35px auto'}} className="onboarding-cta">List Your Service</button>}
                {/* {userType !== 'vendor' && <button onClick={() => dispatch(openModal("vendor-sign-up-form"))} style={{margin: '-0px auto 35px auto', width: '300px', justifySelf:'center'}}>List Your Service</button>} */}
                <h3>No Chasing Invoices</h3>
                <p>On Servo orders, you will never have to follow up with clients to collect payment. Servo ensures your payouts are processed promptly and securely via direct deposit after every job.</p>

                <h3>No Lead Generation Fees</h3>
                <p>With Servo, say goodbye to upfront costs, monthly subscriptions, and hidden charges. We're your tech ally, focusing on supporting your growth, not exploiting it. Our commission-based model aligns our success with yours, ensuring a partnership where your growth is our priority.</p>

                <h3>Seamless Integration</h3>
                <p>Integration with Servo is smooth, allowing the incoming jobs to merge into your workflow effortlessly. We take on the burden of scheduling and payment processing, automating the mundane to let you concentrate on what you do best—providing outstanding service.</p>

                <h3>Long-Term in Mind</h3>
                <p>At Servo, our aim goes beyond mere transactions; we strive to be a long-term partner. Understanding that trust is earned over time, we commit to supporting your business growth every step of the way by building features that will help drive more orders to your business.</p>

                <h3>Efficient Scheduling and Quoting</h3>
                <p>Say farewell to the endless back-and-forth. Our systems enable you to offer instant quotes and real-time availability, simplifying the booking process and increasing your conversion rates.</p>
                
                <div style={{backgroundColor: 'var(--secondary-white)', padding: '40px', marginTop:'60px'}}>
                    <h2 style={{margin:'0'}}>Coming Soon 2024</h2>
                    <h3>View Drive-Time Statistics</h3>
                    <ul>
                        <li>• View the estimated drive time between existing appointments before booking or rescheduling</li>
                        <li></li>
                    </ul>
                    <h3>Optimize with Dynamic Pricing</h3>
                    <ul>
                        <li>• Offer discounts in the neighborhoods you are already servicing</li>
                        <li>• Charge extra for longer drives</li>
                        <li>• Vary pricing throughout the year with surge pricing</li>
                    </ul>
                    <h3>Offer Custom Discounts</h3>
                    <ul>
                        <li>• Offer frequency based discounts for return customers</li>
                        <li>• Promote your service with unique deals</li>
                    </ul>
                    <h3>Additional Service Categories</h3>
                    <ul>
                        <li>• Garbage Can Cleaning</li>
                        <li>• Christmas Lighting</li>
                        <li>• Mobile Detailing</li>
                        <li>• Mobile Lube</li>
                        <li>• Carpet Cleaning</li>
                        <li>• Pool Cleaning</li>
                    </ul>
                    
                    <h2 style={{margin: '40px 0 20px 0'}}>2025 and Beyond...</h2>
                    {/* <h3>The more people, the bigger the discount</h3> */}
                    <ul>
                        <li>• Encourage bookings within the same neighborhood with discounted 'group carts'</li>
                        <li>• Fill gaps in your calendar by offering limited time deals for prospecting customers within the same neighborhood as your existing appointments</li>
                    </ul>
                </div>

                {userType !== 'vendor' && <button onClick={() => dispatch(openModal("vendor-sign-up-form"))} style={{margin: '40px auto 0 auto'}} className="onboarding-cta">List Your Service</button>}
                <p style={{marginTop:'20px', textAlign:'center', color: 'var(--secondary-gray)'}}>Actively onboarding in St George, UT</p>
            </div>
        </div>
    )
}

export default VendorOnboarding;