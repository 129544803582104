const VendorOrderIndexItemExpandedView = ({
    order,
    fulfillmentStatus,
    paymentStatus,
    customerName,
    formattedPrice,
    formattedDate,
    formattedPhoneNumber,
    options,
}) => {
    const firstAddress = order.customer
        ? Object.values(order.customer?.addresses)?.[0]
        : null;

    const wasRefunded =
        paymentStatus === 'Partially Refunded' || paymentStatus === 'Refunded';

    const payoutDestination =
        order?.payoutDestination?.last4 === null &&
        order?.payoutDestination?.bankName === null
            ? 'Paper Check'
            : `...${order?.payoutDestination?.last4} ${order?.payoutDestination?.bankName}`;

    return (
        <div className='order-index-item-expanded-container'>
            <div className='expanded-view-left'>
                <h3>Summary</h3>
                <p
                    style={{
                        fontSize: '14px',
                        marginTop: '5px',
                        color: 'var(--secondary-blue)',
                    }}
                >
                    Order ID #{order.id}
                </p>
                <div className='index-item-info'>
                    <p>Appointment Date:</p>
                    <p>{formattedDate()}</p>
                </div>
                <div className='index-item-info'>
                    <p>Fulfillment Status:</p>
                    <p>{fulfillmentStatus}</p>
                </div>
                <div className='index-item-info'>
                    <p>Payment Status:</p>
                    <p>{paymentStatus}</p>
                </div>
                <div className='index-item-info'>
                    <p>Total:</p>
                    <p>${formattedPrice}</p>
                </div>
                <div className='index-item-info' style={{ marginTop: '15px' }}>
                    <a
                        href={order.workOrderUrl}
                        target='_blank'
                        rel='noreferrer'
                    >
                        View Work Order
                    </a>
                </div>
            </div>
            <div className='expanded-view-middle'>
                <h3>Customer</h3>
                <p style={{ marginTop: '15px' }}>{customerName}</p>
                <p>{formattedPhoneNumber}</p>

                <h3 style={{ marginTop: '30px' }}>Location</h3>
                <p style={{ marginTop: '15px' }}>
                    {firstAddress?.streetAddress}
                </p>
                {firstAddress?.streetAddress2 && (
                    <p>{firstAddress?.streetAddress2}</p>
                )}

                <p>{`${firstAddress?.city}, ${firstAddress?.state}, ${firstAddress?.zipCode}`}</p>
            </div>
            <div className='expanded-view-right'>
                <h3>Service Details</h3>
                {options &&
                    Object.entries(options).map((entry, index) => (
                        <div className='index-item-info' key={index}>
                            <p>
                                {entry[0].charAt(0).toUpperCase() +
                                    entry[0].slice(1)}
                            </p>
                            <p>{entry[1]}</p>
                        </div>
                    ))}
                <h3 style={{ marginTop: '30px' }}>Billing</h3>
                <div className='index-item-info'>
                    <p> Service Total </p>
                    <p> ${parseFloat(order?.totalCollected).toFixed(2)} </p>
                </div>
                <div className='index-item-info'>
                    <p> Commission </p>
                    <p>
                        - $
                        {(
                            parseFloat(order?.commission) +
                            parseFloat(order?.serviceFee)
                        ).toFixed(2)}
                    </p>
                </div>
                {wasRefunded && (
                    <div
                        className='index-item-info'
                        style={{
                            borderBottom: '1px solid ',
                            paddingBottom: '20px',
                        }}
                    >
                        <p> Refunded </p>
                        <p>- ${parseFloat(order?.refundAmount).toFixed(2)}</p>
                    </div>
                )}
                <div className='index-item-info'>
                    <p> Total Payout </p>
                    <p>${parseFloat(order?.vendorPayoutAmount).toFixed(2)}</p>
                </div>
                <div className='index-item-info'>
                    <p> Payout Destination </p>
                    <p>{payoutDestination}</p>
                </div>
            </div>
        </div>
    );
};

export default VendorOrderIndexItemExpandedView;
