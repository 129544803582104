import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import {
    ConnectAccountOnboarding,
    ConnectComponentsProvider,
} from '@stripe/react-connect-js';
import csrfFetch from '../../../../../store/csrf';
import { fetchVendor } from '../../../../../store/vendor';
import './PayoutsStep.css';

const PayoutsStep = ({ vendor }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isConnected, setIsConnected] = useState('inactive');
    const [clientSecret, setClientSecret] = useState(null);
    const [stripeConnectInstance, setStripeConnectInstance] = useState(null);
    const vendorId = vendor?.id;

    const handleLinkClick = (location) => {
        history.push(location);
    };

    const handleOnboardingComplete = () => {
        setTimeout(() => {
            dispatch(fetchVendor(vendorId, history, 'updateSessionVendor'));
        }, 3500); // Wait for 3.5 seconds (3500 milliseconds)
    };

    useEffect(() => {
        if (vendor?.stripeIntegrated === 'active') {
            setIsConnected('active');
        } else if (vendor?.stripeIntegrated === 'inactive') {
            setIsConnected('inactive');
        }
    }, [vendor]);

    const fetchClientSecret = async () => {
        try {
            const response = await csrfFetch(
                `/api/vendors/create_account_session`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ id: vendorId }),
                }
            );

            if (!response.ok) {
                const { error } = await response.json();
                console.error('An error occurred: ', error);
                setIsConnected('inactive');
                return;
            } else {
                const { client_secret: secret } = await response.json();
                setClientSecret(secret);
            }
        } catch (error) {
            console.error('Error fetching client secret:', error);
            setIsConnected('inactive');
        }
    };

    useEffect(() => {
        if (clientSecret && !stripeConnectInstance) {
            const fetchConnectInstance = async () => {
                try {
                    const instance = await loadConnectAndInitialize({
                        publishableKey:
                            process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
                        appearance: {
                            variables: {
                                colorPrimary: '#A3D3E5',
                                colorText: '#23254E',
                                buttonPrimaryColorBackground: '#23254E',
                                actionPrimaryColorText: '#F4BC46',
                                badgeSuccessColorBackground: '#63C466',
                                badgeSuccessColorBorder: '#63C466',
                                badgeWarningColorBorder: '#F4BC46',
                                borderRadius: '8px',
                                fontFamily: 'sans-serif',
                                headingXlFontSize: '16px',
                                headingLgFontSize: '16px',
                                bodyMdFontSize: '14px',
                                bodySmFontSize: '14px',
                                labelMdFontSize: '14px',
                            },
                        },
                        fetchClientSecret: () => clientSecret,
                    });
                    setStripeConnectInstance(instance);
                    setIsConnected('onboarding');
                } catch (error) {
                    console.error(
                        'Error initializing Connect instance:',
                        error
                    );
                    setIsConnected('inactive');
                }
            };

            fetchConnectInstance();
        }
    }, [clientSecret, stripeConnectInstance]);

    useEffect(() => {
        fetchClientSecret();
    }, []);

    return (
        <div className='payments'>
            <div className='payments-left'>
                <div className='stripe-connect-text'>
                    <h2>Connect your bank</h2>
                    <p>
                        Link your bank account to receive payments directly.
                        This ensures fast, secure transactions. You can update
                        your bank details anytime.
                    </p>
                    <p>Servo uses Stripe for payment processing services</p>
                    <p>
                        Payments are initiated 72 hours after the job has been
                        marked "fulfilled".
                    </p>
                    <p>
                        By completing this form, you agree to our&nbsp;
                        <a
                            onClick={() => handleLinkClick('/terms-of-service')}
                            target='_blank'
                        >
                            Services Agreement
                        </a>
                        &nbsp;and the&nbsp;
                        <a
                            href='https://stripe.com/legal/connect-account'
                            target='_blank'
                        >
                            Stripe Connected Account Agreement
                        </a>
                        .
                    </p>

                    {isConnected === 'active' && (
                        <div className='connected-stripe'>
                            <div className='status-circle'></div>
                            <div className='connected-stripe'>Connected</div>
                        </div>
                    )}
                </div>
                <div
                    className='servo-certified-icon'
                    style={{
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        padding: '20px 0',
                    }}
                >
                    <img
                        src='https://spencerheywood.com/images/servo/icons/icons%203/icon_clear_bkgd/icons-09.png'
                        style={{ height: '50px', marginRight: '-5px' }}
                        alt='Servo Sheild Icon'
                    />
                    <img
                        src='https://spencerheywood.com/images/servo/logos_and_icons/logo_blue_yellow.png'
                        style={{ height: '44px' }}
                        alt='Servo Certified Logo'
                    />
                </div>
            </div>
            <div className='payments-right'>
                {stripeConnectInstance && isConnected === 'onboarding' && (
                    <ConnectComponentsProvider
                        connectInstance={stripeConnectInstance}
                    >
                        <ConnectAccountOnboarding
                            onExit={handleOnboardingComplete}
                            collectionOptions={{
                                fields: 'currently_due',
                                // futureRequirements: 'include',
                            }}
                        />
                    </ConnectComponentsProvider>
                )}
            </div>
        </div>
    );
};

export default PayoutsStep;
