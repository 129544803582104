import { useState } from 'react';
import {
    handleEmailChange,
    handleNextStep,
    handlePhoneNumberChange,
} from '../../../../util/vendors/vendorSignUpHelpers';

const Step2 = ({ formData, handleChange, nextStep }) => {
    const {
        category,
        name,
        businessPhoneNumber,
        businessEmail,
        streetAddress,
        streetAddress2,
        city,
        zipCode,
    } = formData;
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [emailError, setEmailError] = useState('');

    return (
        <>
            <h2>About Your Business</h2>
            <span>Service Category</span>
            <select
                name='category'
                onChange={handleChange}
                value={category || 'default'}
            >
                <option value='default' disabled>
                    Select One
                </option>
                <option>Window Cleaning</option>
                <option>House Cleaning</option>
                <option>Pest Control</option>
                <option>Garbage Can Cleaning</option>
            </select>
            <input
                name='name'
                value={name}
                onChange={handleChange}
                placeholder='Business Name'
                type='text'
                required
            />
            <input
                value={businessPhoneNumber}
                onChange={(e) =>
                    handlePhoneNumberChange(
                        'businessPhoneNumber',
                        e.target.value,
                        handleChange,
                        setPhoneNumberError
                    )
                }
                type='tel'
                placeholder='Business Phone Number'
                pattern='[0-9]+'
                name='businessPhoneNumber'
                required
            />
            <input
                name='businessEmail'
                value={businessEmail}
                onChange={(e) =>
                    handleEmailChange(
                        'businessEmail',
                        e.target.value,
                        handleChange,
                        setEmailError
                    )
                }
                placeholder='Business Email'
                type='text'
                required
            />
            <input
                name='streetAddress'
                value={streetAddress}
                onChange={handleChange}
                placeholder='Street Address'
                type='text'
                required
            />
            <input
                name='streetAddress2'
                value={streetAddress2}
                onChange={handleChange}
                placeholder='Street Address 2 (optional)'
                type='text'
            />
            <div className='step2-bottom-div'>
                <input
                    name='city'
                    value={city}
                    onChange={handleChange}
                    placeholder='City'
                    type='text'
                    required
                />
                <select style={{ marginRight: '7px' }}>
                    <option>UT</option>
                </select>
                <select
                    name='zipCode'
                    onChange={handleChange}
                    value={zipCode || 'default'}
                >
                    <option value='default' disabled>
                        Zip Code
                    </option>
                    <option>84765</option>
                    <option>84770</option>
                    <option>84780</option>
                    <option>84790</option>
                </select>
            </div>

            {emailError && <p className='errors'>{emailError}</p>}
            {phoneNumberError && <p className='errors'>{phoneNumberError}</p>}
            <div className='button-div'>
                <button
                    onClick={() =>
                        handleNextStep(
                            businessPhoneNumber,
                            setPhoneNumberError,
                            businessEmail,
                            setEmailError,
                            nextStep
                        )
                    }
                    disabled={
                        businessEmail === '' ||
                        businessPhoneNumber === '' ||
                        category === '' ||
                        name === '' ||
                        city === '' ||
                        streetAddress === '' ||
                        zipCode === '' ||
                        !!phoneNumberError ||
                        !!emailError
                    }
                    className='gray-out'
                >
                    Next
                </button>
            </div>
            <p>Step 2 of 3</p>
        </>
    );
};

export default Step2;
