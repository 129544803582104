import './VendorIndexItem.css';
import { useHistory } from 'react-router-dom';
import { ReactComponent as StarSvg } from '../../../assets/svg/reviewStar.svg';

const VendorIndexItem = ({ vendor }) => {
    const { id, name, thumbnailImageUrl, logoImageUrl, avgRating, reviewCount } = vendor;

    const history = useHistory();

    const vendorRedirect = (e) => {
        e.stopPropagation();
        window.scrollTo({
            top: 0,
        });
        history.push(`/vendors/${id}`);
    };

    let formattedAvgRating = reviewCount > 0 ? avgRating : 'New Service! ';

    return (
        <li className='vendor-index-item' onClick={vendorRedirect}>
            <div className='vendor-index-image-container'>
                <img src={thumbnailImageUrl} alt={`${name} vendor cover`} />
            </div>
            <div className='vendor-index-item-meta-info-container'>
                <img src={logoImageUrl} alt={`${name} logo`} />
                <div className='vendor-index-item-meta-info'>
                    <h3>{name}</h3>
                    <span className='macro-review-container'>
                        <p>{formattedAvgRating}</p>
                        <StarSvg className='review-star-svg' />
                        <p>({reviewCount})</p>
                    </span>
                </div>
            </div>
        </li>
    );
};

export default VendorIndexItem;
