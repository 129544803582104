import './DashboardScheduling.css';
import DashboardHeader from '../DashboardHeader';
import { useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { formatDuration } from '../../../../util/formatting';
import { useDispatch, useSelector } from 'react-redux';
import { updateCalendar } from '../../../../store/vendor_calendar';
import ToolTip from '../../../common/ToolTip';
import CalendarIntegration from './calendarIntegrations/CalendarIntegration';

const DashboardScheduling = ({ vendor }) => {
    const dispatch = useDispatch();
    const vendorPreferences = {
        priceToDurationRate: vendor.priceToDurationRate,
        cancelationPolicyHours: vendor.cancelationPolicyHours,
        appointmentInstructions: vendor.appointmentInstructions,
        id: vendor.id,
    };
    const calendar = useSelector(state => state.session.vendor.calendar);
    const calendarPreferences = {
        workdayStartTime: calendar.workdayStartTime,
        workdayEndTime: calendar.workdayEndTime,
        driveTimeBufferMins: calendar.driveTimeBufferMins,
        bookingWindowStartDays: calendar.bookingWindowStartDays,
        bookingWindowEndDays: calendar.bookingWindowEndDays,
        id: calendar.id,
    };

    const [stagedCalendarSettings, setStagedCalendarSettings] =
        useState(calendarPreferences);
    const [stagedVendorSettings, setStagedVendorSettings] =
        useState(vendorPreferences);
    const [error, setError] = useState(null);

    const calendarEqual = useMemo(() => {
        return isEqual(calendarPreferences, stagedCalendarSettings);
    }, [calendarPreferences, stagedCalendarSettings, vendor]);

    const vendorEqual = useMemo(() => {
        return isEqual(vendorPreferences, stagedVendorSettings);
    }, [vendorPreferences, stagedVendorSettings, vendor]);

    const handleSave = (e) => {
        e.preventDefault();
        if (
            stagedCalendarSettings.workdayStartTime >=
            stagedCalendarSettings.workdayEndTime
        ) {
            setError('Workday start time must be before end time.');
            return;
        }
        let payload = {};
        let needsUpdating = false;
        if (!calendarEqual) {
            payload = { vendorCalendar: stagedCalendarSettings };
            needsUpdating = true;
        }
        if (!vendorEqual) {
            payload = { ...payload, vendor: stagedVendorSettings };
            needsUpdating = true;
        }
        if (needsUpdating) {
            dispatch(updateCalendar(payload));
        }
        setError(null);
    };

    return (
        <>
            <DashboardHeader pageTitle={'Scheduling Preferences'}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <button
                        disabled={calendarEqual && vendorEqual}
                        onClick={handleSave}
                        className='gray-out-inverse'
                    >
                        Save
                    </button>
                </div>
            </DashboardHeader>
            <div className='dashboard-scheduling'>
                <div className='dashboard-scheduling-left'>
                    <div className='dashboard-scheduling-container'>
                        <div className='calendar-header-container'>
                            <h3>Calendar Integration</h3>
                            <ToolTip message='Sync with your calendar to auto-update your availability' />
                        </div>
                        <CalendarIntegration vendor={vendor}/>
                    </div>
                    <div className='dashboard-scheduling-container'>
                        <h3>Workday Availability</h3>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                maxWidth: '300px',
                                marginTop: '20px',
                            }}
                        >
                            <p>Mon-Fri</p>
                            <div className='scheduling-input-container'>
                                <input
                                    type='time'
                                    min='00:00'
                                    max='12:00'
                                    step={1800}
                                    value={
                                        stagedCalendarSettings.workdayStartTime
                                    }
                                    onChange={(e) =>
                                        setStagedCalendarSettings(
                                            (prevValues) => ({
                                                ...prevValues,
                                                workdayStartTime:
                                                    e.target.value,
                                            })
                                        )
                                    }
                                />
                                <div
                                    style={{
                                        width: '15px',
                                        height: '1px',
                                        borderBottom:
                                            '2px solid var(--primary-gray)',
                                        margin: '0 10px',
                                    }}
                                />
                                <input
                                    type='time'
                                    min='12:00'
                                    max='24:00'
                                    step='1800'
                                    value={
                                        stagedCalendarSettings.workdayEndTime
                                    }
                                    onChange={(e) =>
                                        setStagedCalendarSettings(
                                            (prevValues) => ({
                                                ...prevValues,
                                                workdayEndTime: e.target.value,
                                            })
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className='workday-incorrect-time'>
                            <p className='workday-incorrect-time-text'>
                                {error}
                            </p>
                        </div>
                    </div>
                    <div className='dashboard-scheduling-container'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <h3>Gap Between Appointments</h3>
                            <ToolTip message='Account for drive time or prep between appointments' />
                        </div>
                        <div className='scheduling-input-container'>
                            <input
                                type='number'
                                step='1'
                                min='0'
                                max='60'
                                style={{ marginRight: '10px' }}
                                value={
                                    stagedCalendarSettings.driveTimeBufferMins
                                }
                                required
                                onChange={(e) => {
                                    setStagedCalendarSettings((prevValues) => ({
                                        ...prevValues,
                                        driveTimeBufferMins: parseInt(
                                            e.target.value
                                        ),
                                    }));
                                }}
                            />
                            <p>Minutes</p>
                        </div>
                        <p className='scheduling-min-max'>Max: 60</p>
                    </div>
                    <div className='dashboard-scheduling-container'>
                        <h3>Booking Window</h3>
                        <div className='scheduling-input-container'>
                            <p>Booking Limit: </p>
                            <input
                                type='number'
                                step='10'
                                min='30'
                                max='180'
                                style={{ margin: '0 10px' }}
                                value={
                                    stagedCalendarSettings.bookingWindowEndDays
                                }
                                onChange={(e) =>
                                    setStagedCalendarSettings((prevValues) => ({
                                        ...prevValues,
                                        bookingWindowEndDays: parseInt(
                                            e.target.value
                                        ),
                                    }))
                                }
                            />
                            <p>days in advance</p>
                        </div>
                        <p className='scheduling-min-max'>Min: 30 Max: 180</p>
                        <div className='scheduling-input-container'>
                            <p>Minimum Notice: </p>
                            <input
                                type='number'
                                step='1'
                                min='0'
                                max='21'
                                style={{ margin: '0 10px' }}
                                value={
                                    stagedCalendarSettings.bookingWindowStartDays
                                }
                                onChange={(e) =>
                                    setStagedCalendarSettings((prevValues) => ({
                                        ...prevValues,
                                        bookingWindowStartDays: parseInt(
                                            e.target.value
                                        ),
                                    }))
                                }
                            />
                            <p>days</p>
                            <ToolTip message='Set the required lead time for new bookings' />
                        </div>
                        <p className='scheduling-min-max'>Min: 0 Max: 21</p>
                    </div>
                </div>
                <div className='dashboard-scheduling-right'>
                    <div className='dashboard-scheduling-container'>
                        <h3>Duration Estimation</h3>
                        <div className='scheduling-input-container'>
                            <p>Dollars per hour </p>
                            <input
                                type='number'
                                step='1'
                                min='20'
                                max='250'
                                style={{ margin: '0 10px' }}
                                value={stagedVendorSettings.priceToDurationRate}
                                onChange={(e) =>
                                    setStagedVendorSettings((prevValues) => ({
                                        ...prevValues,
                                        priceToDurationRate: parseInt(
                                            e.target.value
                                        ),
                                    }))
                                }
                            />
                        </div>
                        <p className='scheduling-min-max'>Max: 1000</p>
                        <p
                            style={{
                                marginTop: '10px',
                                display: 'inline-block',
                                backgroundColor: 'var(--secondary-white)',
                                padding: '15px',
                                fontSize: '14px',
                            }}
                        >
                            Example: A $100 appointment takes roughly{' '}
                            <strong>
                                {formatDuration(
                                    100 /
                                        stagedVendorSettings.priceToDurationRate
                                ) || '-.-'}
                            </strong>{' '}
                            to complete.
                        </p>
                    </div>
                    <div className='dashboard-scheduling-container'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <h3>Cancellation / Reschedule Policy</h3>
                            <ToolTip message='Get more bookings by having a lower, more flexible cancelation policy.' />
                        </div>
                        <div className='scheduling-input-container'>
                            <p>Require </p>
                            <input
                                type='number'
                                step='1'
                                min='0'
                                max='24'
                                style={{ margin: '0 10px' }}
                                required
                                value={
                                    stagedVendorSettings.cancelationPolicyHours
                                }
                                onChange={(e) =>
                                    setStagedVendorSettings((prevValues) => ({
                                        ...prevValues,
                                        cancelationPolicyHours: parseInt(
                                            e.target.value
                                        ),
                                    }))
                                }
                            />
                            <p>hours in advance</p>
                        </div>
                        <p className='scheduling-min-max'>Max: 24</p>
                    </div>
                    <div className='dashboard-scheduling-container'>
                        <h3>Appointment Instructions</h3>
                        <div className='scheduling-input-container'>
                            <textarea
                                style={{
                                    width: '100%',
                                    height: '120px',
                                    lineHeight: '20px',
                                    marginTop: '8px',
                                }}
                                className='textarea'
                                maxLength='500'
                                onChange={(e) =>
                                    setStagedVendorSettings((prevValues) => ({
                                        ...prevValues,
                                        appointmentInstructions: e.target.value,
                                    }))
                                }
                                placeholder={`Example: Thank you for booking with ${vendor?.name}! On the day of your booking you can prepare by...`}
                                defaultValue={
                                    stagedVendorSettings.appointmentInstructions
                                }
                            />
                        </div>
                        <p className='scheduling-min-max'>
                            Max Characters: 500
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DashboardScheduling;
