import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import './Home.css';
import { fetchVendors } from '../../../store/vendor.js';
import { getBookings } from '../../../store/bookings.js';
import VendorIndex from '../../vendors/VendorIndex/index.jsx';
import { Helmet } from 'react-helmet';

const Home = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const currentUser = useSelector((state) => state.session.user);
    const currentUserId = currentUser.id;

    const queryParams = new URLSearchParams(location.search);
    const initialCategory = queryParams.get('category') || 'all';
    const [selectedCategory, setSelectedCategory] = useState(initialCategory);

    useEffect(() => {
        dispatch(getBookings(currentUserId));
        dispatch(fetchVendors());
    }, [dispatch, currentUserId]);


    // Effect to update selectedCategory when URL changes
    useEffect(() => {
        const currentCategory = queryParams.get('category') || 'all';
        setSelectedCategory(currentCategory);
    }, [location.search]);

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
        history.push(`?category=${category}`);
    };
    

    return (
        <div className='home-main'>
            <Helmet>
                <meta name="description" content='Servo offers the most convenient way to book local professionals for home improvement services like Pest Control, Window Cleaning, and Home Cleaning, in St. George, UT. Enjoy a pristine, well-maintained home with our reliable experts.' />
                <title>Servo - Book Local Professionals for Home Improvement Services</title>
            </Helmet>
            <div className='home-header'>
                <h1>Services</h1>
            </div>
            <div className='filter-buttons-container'>
                <div
                    className={
                        selectedCategory === 'house_cleaning' ? 'active' : ''
                    }
                >
                    <span
                        onClick={() => handleCategoryChange('house_cleaning')}
                    >
                        House Cleaning
                    </span>
                </div>
                <div
                    className={
                        selectedCategory === 'pest_control' ? 'active' : ''
                    }
                >
                    <span onClick={() => handleCategoryChange('pest_control')}>
                        Pest Control
                    </span>
                </div>
                <div
                    className={
                        selectedCategory === 'window_cleaning' ? 'active' : ''
                    }
                >
                    <span
                        onClick={() => handleCategoryChange('window_cleaning')}
                    >
                        Window Cleaning
                    </span>
                </div>
                <div
                    className={
                        selectedCategory === 'garbage_can_cleaning' ? 'active' : ''
                    }
                >
                    <span
                        onClick={() => handleCategoryChange('garbage_can_cleaning')}
                    >
                        Garbage Can Cleaning
                    </span>
                </div>
            </div>

            {selectedCategory === 'all' ? (
                <>
                    <VendorIndex category={'house_cleaning'} fromHome={true} />
                    <VendorIndex category={'pest_control'} fromHome={true} />
                    <VendorIndex category={'window_cleaning'} fromHome={true} />
                    <VendorIndex category={'garbage_can_cleaning'} fromHome={true} />
                </>
            ) : (
                <VendorIndex category={selectedCategory} fromHome={true} />
            )}
        </div>
    );
};

export default Home;
