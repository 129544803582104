import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useEffect} from 'react';
import './Order.css';
import OrderIndexItem from './OrderIndexItem';
// import loadingGif from '../../../assets/images/loading-gif.gif';
// import { useHistory } from 'react-router-dom';
// import useLoading from '../../../hooks/useLoading';

const Orders = () => {
    // const history = useHistory();
    // const dispatch = useDispatch();

    // const userId = useSelector((state) => state.session.user.id);
    let orders = useSelector((state) => state?.orders);
    orders = Object.values(orders);

    // const { isLoading, startLoading, stopLoading } = useLoading();
    const [expandedOrder, setExpandedOrder] = useState(null); // state to track the id of the currently expanded order

    // useEffect(() => {
    //     startLoading();
    //     dispatch(fetchOrders(userId)).finally(() => stopLoading());
    // }, [userId, dispatch, history]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    const handleToggle = (orderId) => {
        setExpandedOrder(orderId === expandedOrder ? null : orderId);
    }; // toggle the expanded state of an order

    return (
        <div className='orders'>
            <div className='orders-main-container'>
                <div className='orders-index'>
                    <h1>Orders</h1>
                    {/* {!orders ? (
                        <div className='loading-user-orders-gif-div'>
                            <img
                                src={loadingGif}
                                className='loading-spinner-user-orders'
                                alt='loading spinner'
                            ></img>
                        </div>
                    ) : ( */}
                        <>
                            {Object.values(orders)
                                .reverse()
                                .map((order) => {
                                    return (
                                        <OrderIndexItem
                                            key={order.id}
                                            order={order}
                                            expanded={
                                                order.id === expandedOrder
                                            }
                                            onToggle={() =>
                                                handleToggle(order.id)
                                            }
                                        />
                                    );
                                })}
                        </>
                    {/* )} */}
                </div>
            </div>
        </div>
    );
};

export default Orders;
