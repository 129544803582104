import './VendorShow.css';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addDays, format, parseISO } from 'date-fns';
import ProviderMeta from './ProviderMeta/ProviderMeta.js';
import { updateBooking } from '../../../store/bookings.js';
import ProviderSummary from './ProviderSummary/ProviderSummary.js';
import ProviderGallery from './ProviderGallery/ProviderGallery.js';
import ProviderReviews from './ProviderReviews/ProviderReviews.js';
import ProviderPricing from './ProviderPricing/ProviderPricing.js';
import { useQueryParams } from '../../../util/urlQueryParams.js';
import ProviderScheduling from './ProviderScheduling/ProviderScheduling.js';
import { openModal } from '../../../store/ui.js';
import HoverTooltip from '../../common/HoverTooltip/index.jsx';
import { Helmet } from 'react-helmet';
import { getActiveAddress } from '../../../store/addresses.js';
import { fetchCalendarData } from '../../../store/vendor.js';

const VendorShowContent = ({ vendor, calendarData, galleryImageUrls }) => {
    const vendorId = vendor?.id;

    const history = useHistory();
    const dispatch = useDispatch();
    const queryParams = useQueryParams();

    const user = useSelector((state) => state.session.user);
    const activeAddress = useSelector(getActiveAddress);
    const serviceCharge = user?.serviceCharge || 2.55;
    const isVendor =
        user?.userType === 'vendor' && user?.vendorId === vendor?.id;
    const homeView = useSelector((state) => state.ui.homeView);
    const [displayDisclaimerBottom, setDisplayDisclaimerBottom] = useState(
        window.innerWidth < 1000
    );
    const [isCalendarLoading, setIsCalendarLoading] = useState(true);

    const reviews = useMemo(() => {
        return vendor?.reviews ? Object.values(vendor.reviews) : [];
    }, [vendor]);

    // if user is coming from order completion email to leave a review open the review form
    useEffect(() => {
        const openReviewFormModal = queryParams.open_review_form_modal;

        if (openReviewFormModal && vendorId && !isVendor && user) {
            dispatch(openModal('review-form', { vendorId }));
            // Change query parameter to false after opening review form
            history.push({ search: '?open_review_form_modal=false' });
        } else if (openReviewFormModal && !user) {
            dispatch(
                openModal('user-session-form', { sessionType: 'Sign In' })
            );
        }
    }, [
        dispatch,
        history,
        isVendor,
        queryParams.open_review_form_modal,
        user,
        vendorId,
    ]);

    // Grab existing booking (if any) that have a state of "priced", "scheduled", or "pending"
    const vendorBooking = useSelector((state) => {
        return Object.values(state.bookings).find((item) => {
            const parsedId = parseInt(vendorId, 10);
            return (
                item?.vendorId === parsedId &&
                item?.status !== 'paid' &&
                item?.status !== 'completed'
            );
        });
    });

    useEffect(() => {
        setDisplayDisclaimerBottom(window.innerWidth < 1000);
    }, [window.innerWidth]);

    useEffect(() => {
        if (homeView === 'vendor') history.push('/dashboard/orders');
    }, [homeView, history]);

    const [openComponent, setOpenComponent] = useState({
        pricing: true,
        scheduling: false,
        summary: false,
    });

    const nextAvailableAppointment =
        calendarData?.length > 0
            ? parseISO(calendarData[0].start_time)
            : addDays(new Date(), 2);
    const formattedNextAvailableAppointment = format(
        nextAvailableAppointment,
        'EEE, MMM do'
    );
    const bookingStatus = vendorBooking?.status;
    const allComponentsClosed = Object.values(openComponent).every(
        (val) => val === false
    );
    let isMobile = window.innerWidth < 700;

    useEffect(() => {
        dispatch(fetchCalendarData(vendorId, isVendor, vendorBooking?.duration)).finally(() =>
            setIsCalendarLoading(false)
        );
    }, [vendorBooking?.duration]);

    useEffect(() => {
        if (queryParams.open_payment_gateway) {
            dispatch(openModal('payment-gateway'));
        }
    }, [queryParams.open_payment_gateway, dispatch]);

    // Click handlers
    const handleScheduleClick = ({ bypass }) => {
        if ((bookingStatus && !openComponent.pricing) || bypass) {
            if (openComponent['summary'] === false) setIsCalendarLoading(true);
            setOpenComponent({
                pricing: false,
                scheduling: true,
                summary: false,
            });
        }
    };

    const handleGetPriceClick = () => {
        setOpenComponent({
            pricing: true,
            scheduling: false,
            summary: false,
        });
    };

    const handleSummaryClick = ({
        bypass = false,
        scheduling = false,
    } = {}) => {
        if (scheduling) {
            setOpenComponent({
                pricing: false,
                scheduling: true,
                summary: false,
            });
        } else if (
            ((bookingStatus === 'scheduled' || bookingStatus === 'pending') &&
                !openComponent.scheduling) ||
            bypass
        ) {
            setOpenComponent({
                pricing: false,
                scheduling: false,
                summary: true,
            });
        }
    };

    const handleCheckout = () => {
        let bookingData = {
            ...vendorBooking,
            status: 'pending',
            userId: user?.id,
            addressId: activeAddress?.id,
        };

        dispatch(updateBooking({ booking: bookingData }));
        dispatch(
            openModal('payment-gateway', {
                booking: bookingData,
                resetComponents,
            })
        );
    };

    const resetComponents = () => {
        setOpenComponent({
            pricing: true,
            scheduling: false,
            summary: false,
        });
    };

    let formattedDate = () => {
        const appointmentAtWithoutTimezone =
            vendorBooking?.appointmentAt?.replace(/([-+]\d{2}:\d{2})/, '');
        if (vendorBooking?.appointmentAt) {
            return isMobile
                ? format(appointmentAtWithoutTimezone, 'MMM do @ h:mm')
                : format(
                      appointmentAtWithoutTimezone,
                      'EEEE, MMMM do @ h:mmaaa'
                  );
        } else {
            return '--';
        }
    };

    const basePricingDiv = (
        <div className='pricing-preview'>
            Starting at: <br />$
            {vendor?.minPrice ? vendor.minPrice.toFixed(2) : '--'}
        </div>
    );
    const confirmedPricingDiv = (
        <div className='pricing-preview--confirmed'>
            Custom Quote
            <div className='green-text'>
                {' '}
                ${vendorBooking?.price.toFixed(2)}
            </div>
        </div>
    );

    const defaultSchedulingDiv = (
        <div className='scheduling-preview'>
            Next Available Appointment:
            <br />
            {formattedNextAvailableAppointment}
        </div>
    );

    const confirmedSchedulingDiv = (
        <div className='scheduling-preview--confirmed'>
            <div>Service Date</div>
            <p className='green-text'>{formattedDate()}</p>
        </div>
    );
    // helmet lets us change meta by vendor
    return (
        <div className='provider-show-container'>
            <Helmet>
                <title>
                    {vendor
                        ? `${vendor?.name} - Servo`
                        : 'Servo - Book Local Professionals for Home Improvement Services'}
                </title>
                <meta
                    name='description'
                    content={
                        vendor?.serviceDescription
                            ? vendor.serviceDescription
                            : `Book ${vendor?.name} for ${vendor?.category?.split('_').join(' ')} services in St. George, UT.`
                    }
                />
            </Helmet>
            <div className='provider-show'>
                <div className='provider-show-left'>
                    <ProviderMeta vendor={vendor} />
                    <ProviderReviews vendorId={vendorId} />
                    {!displayDisclaimerBottom && (
                        <div className='disclaimer'>
                            At Servo, we ensure a seamless connection with
                            skilled professionals. For clarity on our role and
                            responsibilities, we encourage you to review our
                            Terms of Service. Your privacy matters to us; our
                            Privacy Policy is designed with your security and
                            trust in mind.
                        </div>
                    )}
                </div>

                <div className='provider-show-right'>
                    <ProviderGallery
                        vendorId={vendor?.id}
                        deletable={false}
                        galleryImageUrls={galleryImageUrls}
                    />
                    <ProviderPricing
                        pricingOpen={openComponent.pricing}
                        onContinue={handleScheduleClick}
                        vendor={vendor}
                        booking={vendorBooking}
                        activeAddress={activeAddress}
                        setIsCalendarLoading={setIsCalendarLoading}
                    />
                    <div
                        className={`provider-pricing ${
                            openComponent.pricing ? 'minimize' : ''
                        }`}
                    >
                        <img
                            className='provider-price-icon'
                            src='https://spencerheywood.com/images/servo/icons/icons%203/icon_clear_bkgd/icons-04.png'
                            alt='get price icon servo instance price'
                        />
                        {vendorBooking ? confirmedPricingDiv : basePricingDiv}
                        <button
                            onClick={handleGetPriceClick}
                            className={`gray-out get-price-button green-out 
                                    ${vendorBooking ? 'anchor-style' : ''}`}
                        >
                            {vendorBooking ? 'Edit Service' : 'Get Price'}
                        </button>
                    </div>
                    {vendor && vendor.calendar && openComponent.scheduling && (
                        <ProviderScheduling
                            schedulingOpen={openComponent.scheduling}
                            calendarData={calendarData}
                            booking={vendorBooking}
                            onContinue={handleSummaryClick}
                            isCalendarLoading={isCalendarLoading}
                        />
                    )}

                    <div
                        className={`provider-scheduling ${
                            openComponent.scheduling ? 'minimize' : ''
                        }`}
                    >
                        <img
                            className='provider-calendar-icon'
                            src='https://spencerheywood.com/images/servo/icons/icons%203/icon_clear_bkgd/icons-08.png'
                            alt='schedule now servo icon'
                        />
                        {bookingStatus === 'scheduled' ||
                        bookingStatus === 'pending'
                            ? confirmedSchedulingDiv
                            : defaultSchedulingDiv}
                        {vendorBooking && !openComponent.pricing ? (
                            <button
                                onClick={handleScheduleClick}
                                disabled={
                                    !vendorBooking || openComponent.pricing
                                }
                                className={`gray-out schedule-button green-out ${bookingStatus && bookingStatus !== 'priced' ? 'anchor-style' : ''}`}
                            >
                                {bookingStatus && bookingStatus !== 'priced'
                                    ? 'Edit Booking'
                                    : 'Schedule'}
                            </button>
                        ) : (
                            <HoverTooltip text='Must get price before scheduling'>
                                <button
                                    onClick={handleScheduleClick}
                                    disabled={
                                        !vendorBooking || openComponent.pricing
                                    }
                                    className={`gray-out schedule-button green-out ${bookingStatus && bookingStatus !== 'priced' ? 'anchor-style' : ''}`}
                                >
                                    {bookingStatus && bookingStatus !== 'priced'
                                        ? 'Edit Booking'
                                        : 'Schedule'}
                                </button>
                            </HoverTooltip>
                        )}
                    </div>
                    <ProviderSummary
                        summaryOpen={openComponent.summary}
                        booking={vendorBooking}
                        vendor={vendor}
                        onCheckout={handleCheckout}
                        serviceCharge={serviceCharge}
                        onContinue={handleSummaryClick}
                        setOpenComponent={setOpenComponent}
                    />
                    <div
                        className={`provider-summary ${
                            openComponent.summary ? 'minimize' : ''
                        }`}
                    >
                        <img
                            className='provider-summary-icon'
                            src={
                                'https://spencerheywood.com/images/servo/icons/icons-07.png'
                            }
                            alt='mobile checkout icon'
                        />
                        <div className='summary-preview'>Summary</div>
                        {vendorBooking &&
                        bookingStatus !== 'priced' &&
                        allComponentsClosed ? (
                            <button
                                className={`${vendorBooking && bookingStatus !== 'priced' && allComponentsClosed ? 'green-out' : 'gray-out'}`}
                                onClick={handleSummaryClick}
                            >
                                {bookingStatus === 'scheduled' ||
                                bookingStatus === 'pending'
                                    ? 'Continue'
                                    : 'Checkout'}
                            </button>
                        ) : (
                            <HoverTooltip text='Must schedule appointment before checkout'>
                                <button
                                    disabled={
                                        !vendorBooking ||
                                        bookingStatus === 'priced' ||
                                        !allComponentsClosed
                                    }
                                    className={`gray-out ${vendorBooking && bookingStatus !== 'priced' && allComponentsClosed ? 'green-out' : 'gray-out'}`}
                                    onClick={handleSummaryClick}
                                >
                                    {bookingStatus === 'scheduled' ||
                                    bookingStatus === 'pending'
                                        ? 'Continue'
                                        : 'Checkout'}
                                </button>
                            </HoverTooltip>
                        )}
                    </div>
                    {displayDisclaimerBottom && (
                        <div className='disclaimer'>
                            At Servo, we ensure a seamless connection with
                            skilled professionals. It's important to remember
                            that Servo is a facilitator of these important
                            interactions. For clarity on our role and
                            responsibilities, we encourage you to review our
                            Terms of Service. Your privacy matters to us; our
                            Privacy Policy is designed with your security and
                            trust in mind.
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default VendorShowContent;
