import './PrivacyPolicy.css'
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy-container">
            <Helmet>
                <meta name="description" content='Servo offers the most convenient way to book local professionals for home improvement services like Pest Control, Window Cleaning, and Home Cleaning, in St. George, UT. Enjoy a pristine, well-maintained home with our reliable experts.' />
                <title>Servo - Learn About Our Privacy Policy</title>
            </Helmet>
            <div className="privacy-policy">
                <h1>Servo Privacy Policy</h1>
                <section>
                    <p><strong>Effective Date: April 2, 2024</strong></p>
                    <p>Welcome to Servo, LLC ("Servo", "we", "us", "our"). We respect your privacy and are committed to protecting it through our compliance with this Privacy Policy. This policy describes the types of information we may collect from you or that you may provide when you visit our website ("Website") and our practices for collecting, using, maintaining, protecting, and disclosing that information.</p>
                </section>
                <section>
                    <h2>Our Commitment to Your Privacy</h2>
                    <p>At Servo, LLC, we hold the privacy of your personal information in the highest regard. We do not sell or share your information with anyone, except as necessary to provide the services you request. Specifically, when you place an order through our Website, we share your name, address, phone number, service preferences, property details, order date, and email address only with the companies with whom you have placed an order. This sharing is essential for the fulfillment of the service you have requested from us and is conducted with your privacy in mind.</p>
                </section>
                <section>
                    <h2>Information We Collect About You and How We Collect It</h2>
                    <p>We collect several types of information from and about users of our Website, including information:</p>
                    <ul>
                        <li>By which you may be personally identified, such as name, postal address, email address, telephone number ("personal information");</li>
                        <li>That is about you but individually does not identify you, such as your home's square footage and your service preferences, which you may provide in our instant quote forms on service provider pages;</li>
                        <li>About your internet connection, the equipment you use to access our Website, and usage details.</li>
                    </ul>
                    <p>We collect this information:</p>
                    <ul>
                        <li>Directly from you when you provide it to us, including when you fill out instant quote forms or place an order.</li>
                        <li>Automatically as you navigate through the site. Information collected automatically may include usage details, IP addresses, and information collected through cookies and other tracking technologies.</li>
                    </ul>
                </section>
                <section>
                    <h2>How We Use Your Information</h2>
                    <p>We use information that we collect about you or that you provide to us, including any personal information:</p>
                    <ul>
                        <li>To present our Website and its contents to you.</li>
                        <li>To provide you with information, products, or services that you request from us, including generating instant quotes based on the square footage of your home and your service preferences.</li>
                        <li>To fulfill any other purpose for which you provide it.</li>
                        <li>To provide you with notices about your account/subscription, including expiration and renewal notices.</li>
                        <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.</li>
                        <li>To notify you about changes to our Website or any products or services we offer or provide through it.</li>
                        <li>To allow you to participate in interactive features on our Website.</li>
                        <li>In any other way we may describe when you provide the information.</li>
                        <li>For any other purpose with your consent.</li>
                    </ul>
                </section>
                <section>
                    <h2>Two-Way Google Calendar Integration for Vendors</h2>
                    <p>For vendors identified as "vendor" account types, we facilitate a two-way integration with Google Calendar. This feature is designed to utilize your calendar's event data to showcase real-time availability on our site and to dynamically schedule new appointments directly into your calendar based on user bookings. The integration supports not only the retrieval of existing events to determine and display your availability but also enables us to write new appointments into your calendar, ensuring seamless operational sync between your Servo listing and personal scheduling.</p>
                    <p>Upon opting into this integration, you allow us to:</p>
                    <ul>
                        <li>Access and retrieve event information from your Google Calendar to display current availability on your listing.</li>
                        <li>Write new appointments into your calendar when users book services with your business, facilitating real-time schedule updates.</li>
                        <li>Analyze event locations for future feature implementations, such as optimizing booking suggestions based on geographic efficiency.</li>
                    </ul>
                    <p>This comprehensive integration requires your explicit consent and is entirely voluntary. You maintain the ability to disconnect and manage this integration at any time through your vendor account settings. Our access to, and use of, your Google Calendar data is conducted with strict adherence to our terms of service with Google, Google’s Privacy Policy, and our privacy policy, emphasizing our commitment to your data's security and privacy. This feature is designed to enhance service delivery and operational efficiency for both vendors and customers, ensuring a seamless booking experience.</p>
                    <p style={{marginTop: '20px'}}>Servo's use and transfer of information received from Google APIs to any other app will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="blank">Google API Services User Data Policy</a>, including the Limited Use requirements.</p>
                </section>
                <section>
                    <h2>Data Retention and Management</h2>
                    <p>For the purpose of integrating with Google Calendar, we store minimal information necessary for the connection: the access token, refresh token, and token expiration date. This information is retained only as long as the integration is active to facilitate real-time synchronization with your Google Calendar for displaying available time slots.</p>
                    <p>Calendar events are not stored on our servers. Instead, event data is requested in real-time from Google Calendar by our backend server whenever a customer views the "schedule" container on your listing on BookServo.com. Only available time slots derived from this data are sent to the frontend client; no specific event details are retained.</p>
                    <p>In the future, should we decide to implement caching for performance improvements, we will update this policy accordingly to reflect how cached data is managed. Any such update will include details on how long cached data is kept and the measures taken to ensure its security and privacy.</p>
                    <p>If you choose to disconnect the Google Calendar integration, you can do so by clicking the "x" next to the connected status indicator in your vendor portal. This action will immediately delete the stored access and refresh tokens from our system, effectively ceasing the integration and preventing further data synchronization.</p>
                </section>
                <section>
                    <h2>Security Measures for Data Protection</h2>
                    <p>At Servo, LLC, we take the security of your data seriously. To protect the access tokens and any associated data obtained through the Google Calendar integration, we've implemented robust security measures, including:</p>
                    <ul>
                        <li>Encrypting data in transit using HTTPS, ensuring that any data sent to or from our servers is secure from interception.</li>
                        <li>Adhering to standard security conventions and best practices recognized in the industry to safeguard digital information.</li>
                        <li>Strict access controls and permissions are in place, allowing only authorized personnel to access sensitive data.</li>
                    </ul>
                </section>
                <section>
                    <h2>Compliance with Laws and Regulations</h2>
                    <p>At Servo, LLC, we are committed to upholding the highest standards of privacy and data protection laws and regulations applicable to our operations. This includes compliance with the General Data Protection Regulation (GDPR) for our users in the European Union, the California Consumer Privacy Act (CCPA) for California residents, and any other relevant data protection laws. Our practices are designed to respect your privacy rights and ensure transparent, lawful, and fair handling of your information.</p>
                </section>
                <section>
                    <h2>Payments</h2>
                    <p>For payment processing, we use third-party services (e.g., payment processors). Our third-party payment processor, Stripe, securely stores your card information for future use when you place an order on our Website. We do not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. All transactions require your explicit, on-session consent.</p>
                    <p>Stripe adheres to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express, and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</p>
                </section>
                <section className="contact-info">
                    <h2>Contact Information</h2>
                    <p>For questions, comments, or requests regarding this privacy policy and our privacy practices, please reach out to us:</p>
                    <p>Email: <a href="mailto:spencer@bookservo.com">contact@bookservo.com</a></p>
                    <p>Phone: <a href="tel:9717771485">(971) 777-1845</a></p>
                </section>
            </div>
        </div>
    )
}

export default PrivacyPolicy;
