import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../../store/ui';
import { ReactComponent as CloseIcon } from '../../../assets/svg/close.svg';
import './Modal.css';
import ReviewForm from '../../pages/Reviews/ReviewForm';
import VendorSignUpForm from '../../Session/VendorSignUpForm';
import PricingFormulaModal from '../../vendors/VendorDashboard/DashboardPricing/PricingFormulaModal';
import Carousel from '../Carousel';
import PaymentGateway from '../../orders/Checkout/paymentGateway/PaymentGateway';
import UpdatePasswordForm from '../../pages/UserAccount/UpdatePasswordForm';
import RescheduleModal from '../../orders/Order/RescheduleModal';
import PartialRefundForm from '../../pages/PartialRefundForm';
import ConfirmCancelOrderModal from '../ConfirmCancelOrderModal';
import ConfirmCancelOrderCustomerModal from '../ConfirmCancelOrderCustomerModal';
import OrderCompletionModal from '../../vendors/VendorDashboard/DashboardOrders/VendorOrderIndexItem/VendorOrderIndexItemDropdown/OrderCompletionModal';
import { removeErrors } from '../../../store/errors';
import UserSessionForm from '../../Session/UserSessionForm';
import ReviewsCarousel from '../../pages/Reviews/ReviewsCarousel';
import DisconnectCalendar from '../../vendors/VendorDashboard/DashboardScheduling/DisconnectCalendar';
import ConfirmPasswordModal from './ConfirmPasswordModal';

export default function Modal() {
    const dispatch = useDispatch();

    const modal = useSelector((state) => state?.ui?.modal);
    const errors = useSelector((state) => state.errors);

    useEffect(() => {
        if (!!modal) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        return () => document.body.classList.remove('no-scroll');
    }, [modal]);

    useEffect(() => {
        if (modal && errors && errors.length > 0) {
            dispatch(removeErrors());
        }
    }, [modal]);

    if (!modal) return null;

    let component;

    switch (modal.modalTitle) {
        case 'confirm-password-modal':
            component = <ConfirmPasswordModal props={modal.props} />;
            break;
        case 'user-session-form':
            component = <UserSessionForm props={modal.props} />;
            break;
        case 'vendor-sign-up-form':
            component = <VendorSignUpForm />;
            break;
        case 'update-password-form':
            component = <UpdatePasswordForm props={modal.props} />;
            break;
        case 'review-form':
            component = <ReviewForm props={modal.props} />;
            break;
        case 'payment-gateway':
            component = <PaymentGateway props={modal.props} />;
            break;
        case 'pricing-formula':
            component = <PricingFormulaModal props={modal.props} />;
            break;
        case 'order-cancellation-confirmation':
            component = <ConfirmCancelOrderModal props={modal.props} />;
            break;
        case 'customer-order-cancellation-confirmation':
            component = <ConfirmCancelOrderCustomerModal props={modal.props} />;
            break;
        case 'reschedule-appointment':
            component = <RescheduleModal props={modal.props} />;
            break;
        case 'partial-refund':
            component = <PartialRefundForm props={modal.props} />;
            break;
        case 'order-completion':
            component = <OrderCompletionModal props={modal.props} />;
            break;
        case 'disconnect-calendar':
            component = <DisconnectCalendar props={modal.props} />;
            break;
        case 'carousel':
            component = <Carousel props={modal.props} />;
            break;
        case 'reviews-carousel':
            component = <ReviewsCarousel props={modal.props} />;
            break;
        default:
            return null;
    }

    return (
        <div
            className='modal-backdrop'
            onMouseDown={() => dispatch(closeModal())}
        >
            <div
                className={`modal-content`}
                onMouseDown={(e) => e.stopPropagation()}
            >
                {component}
            </div>
        </div>
    );
}

export const ModalHeader = ({ headerText }) => {
    const dispatch = useDispatch();

    return (
        <div className='modal-header'>
            <h2>{headerText}</h2>
            <CloseIcon
                onClick={() => dispatch(closeModal())}
                className='close-icon'
            />
        </div>
    );
};
