import { useDispatch, useSelector } from 'react-redux';
import {
  deleteGalleryImage,
  updateVendorPicture,
} from '../../../../../../store/vendor';
import deleteIcon from '../../../../../../assets/images/x-circle-icon.png';
import { ReactComponent as PlusIcon } from '../../../../../../assets/svg/plus.svg';
import { useState } from 'react';

const ImagesStepGallery = ({ vendor, setIsUploading }) => {
  const dispatch = useDispatch();
  const [imagePreviews, setImagePreviews] = useState([]);
  const galleryImageUrls = useSelector(
    (state) => state?.session?.vendor?.galleryImageUrls || []
  );

  const handleDelete = (blobId) => {
    dispatch(deleteGalleryImage(blobId, vendor));
  };

  const handleUpdateGalleryImages = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 0) {
      setIsUploading(true);
      const newImagePreviews = files.map((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise((resolve) => {
          reader.onload = () => resolve(reader.result);
        });
      });

      Promise.all(newImagePreviews).then((previews) => {
        setImagePreviews(previews); // Initially set new previews

        const formData = new FormData();
        files.forEach((file) =>
          formData.append('vendor[galleryImages][]', file)
        );
        formData.append('id', vendor.id);

        dispatch(updateVendorPicture(formData)).then(() => {
          setIsUploading(false);
          setImagePreviews([]); // Clear previews once the images are uploaded
        });
      });
    }
  };

  return (
    <div className='gallery-container'>
      {galleryImageUrls.map(({ blobId, url }) => (
        <div key={blobId} className='image-container'>
          <img className='provider-photo' src={url} alt='' />
          <img
            src={deleteIcon}
            alt='Delete'
            onClick={() => handleDelete(blobId)}
            className='delete-button'
          />
        </div>
      ))}
      {imagePreviews.map((src, index) => (
        <div key={index} className='image-container'>
          <img className='provider-photo' src={src} alt='Preview' />
        </div>
      ))}
      <div className='provider-logo-background-upload' 
          onClick={() => document.getElementById('galleryFileInput').click()}>
        <PlusIcon
          className='plus-icon'
        />
        <input
          type='file'
          id='galleryFileInput'
          accept='image/*'
          multiple
          onChange={handleUpdateGalleryImages}
          style={{ display: 'none' }}
        />
      </div>
    </div>
  );
};

export default ImagesStepGallery;