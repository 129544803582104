import './JobberAuthorization.css';
import { ReactComponent as LinkIcon } from '../../../../../../assets/svg/link.svg';

const JobberAuthorization = () => {

  const jobberLogin = async () => {
      const clientId = 'c5875368-7ca1-4693-888f-fb15d150cd1e';

      function getRedirectUri() {
        const isProduction = window.location.hostname === 'bookservo.com';
        return isProduction
            ? encodeURIComponent('https://bookservo.com/dashboard/scheduling')
            : encodeURIComponent('http://localhost:3002/dashboard/scheduling');
      }

      const redirectUri = getRedirectUri();
      const url = `https://api.getjobber.com/api/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;
  
      window.location.href = url; // Redirect the user to Jobber's OAuth page
  };

  const calendarButton = () => {
    return (
      <button
        className='connect-google-calendar'
        onClick={() => jobberLogin()}
      >
        Connect <LinkIcon />
      </button>
    );
  };

  return calendarButton();
};

export default JobberAuthorization;
