import './OrdersFilter.css';
import { ReactComponent as FilterSvg } from '../../../../../assets/svg/filter.svg';
import useDropdown from '../../../../../hooks/useDropdown';
import '../VendorOrderIndexItem/VendorOrderIndexItemDropdown/VendorOrderIndexItemDropdown.css';

const OrdersFilter = ({ setShowOrders, showOrders }) => {
    const { isDropdownOpen, toggleDropdown, dropdownRef, closeDropdown } = useDropdown();

    const handleItemClick = (orderType) => {
        setShowOrders(orderType);
        closeDropdown();
    };

    return (
        <div
            className='orders-filter'
            ref={dropdownRef}
            onClick={() => toggleDropdown()}
        >
            <FilterSvg id={showOrders !== 'all' ? 'active' : ''} />

            {isDropdownOpen && (
                <ul
                    id='orders-filter-dropdown'
                    className='vendor-order-index-item-dropdown'
                    onClick={(e) => e.stopPropagation()}
                >
                    <li
                        onClick={() => handleItemClick('active')}
                        className={
                            showOrders === 'active' ? 'active-filter' : ''
                        }
                    >
                        Active Orders
                    </li>
                    <li
                        onClick={() => handleItemClick('past')}
                        className={showOrders === 'past' ? 'active-filter' : ''}
                    >
                        Past Orders
                    </li>
                    <hr />
                    <li onClick={() => handleItemClick('all')}>
                        Remove Filter
                    </li>
                </ul>
            )}
        </div>
    );
};

export default OrdersFilter;
