import { useDispatch, useSelector } from 'react-redux';
import './PricingFormulaModal.css';
import { closeModal } from '../../../../../store/ui';
import { useState } from 'react';
import PricingFormulaTips from './PricingFormulaTips';
import { setStagedFormula } from '../../../../../store/session';
import { ModalHeader } from '../../../../common/Modal';

const PricingFormulaModal = () => {
    const dispatch = useDispatch();

    const { formula, isFormulaValid } = useSelector(
        (state) => state.ui.modal?.props
    );

    const [newFormula, setNewFormula] = useState(formula);

    const handleTestChanges = () => {
        dispatch(setStagedFormula(newFormula));
        dispatch(closeModal());
    };

    return (
        <div id='pricing-formula-modal'>
            <ModalHeader headerText={'Update Pricing Formula'} />
            <h3 className='textarea-header'>Pricing Formula:</h3>
            <div className='formula-div'>
                <textarea
                    value={newFormula}
                    className={
                        isFormulaValid(newFormula)
                            ? 'formula-valid'
                            : 'formula-invalid'
                    }
                    onChange={(e) => setNewFormula(e.target.value)}
                />
                <button
                    disabled={!isFormulaValid(newFormula)}
                    onClick={handleTestChanges}
                    className='gray-out'
                >
                    Test Changes
                </button>
            </div>
            <PricingFormulaTips />
        </div>
    );
};

export default PricingFormulaModal;
