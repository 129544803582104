import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, useLocation, useHistory } from 'react-router-dom';
import DashboardScheduling from './DashboardScheduling/DashboardScheduling';
import DashboardPricing from './DashboardPricing/DashboardPricing';
import DashboardGeneral from './DashboardGeneral/DashboardGeneral';
import './ProviderDashboard.css';
import DashboardSetup from './DashboardSetup';
import DashboardSupport from './DashboardSupport';
import DashboardOrders from './DashboardOrders';

const ProviderDashboard = () => {
  const history = useHistory();
  const location = useLocation();
  const currentPage = location.pathname.split('/')[2];

  const vendor = useSelector((state) => state.session?.vendor);
  
  const listingStatus = vendor?.listingStatus

  useEffect(() => {
    if (listingStatus === 'awaiting_submission' || listingStatus === 'under_review') {
        history.push('/dashboard/setup');
    } else if(listingStatus === 'active' && currentPage === 'setup') {
      history.push('/dashboard/orders');
    }
  }, [listingStatus, history, currentPage]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [currentPage]);

  return (
    <div className='provider-dashboard'>
      <div
        className={
          currentPage !== 'setup' ? 'provider-dashboard-container' : ''
        }
      >
        <Route exact path='/dashboard/setup'>
          <DashboardSetup vendor={vendor} />
        </Route>
        <Route exact path='/dashboard/scheduling'>
          <DashboardScheduling vendor={vendor} />
        </Route>
        <Route exact path='/dashboard/pricing'>
          <DashboardPricing vendor={vendor} />
        </Route>
        <Route exact path='/dashboard/general'>
          <DashboardGeneral vendor={vendor} />
        </Route>
        <Route exact path='/dashboard/support'>
          <DashboardSupport />
        </Route>
        <Route exact path='/dashboard/orders'>
          <DashboardOrders />
        </Route>
        {/* <div className="servo-documentation">
            <a href="/terms-of-service">Terms of Service</a>
            <span> | </span>
            <a href="/privacy-policy">Privacy Policy</a>
        </div> */}
      </div>
    </div>
  );
};

export default ProviderDashboard;
