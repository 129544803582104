import { useDispatch, useSelector } from 'react-redux';
import './Errors.css';
import { useEffect } from 'react';
import { removeErrors } from '../../../store/errors';

const Errors = () => {
    const dispatch = useDispatch();

    const errors = useSelector((state) => state.errors);

    useEffect(() => {
        return () => {
            if (errors && errors.length > 0) {
                dispatch(removeErrors());
            }
        };
    }, [dispatch, errors]);

    if (!errors || errors.length === 0) return;

    return (
        <div className='errors'>
            <ul>
                {errors &&
                    errors?.map((error, idx) => {
                        if (error === "Password digest can't be blank")
                            return null;
                        if (error.includes('Addresses')) {
                            let correctedError = error.replace(
                                'Addresses',
                                'Address'
                            );
                            return <li key={idx}>{correctedError}</li>;
                        }
                        return <li key={idx}>{error}</li>;
                    })}
            </ul>
        </div>
    );
};

export default Errors;
