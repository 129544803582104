import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVendor } from '../../../store/vendor';
import { useEffect } from 'react';
import VendorShowContent from './VendorShowContent';

const VendorShow = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(fetchVendor(Number(id), history));
    }, [dispatch, id]);

    const vendor = useSelector((state) => state.vendors[id]);

    const calendarData = useSelector(
        (state) => state.vendors[id]?.calendar?.calendarData
    );

    const galleryImageUrls = useSelector(
        (state) => state.vendors[id]?.galleryImageUrls
    );

    return(
        vendor && (
            <VendorShowContent
                vendor={vendor}
                calendarData={calendarData}
                galleryImageUrls={galleryImageUrls}
            />
        ))
};

export default VendorShow;
