import { useDispatch } from 'react-redux';
import './ReviewIndexItem.css';
import { ReactComponent as GrayReviewStar } from '../../../../assets/svg/reviewStar.svg';
import GoogleIcon from '../../../../assets/images/google-icon.webp';
import { openModal } from '../../../../store/ui';
import { getServoNameColor } from '../../../../util/reviewsHelper';

const ReviewIndexItem = ({ review, reviews }) => {
    const dispatch = useDispatch();

    const { firstName, lastName } = review.author;
    const formattedAuthor = lastName ? `${firstName} ${lastName}` : firstName;

    const googleReviewImg = (
        <>
            <img
                src={review.authorImage}
                alt={review.firstName}
                className='google-author-img'
            />
            <img className='icon' src={GoogleIcon} alt='Google Logo' />
        </>
    );

    const servoReviewImg = (
        <>
            <div
                className='servo-name-circle'
                style={{
                    backgroundColor: getServoNameColor(review, formattedAuthor),
                }}
            >
                {formattedAuthor && formattedAuthor[0]}
            </div>
            <div className='icon' id='servo-icon-background'>
                <img
                    className='servo-icon'
                    src='https://spencerheywood.com/images/servo/icons/icons-07.png'
                    alt='Servo Logo'
                />
            </div>
        </>
    );

    return (
        <li
            className='review-index-item'
            onClick={() =>
                // dispatch(
                //     openModal('review-show', {
                //         review,
                //         formattedAuthor,
                //         randomColor: getColor(),
                //     })
                // )
                dispatch(
                    openModal('reviews-carousel', {
                        reviews,
                        review,
                        randomColor: getServoNameColor(review, formattedAuthor),
                    })
                )
            }
        >
            <div className='review-index-item-top'>
                <div className='review-index-item-top-left'>
                    <div className='author-image-div'>
                        {review.reviewType === 'google'
                            ? googleReviewImg
                            : servoReviewImg}
                    </div>
                    {formattedAuthor && <h3>{formattedAuthor}</h3>}
                </div>

                <div className='review-stars-container'>
                    {Array.from({ length: review?.score }, (_, index) => (
                        <GrayReviewStar key={index} />
                    ))}
                </div>
            </div>
            <p className='review-index-item-body'>
                {!review.body || review.body === ''
                    ? 'Rating only'
                    : review.body}
            </p>

            {/* {review.reviewType === 'servo' && (
                <div className='customer-verified-badge' style={{backgroundColor:'var(--primary-green)'}}>Verified</div>
            )} */}
        </li>
    );
};

export default ReviewIndexItem;
