import { useState } from 'react';
import './HoverTooltip.css';

const HoverTooltip = ({ text, children }) => {
    const [hovered, setHovered] = useState(false);

    return (
        <div
            className='hover-container'
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            {children}
            {hovered && (
                <div
                    className='hover-tooltip'
                    onMouseEnter={() => setHovered(false)}
                >
                    {text}
                </div>
            )}
        </div>
    );
};

export default HoverTooltip;
