import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './VendorIndex.css';
import VendorIndexItem from '../VendorIndexItem';
import { parsedCategory } from '../../../util/formatting';

const VendorIndex = ({ category, fromHome = false }) => {
    const history = useHistory();

    const allVendors = useSelector((state) => state.vendors);
    const homeView = useSelector((state) => state.ui?.homeView);
    const vendorId = useSelector((state) => state.session.user?.vendorId);

    const vendors = allVendors ? Object.values(allVendors).filter((vendor) => vendor.category === category) : [];
    const vendorCategory = parsedCategory(category);

    useEffect(() => {
        if (homeView === 'vendor') history.push('/dashboard/orders');
    }, [vendorId, homeView, history]);

    return (
        <>
            <div className='vendor-index-container'>
                {!fromHome && <h3>{vendorCategory}</h3>}
                <div className='vendor-index'>
                    <ul>
                        {vendors.map((vendor) => {
                            return (
                                <VendorIndexItem
                                    vendor={vendor}
                                    key={vendor.id}
                                />
                            );
                        })}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default VendorIndex;
