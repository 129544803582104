import ToolTip from '../../common/ToolTip';
import './VendorFormComponents.css';
import useDropdown from '../../../hooks/useDropdown';

const VendorSelector = ({
    name,
    stagedOptions,
    handleInputChange,
    handleValueChange,
    toolTip,
}) => {
    const { isDropdownOpen, toggleDropdown, dropdownRef } = useDropdown();

    const handleOptionClick = (option, e) => {
        handleInputChange({ value: option.value });
        toggleDropdown();
        e.stopPropagation();
    };

    return (
        <div className='custom-select vendor-form-component'>
            <label htmlFor={name} className='name-label'>
                {name}
                <ToolTip message={toolTip} />
            </label>
            <div
                className='select-wrapper vendor-custom-select'
                ref={dropdownRef}
                onClick={() => toggleDropdown('vendor-selector')}
            >
                <span className='select-one-span'>Select One</span>
                {isDropdownOpen && (
                    <ul className='options-list'>
                        {Object.entries(stagedOptions).map(([id, option]) => (
                            <li
                                key={id}
                                className='option-item'
                                onClick={(e) => handleOptionClick(option, e)}
                            >
                                <span className='option-name'>
                                    {option.name}
                                </span>
                                <div onClick={(e) => e.stopPropagation()}>
                                    <span>Value:</span>
                                    <input
                                        type='number'
                                        value={option.value}
                                        className='option-input'
                                        onChange={(e) =>
                                            handleValueChange(id, e)
                                        }
                                        step='0.01'
                                    />
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default VendorSelector;
