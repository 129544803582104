import { useHistory } from 'react-router-dom';
import './ImagesStep.css';
import ImagesStepGallery from './ImagesStepGallery';
import '../LogoStep.css';
import { useState } from 'react';
import loadingGif from '../../../../../../assets/images/loading-gif.gif'

const ImagesStep = ({ vendor }) => {
  const history = useHistory();
  const imagesStatus = vendor?.galleryImageUrls.length >= 4;
  const [isUploading, setIsUploading] = useState(false);

  return (
    <div className='images-step'>
      <h2>Upload Images</h2>
      <h3 className='add-images-text'>
        Add 4-20 images to your public profile
      </h3>
      <ImagesStepGallery vendor={vendor} setIsUploading={setIsUploading}/>
      {imagesStatus && !isUploading ? 
        <button
          onClick={() => history.push('/dashboard/setup?page=calendar')}
          className='continue-button'
        >
          Continue
        </button>
      : 
        (isUploading ? 
          <button className='continue-button gray-out' disabled>
            <img src={loadingGif} className="loading-spinner" alt='loading spinner'></img>
          </button> 
        :
          <button className='continue-button gray-out' disabled>
            Continue
          </button> 
        )
      }

    </div>
  );
};

export default ImagesStep;