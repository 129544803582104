import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { cancelOrder } from '../../../store/orders';
import '../ConfirmCancelOrderModal/ConfirmCancelOrderModal.css';
import { ModalHeader } from '../Modal';
import Errors from '../Errors';
import useLoading from '../../../hooks/useLoading';

const ConfirmCancelOrderCustomerModal = ({ props }) => {
    const { order, currentUser, vendor } = props;

    const dispatch = useDispatch();

    const { isLoading, startLoading, stopLoading } = useLoading();
    const [cancelReason, setCancelReason] = useState('');

    const handleCancelReasonChange = (e) => setCancelReason(e.target.value);

    const handleCancelOrder = (e) => {
        e.stopPropagation();

        if (isLoading) return;

        startLoading();

        const currentTime = new Date();
        const appointmentTime = new Date(order.appointmentAt);
        const cancellationDeadline = new Date(
            appointmentTime.getTime() -
                vendor.cancelationPolicyHours * 60 * 60 * 1000
        );

        if (currentUser.id === order.userId) {
            if (currentTime <= cancellationDeadline) {
                dispatch(cancelOrder(order, cancelReason)).finally(() =>
                    stopLoading()
                );
            } else {
                console.log(
                    `Cannot cancel order within ${vendor.cancelationPolicyHours} hours of the appointment time.`
                );
            }
        }
    };

    return (
        <div
            className='partial-refund-modal confirm-cancel-order-modal'
            id='update-password-form'
        >
            <ModalHeader headerText={'Confirm Order Cancellation'} />
            <p>Are you sure you want to cancel this order?</p>
            <div className='order-info'>
                <p>
                    <strong>Order Number:</strong> {order.id}
                </p>
                <p>
                    <strong>Appointment Time:</strong>
                    {new Date(order.appointmentAt).toLocaleString()}
                </p>
                <p>
                    <strong>Vendor:</strong> {vendor.name}
                </p>
            </div>
            <textarea
                value={cancelReason}
                onChange={handleCancelReasonChange}
                required
                placeholder='Reason for Cancellation'
            />
            <Errors />
            <button
                onClick={handleCancelOrder}
                className='gray-out submit-button'
                disabled={cancelReason === ''}
            >
                {isLoading ? 'Canceling Order...' : 'Cancel Order'}
            </button>
        </div>
    );
};

export default ConfirmCancelOrderCustomerModal;
