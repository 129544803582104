import { useEffect } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import StatusBar from "./StatusBar";
import LogoStep from './Steps/LogoStep';
import NextSteps from "./Steps/NextSteps";
import ImagesStep from './Steps/ImagesStep/';
import PayoutsStep from './Steps/PayoutsStep';
import CalendarStep from './Steps/CalendarStep';
import './DashboardSetup.css'

const DashboardSetup = ({ vendor }) => {
  const location = useLocation();
  const history = useHistory();
  const currentTab = location.search.split('=')[1];
  const listingStatus = vendor?.listingStatus

  useEffect(() => {
    const validTabs = ['logo', 'images', 'calendar', 'payouts', 'next-steps']

    if (!validTabs.includes(currentTab) && listingStatus !== 'under_review'){
      history.push('/dashboard/setup?page=logo')
    } else if (!validTabs.includes(currentTab) && listingStatus === 'under_review') {
      history.push('/dashboard/setup?page=next-steps')
    }
  }, [history, location, listingStatus, currentTab]); 
  
  return (   
    <div className="dashboard-setup">
      <StatusBar vendor={vendor} />
      <div className={`current-step-div ${currentTab === 'payouts' && 'payouts-no-padding'}`}  >
        {currentTab === 'logo' && <LogoStep vendor={vendor}/>}
        {currentTab === 'images' && <ImagesStep vendor={vendor}/>}
        {currentTab === 'calendar' && <CalendarStep vendor={vendor}/>}
        {currentTab === 'payouts' && <PayoutsStep vendor={vendor}/>}
        {currentTab === 'next-steps' && <NextSteps vendor={vendor}/>}
      </div>
    </div>
  );
}

export default DashboardSetup;


