import './ExpandedOrderIndexItem.css';
import {
    capitalizeFirstLetter,
    snakeToTitleCase,
} from '../../../../../util/formatting';

const ExpandedOrderIndexItem = ({
    order,
    vendor,
    options,
    formattedDate,
    formattedPrice,
}) => {
    const paymentStatus = snakeToTitleCase(order.paymentStatus);
    const wasRefunded =
        paymentStatus === 'Partially Refunded' || paymentStatus === 'Refunded';
    const paymentMethod = `...${order?.paymentMethod?.last4} ${order?.paymentMethod?.bankName.toUpperCase()}`;
    return (
        <div className='order-index-item-expanded-container'>
            <div className='expanded-view-left'>
                <h3>Summary</h3>
                <p
                    style={{
                        fontSize: '14px',
                        marginTop: '5px',
                        color: 'var(--secondary-blue)',
                    }}
                >
                    Order ID #{order.id}
                </p>
                <div className='index-item-info'>
                    <p>Appointment Date:</p>
                    <p>{formattedDate()}</p>
                </div>
                <div className='index-item-info'>
                    <p>Fulfillment Status:</p>
                    <p>{capitalizeFirstLetter(order.fulfillmentStatus)}</p>
                </div>
                <div className='index-item-info'>
                    <p>Payment Status:</p>
                    <p>{snakeToTitleCase(paymentStatus)}</p>
                </div>
                {wasRefunded && (
                    <div className='index-item-info'>
                        <p> Refunded </p>
                        <p>- ${parseFloat(order?.refundAmount).toFixed(2)}</p>
                    </div>
                )}
                <div className='index-item-info'>
                    <p>Total:</p>
                    <p>${formattedPrice}</p>
                </div>
                <div className='index-item-info'>
                    <p> Payment Method </p>
                    <p>
                        {paymentMethod}
                    </p>
                </div>
            </div>
            <div className='expanded-view-middle'>
                <h3>Service</h3>
                <p style={{ marginTop: '15px' }}>{vendor?.name}</p>
                <p>{vendor?.phoneNumber}</p>
                <p>{vendor?.email}</p>
                <h3 style={{ marginTop: '30px' }}>Instructions</h3>
                <p>
                    Thank you for booking with Dave's Pest Control! To get ready
                    for your appointment...
                </p>
            </div>
            <div className='expanded-view-right'>
                <h3>Details</h3>
                {options &&
                    Object.entries(options).map((entry, index) => (
                        <div className='index-item-info' key={index}>
                            <p>{capitalizeFirstLetter(entry[0])}</p>
                            <p>{entry[1]}</p>
                        </div>
                    ))}
                <h3 style={{ marginTop: '30px' }}>Notes</h3>
                <p style={{ margin: '10px 0', fontSize: '13px' }}>
                    {order?.customerNotes ? order.customerNotes : 'N/A'}
                </p>
            </div>
        </div>
    );
};

export default ExpandedOrderIndexItem;
