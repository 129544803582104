import { ReactComponent as Tooltip } from '../../../assets/svg/ToolTip.svg';
import './ToolTip.css'

const ToolTip = ({ message }) => {

  return (
    <div className='tooltip'>
      <Tooltip />
      <span className='tooltiptext'>{message}</span>
    </div>
  );
};

export default ToolTip;