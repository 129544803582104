import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import './ProviderReviews.css';
import ReviewIndexItem from '../../../pages/Reviews/ReviewIndexItem/index.jsx';
import { fetchReviews } from '../../../../store/reviews.js';
import { openModal } from '../../../../store/ui.js';
import HoverTooltip from '../../../common/HoverTooltip/index.jsx';
import ReviewsFilter from './ReviewsFilter/index.jsx';

const ProviderReviews = ({ vendorId }) => {
    const dispatch = useDispatch();

    const currentUser = useSelector((state) => state.session.user);
    const reviews = useSelector((state) => state.reviews);
    const orders = useSelector((state) => state.orders);

    const [filter, setFilter] = useState('all');

    useEffect(() => {
        if (vendorId) dispatch(fetchReviews(vendorId));
    }, [dispatch, vendorId]);

    const formattedReviews = Object.values(reviews).sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    const filteredReviews = formattedReviews.filter((review) => {
        return filter === 'all' || review.reviewType === filter;
    });

    const isVendor = currentUser?.userType === 'vendor';
    const isUserReviewed = formattedReviews.some(
        (review) => review.userId === currentUser?.id
    );
    const isPrevCustomer = Object.values(orders).some(
        (order) => order.vendorId === Number(vendorId)
    );

    const renderReviewButton = () => {
        if (isVendor) return null;

        if (isUserReviewed || !isPrevCustomer || !currentUser) {
            const text = isUserReviewed
                ? "You've already reviewed this vendor"
                : 'You must place an order with this vendor to leave them a review';

            return (
                <HoverTooltip text={text}>
                    <button className='vendor-review-button gray-out' disabled>
                        Share Your Experience
                    </button>
                </HoverTooltip>
            );
        }

        return (
            <button
                className='vendor-review-button'
                onClick={() => dispatch(openModal('review-form', { vendorId }))}
            >
                Share Your Experience
            </button>
        );
    };

    return (
        <div className='reviews'>
            <div className='reviews-header'>
                <div className='reviews-header-left'>
                    <h3>Reviews</h3>
                    <ReviewsFilter filter={filter} setFilter={setFilter} />
                </div>
                {renderReviewButton()}
            </div>
            <ul className='provider-review-index'>
                {filteredReviews.length > 0 ? (
                    filteredReviews.map((review) => (
                        <ReviewIndexItem
                            key={review?.id}
                            review={review}
                            reviews={reviews}
                        />
                    ))
                ) : (
                    <div className='no-reviews-yet'>No reviews yet.</div>
                )}
            </ul>
        </div>
    );
};

export default ProviderReviews;
