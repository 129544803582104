import { useState } from 'react';
import { ModalHeader } from '../../common/Modal';
import SignInForm from './SignInForm';
import SignUpForm from './SignUpForm';
import './UserSessionForm.css';

export default function UserSessionForm({ props }) {
    const initialSessionType = props.sessionType;
    const [sessionType, setSessionType] = useState(initialSessionType);

    let modalHeaderText;

    if (props?.isOnGuestCheckout) {
        modalHeaderText = 'Booking Information';
    } else if (sessionType === 'Sign Up') {
        modalHeaderText = 'New Login';
    } else {
        modalHeaderText = 'Sign In';
    }

    return (
        <div className='user-session-form'>
            <ModalHeader headerText={modalHeaderText} />

            <div className='form-toggle'>
                <button
                    className={`toggle-button ${sessionType === 'Sign Up' ? 'active' : 'not-active'}`}
                    onClick={() => setSessionType('Sign Up')}
                >
                    Sign Up
                </button>
                <button
                    className={`toggle-button ${sessionType === 'Sign In' ? 'active' : 'not-active'}`}
                    onClick={() => setSessionType('Sign In')}
                >
                    Sign In
                </button>
            </div>

            {sessionType === 'Sign In' ? (
                <SignInForm props={props} />
            ) : (
                <SignUpForm props={props} />
            )}
        </div>
    );
}
