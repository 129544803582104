import LoginNav from "./LoginNav";
import HomeNav from "./HomeNav";
import Menu from "./Menu";
// import Cart from "../Cart";
import { useSelector } from "react-redux";
import { isLoggedIn } from "../../../store/session";
import { useState } from "react";
import VendorMobileMenu from "./Menu/VendorMobileMenu";
// import { toggleCart } from "../store/cart";

const NavigationBar = () => {
    const userLoggedIn = useSelector(isLoggedIn)
    const [isMenuOpen, setIsMenuOpen] = useState();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen)
    }

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen)
    }

    return(
        <>
            <LoginNav display={!userLoggedIn} />
            <HomeNav display={userLoggedIn} toggleMenu={toggleMenu} toggleMobileMenu={toggleMobileMenu}/>
            <Menu isMenuOpen={isMenuOpen} closeMenu={()=>{setIsMenuOpen(false)}}/>
            <VendorMobileMenu isMobileMenuOpen={isMobileMenuOpen} closeMobileMenu={()=>setIsMobileMenuOpen(false)}/>
            {/* <Cart /> */}
        </>
    )
}

export default NavigationBar;