import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import './SecondaryNav.css';
import { useState, useEffect } from 'react';

const SecondaryNav = () => {
    const history = useHistory();
    const location = useLocation();

    const pages = ['orders', 'general', 'scheduling', 'pricing'];
    const homeView = useSelector((state) => state.ui.homeView);
    const vendorListingStatus = useSelector(
        (state) => state.session?.vendor.listingStatus
    );

    const [isVendorSetup, setIsVendorSetup] = useState(false);

    const currentPage = location.pathname.split('/')[2];

    useEffect(() => {
        if (
            vendorListingStatus === 'awaiting_submission' ||
            vendorListingStatus === 'under_review'
        ) {
            setIsVendorSetup(false);
        } else {
            setIsVendorSetup(true);
        }
    }, [vendorListingStatus]);

    const handlePageClick = (name) => {
        history.push(`/dashboard/${name}`);
    };

    return (
        <div className='secondary-nav-bar'>
            {homeView === 'vendor' ? (
                <div className='subheader home-nav'>
                    <div className='subheader-left'>
                        {!isVendorSetup && (
                            <span
                                className={`active no-hover-effect`}
                                onClick={() => {
                                    handlePageClick('setup');
                                }}
                            >
                                Setup
                            </span>
                        )}
                        {pages.map((pageName, idx) => (
                            <span
                                key={idx}
                                className={`${currentPage === pageName && isVendorSetup ? 'active' : ''} ${!isVendorSetup ? 'gray-out-text' : ''}`}
                                onClick={() =>
                                    isVendorSetup &&
                                    history.push(`/dashboard/${pageName}`)
                                }
                            >
                                {pageName.charAt(0).toUpperCase() +
                                    pageName.slice(1)}
                            </span>
                        ))}
                    </div>
                    <div style={{ marginRight: 'calc(5% + 20px)' }}>
                        <span
                            className={`support ${currentPage === 'support' ? 'active' : ''} ${!isVendorSetup ? 'gray-out-text' : ''}`}
                            onClick={() =>
                                isVendorSetup &&
                                history.push('/dashboard/support')
                            }
                        >
                            Support
                        </span>
                    </div>
                </div>
            ) : (
                <div className='subheader user-view-banner'>
                    Currently in customer view mode.
                </div>
            )}
        </div>
    );
};

export default SecondaryNav;
