import { useEffect } from 'react';
import './Selector.css'

const Selector = ({name, options, onChange, prefilledUserInput}) => {
    const prefilledUserInputValue = Object.values(options).find(option => option.name === prefilledUserInput)?.value
    const handleChange = (selectedElement) => {
        const selectedOption = selectedElement.options[selectedElement.selectedIndex];
        const dataName = selectedOption.getAttribute('data-name');
        onChange({label: name, value: selectedElement.value, name: dataName})
    }

    useEffect(() => { 
        if (prefilledUserInputValue) {
            onChange({label: name, value: prefilledUserInputValue, name: prefilledUserInput });  
        }
    }, [])         

    return (
        <div className="custom-select">
            <label htmlFor={name}>{name}</label>
            <div className="select-wrapper">
                <select id={name} onChange={e => handleChange(e.target)} defaultValue={prefilledUserInputValue || 'default'}>
                    <option disabled value='default'>Select One</option>
                    {options && Object.values(options).map((option, index) => {
                        return <option key={index} value={option?.value} data-name={option?.name}>{option?.name}</option>
                    })}
                </select>
            </div>
        </div>
    )
}

export default Selector;