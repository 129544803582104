import { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './DashboardOrders.css';
import DashboardHeader from '../DashboardHeader';
import VendorOrderIndexItem from './VendorOrderIndexItem';
import OrdersFilter from './OrdersFilter';
// import loadingGif from '../../../../assets/images/loading-gif.gif';
// import useLoading from '../../../../hooks/useLoading';

const DashboardOrders = () => {
    // const dispatch = useDispatch();
    // const userId = useSelector((state) => state?.session?.vendor?.id);
    let orders = useSelector((state) => state?.orders);
    orders = Object.values(orders);

    // const { isLoading, startLoading, stopLoading } = useLoading();
    const [showOrders, setShowOrders] = useState('all');
    const [expandedOrder, setExpandedOrder] = useState(null); // state to track the id of the actively expanded order

    // useEffect(() => {
    //     setIsLoading(true);
    //     dispatch(fetchOrders(userId)).finally(() => setIsLoading(false));
    // }, [orders.length, dispatch, userId]);

    const handleToggle = (orderId) => {
        // toggle the expanded state of an order
        setExpandedOrder(orderId === expandedOrder ? null : orderId);
    };

    const tableHeader = (
        <div className='table-header'>
            <div className='table-header-column'>
                <h4>Order</h4>
            </div>
            <div className='table-header-column'>
                <h4>Name</h4>
            </div>
            <div className='table-header-column'>
                <h4>Appointment</h4>
            </div>
            <div className='table-header-column'>
                <h4>Fulfillment Status</h4>
            </div>
            <div className='table-header-column'>
                <h4>Payment Status</h4>
            </div>
            <div className='table-header-column'>
                <h4>Price</h4>
            </div>
            <div className='table-header-column'>
                <div></div>
            </div>
        </div>
    );

    const getSortedOrders = (orders, ascending = true) => {
        return orders.sort((a, b) =>
            ascending
                ? new Date(a.appointmentAt) - new Date(b.appointmentAt)
                : new Date(b.appointmentAt) - new Date(a.appointmentAt)
        );
    };

    const getFilteredOrders = (orders, isActive) => {
        return orders.filter((order) =>
            isActive
                ? order.fulfillmentStatus !== 'fulfilled' &&
                  order.fulfillmentStatus !== 'cancelled'
                : order.fulfillmentStatus === 'fulfilled' ||
                  order.fulfillmentStatus === 'cancelled'
        );
    };

    const activeOrders = useMemo(() => {
        return getSortedOrders(getFilteredOrders(orders, true), true);
    }, [orders]);

    const pastOrders = useMemo(() => {
        return getSortedOrders(getFilteredOrders(orders, false), false);
    }, [orders]);

    const renderActiveOrders = () => {
        return (
            <div className='upcoming-orders-index'>
                <h2 style={{ margin: '20px 0 10px 0' }}>Active Orders</h2>
                {activeOrders.length > 0 && tableHeader}
                {activeOrders.length === 0 ? (
                    <p style={{ marginTop: '20px' }}>No active orders.</p>
                ) : (
                    activeOrders.map((order) => (
                        <VendorOrderIndexItem
                            key={order.id}
                            order={order}
                            expanded={order.id === expandedOrder}
                            onToggle={() => handleToggle(order.id)}
                        />
                    ))
                )}
            </div>
        );
    };

    const renderPastOrders = () => {
        return (
            <div className='past-orders-index'>
                <h2 style={{ margin: '20px 0 10px 0' }}>Past Orders</h2>
                {pastOrders.length > 0 && tableHeader}
                {pastOrders.length === 0 ? (
                    <p style={{ marginTop: '20px' }}>No past orders.</p>
                ) : (
                    pastOrders.map((order) => (
                        <VendorOrderIndexItem
                            key={order.id}
                            order={order}
                            expanded={order.id === expandedOrder}
                            onToggle={() => handleToggle(order.id)}
                            past={true}
                        />
                    ))
                )}
            </div>
        );
    };

    const renderAllOrders = () => {
        return (
            <div className='past-orders-index'>
                <h2 style={{ margin: '20px 0 10px 0' }}>All Orders</h2>
                {(pastOrders.length > 0 || activeOrders.length) > 0 &&
                    tableHeader}
                {pastOrders.length === 0 && activeOrders.length === 0 ? (
                    <p style={{ marginTop: '20px' }}>No orders.</p>
                ) : (
                    activeOrders
                        .concat(pastOrders)
                        .map((order) => (
                            <VendorOrderIndexItem
                                key={order.id}
                                order={order}
                                expanded={order.id === expandedOrder}
                                onToggle={() => handleToggle(order.id)}
                                past={true}
                            />
                        ))
                )}
            </div>
        );
    };

    return (
        <>
            <DashboardHeader pageTitle={'Order History'}>
                <OrdersFilter
                    setShowOrders={setShowOrders}
                    showOrders={showOrders}
                />
            </DashboardHeader>
            <div className='dashboard-orders'>
                <div key={orders.length} className='orders-container'>
                    {/* {!orders ? (
                        <div className='loading-orders-gif-div'>
                            <img
                                src={loadingGif}
                                className='loading-spinner-orders'
                                alt='loading spinner'
                            ></img>
                        </div>
                    ) : ( */}
                        <>
                            {showOrders === 'active' && renderActiveOrders()}
                            {showOrders === 'past' && renderPastOrders()}
                            {showOrders === 'all' && renderAllOrders()}
                        </>
                    {/* )} */}
                </div>
            </div>
        </>
    );
};

export default DashboardOrders;
