import './OrderIndexItemDropdown.css';

const OrderIndexItemDropdown = ({
    isRescheduleDisabled,
    handleRescheduleClick,
    canCancelOrder,
    handleCancelClick,
    fulfillmentStatus,
}) => {

    return (
        <div
            className='vendor-order-index-item vendor-order-index-item-dropdown'
            id='order-index-item-dropdown'
            onClick={(e) => e.stopPropagation()}
        >
            <ul>
                <li
                    onClick={handleRescheduleClick}
                    className={isRescheduleDisabled() ? 'not-allowed' : ''}
                >
                    Reschedule
                </li>
                <hr />

                <li
                    className={!canCancelOrder ? 'not-allowed' : ''}
                    onClick={handleCancelClick}
                >
                    {fulfillmentStatus === 'cancelled'
                        ? 'Cancelled'
                        : 'Cancel Order'}
                </li>
            </ul>
        </div>
    );
};

export default OrderIndexItemDropdown;
