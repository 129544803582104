import { useSelector } from 'react-redux';
import './Footer.css'
import { useHistory } from 'react-router-dom';

const Footer = () => {
    const userType = useSelector(state => state.session?.user?.userType)
    const history = useHistory();

    const handleLinkClick = (location) => {
        history.push(location)
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    return (
        <footer>
            <div className="footer">
                <div className="footer-column">
                    <h3>About</h3>
                    <a onClick={() => handleLinkClick('/about-us')}>About Us</a>
                    <a onClick={() => handleLinkClick('/privacy-policy')}>Privacy Policy</a>
                    <a onClick={() => handleLinkClick('/terms-of-service')}>Terms of Service</a>
                </div>
                <div className="footer-column">
                    <h3>Businesses</h3>
                    <a onClick={()=> handleLinkClick('/vendor-onboarding')}>{userType === "vendor" ? <>Learn More</> : <>Sign Up</>}</a>
                </div>
                <div className="footer-column">
                    <h3>Get in Touch</h3>
                    <a onClick={()=> handleLinkClick('/contact-us')}>Contact Us</a>
                </div>
            </div>
        </footer>
    )
}

export default Footer;