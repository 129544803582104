import './LoginNav.css';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import LogoSvg from '../../../../assets/svg/LogoSvg';
import { openModal } from '../../../../store/ui';

const LoginNav = ({ display }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const isOnSplashPage = location.pathname === '/';
    const isOnShowPage = location.pathname.includes('vendors');

    if (!display) return null;

    const handleSignUpOpen = () => {
        if (isOnShowPage) {
            dispatch(
                openModal('user-session-form', {
                    sessionType: 'Sign Up',
                    isOnShowPage,
                })
            );
        } else {
            dispatch(
                openModal('user-session-form', {
                    sessionType: 'Sign Up',
                })
            );
        }
    };

    const handleSignInOpen = () =>
        dispatch(openModal('user-session-form', { sessionType: 'Sign In' }));

    return (
        <div
            className='main-nav-bar'
            id={`${!isOnSplashPage && 'about-us-nav-bar'}`}
        >
            {isOnSplashPage && <div></div>}
            <LogoSvg
                onClick={() => history.push('/')}
                alt="White 'SERVO' text logo with a yellow checkmark, official logo for a St. George, Utah-based company offering online booking for home services like cleaning and pest control."
            />
            <div className='main-nav-links'>
                {location.pathname !== '/vendor-onboarding' ? (
                    <button
                        className='main-sign-up-button button'
                        onClick={handleSignUpOpen}
                    >
                        Create Account
                    </button>
                ) : (
                    <button
                        onClick={() =>
                            dispatch(openModal('vendor-sign-up-form'))
                        }
                        style={{
                            width: '150px',
                            backgroundColor: 'var(--primary-yellow)',
                        }}
                    >
                        List Your Service
                    </button>
                )}
                <button
                    className='main-sign-in-button button'
                    onClick={handleSignInOpen}
                    style={
                        location.pathname === '/vendor-onboarding'
                            ? { backgroundColor: 'var(--primary-blue)' }
                            : {}
                    }
                >
                    Sign In
                </button>
            </div>
        </div>
    );
};

export default LoginNav;
