import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './SignUpForm.css';
import { signUp } from '../../../../store/session';
import Errors from '../../../common/Errors';
import useLoading from '../../../../hooks/useLoading';

const SignUpForm = ({ props }) => {
    const dispatch = useDispatch();
    let history = useHistory();

    const guestInputs = useSelector((state) => state.session?.inputs);

    const { isLoading, startLoading, stopLoading } = useLoading();
    const [signUpFormValues, setSignUpFormValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        password: '',
        addressesAttributes: {
            streetAddress: '',
            street_address_2: '',
            city: '',
            state: 'UT',
            zipCode: '',
            default: true,
        },
    });

    const submitHandler = (e) => {
        e.preventDefault();

        if (isLoading) return;

        startLoading();

        const formattedData = {
            ...signUpFormValues,
            addressesAttributes: [signUpFormValues.addressesAttributes],
        };

        if (props?.isOnShowPage || props?.isOnGuestCheckout) {
            history = null;
        }

        if (guestInputs) formattedData.userInputs = guestInputs;

        dispatch(
            signUp(
                formattedData,
                history,
                props?.isOnGuestCheckout,
                props?.onGuestSignUpOrInSuccess
            )
        ).finally(() => stopLoading());
    };

    return (
        <form onSubmit={submitHandler} id='sign-up-form'>
            <div className='sign-up-form-section'>
                <input
                    type='text'
                    placeholder='First Name'
                    style={{ marginRight: '7px', width: '50%' }}
                    value={signUpFormValues.firstName}
                    onChange={(e) =>
                        setSignUpFormValues((prevValues) => ({
                            ...prevValues,
                            firstName: e.target.value,
                        }))
                    }
                />
                <input
                    type='text'
                    placeholder='Last Name'
                    style={{ width: '50%' }}
                    value={signUpFormValues.lastName}
                    onChange={(e) =>
                        setSignUpFormValues((prevValues) => ({
                            ...prevValues,
                            lastName: e.target.value,
                        }))
                    }
                />
            </div>
            <input
                type='text'
                placeholder='Email'
                value={signUpFormValues.email}
                onChange={(e) =>
                    setSignUpFormValues((prevValues) => ({
                        ...prevValues,
                        email: e.target.value,
                    }))
                }
            />
            <input
                type='tel'
                placeholder='Phone Number'
                value={signUpFormValues.phoneNumber}
                onChange={(e) =>
                    setSignUpFormValues((prevValues) => ({
                        ...prevValues,
                        phoneNumber: e.target.value,
                    }))
                }
            />
            <input
                type='text'
                placeholder='Street Address'
                value={signUpFormValues.addressesAttributes.streetAddress}
                onChange={(e) =>
                    setSignUpFormValues((prevValues) => ({
                        ...prevValues,
                        addressesAttributes: {
                            ...prevValues.addressesAttributes,
                            streetAddress: e.target.value,
                        },
                    }))
                }
            />
            <input
                type='text'
                placeholder='Street Address 2 (optional)'
                value={signUpFormValues.addressesAttributes.street_address_2}
                onChange={(e) =>
                    setSignUpFormValues((prevValues) => ({
                        ...prevValues,
                        addressesAttributes: {
                            ...prevValues.addressesAttributes,
                            street_address_2: e.target.value,
                        },
                    }))
                }
            />
            <div className='sign-up-form-section'>
                <input
                    type='text'
                    placeholder='City'
                    value={signUpFormValues.addressesAttributes.city}
                    style={{ marginRight: '7px', maxWidth: '150px' }}
                    onChange={(e) =>
                        setSignUpFormValues((prevValues) => ({
                            ...prevValues,
                            addressesAttributes: {
                                ...prevValues.addressesAttributes,
                                city: e.target.value,
                            },
                        }))
                    }
                />
                <select style={{ marginRight: '7px' }}>
                    <option>UT</option>
                </select>
                <select
                    onChange={(e) =>
                        setSignUpFormValues((prevValues) => ({
                            ...prevValues,
                            addressesAttributes: {
                                ...prevValues.addressesAttributes,
                                zipCode: e.target.value,
                            },
                        }))
                    }
                >
                    <option>Zip Code</option>
                    <option>84765</option>
                    <option>84770</option>
                    <option>84780</option>
                    <option>84790</option>
                </select>
            </div>
            <input
                type='password'
                placeholder='Create Password'
                value={signUpFormValues.password}
                onChange={(e) =>
                    setSignUpFormValues((prevValues) => ({
                        ...prevValues,
                        password: e.target.value,
                    }))
                }
            />
            <Errors />
            <p style={{ textAlign: 'center', alignSelf: 'center' }}>
                By clicking “Continue” you agree to Servo’s
                <br />
                <a href='/terms-of-service'>Terms of Service</a> and{' '}
                <a href='/privacy-policy'>Privacy Policy</a>.
            </p>
            <button
                type='submit'
                className='gray-out'
                disabled={
                    signUpFormValues.firstName === '' ||
                    signUpFormValues.lastName === '' ||
                    signUpFormValues.email === '' ||
                    signUpFormValues.streetAddress === '' ||
                    signUpFormValues.phoneNumber === '' ||
                    signUpFormValues.city === '' ||
                    signUpFormValues.password === '' ||
                    signUpFormValues.zipCode === ''
                }
            >
                {isLoading ? 'Processing...' : 'Continue'}
            </button>
            {/* this will be changed when we add real states & zip codes*/}
        </form>
    );
};

export default SignUpForm;
