import { ReactComponent as StarSvg } from '../../../../assets/svg/reviewStar.svg';
import { formatPhoneNumber } from '../../../../util/formatting';
import './ProviderMeta.css';
import { ReactComponent as EmailIcon } from '../../../../assets/svg/envelope-icon.svg';
import { ReactComponent as PhoneNumberIcon } from '../../../../assets/svg/phone-call-icon.svg';
import {ReactComponent as AddressIcon} from '../../../../assets/svg/maps-pin-black-icon.svg'

const ProviderMeta = ({ vendor }) => {
    return (
        <>
            <div className='meta-info-block'>
                <div className='provider-logo-background'>
                    <img
                        className='provider-logo'
                        src={vendor?.logoImageUrl}
                        alt='provider logo'
                    />
                </div>
                <div className='meta-info-container'>
                    <h1 className='provider-name'>
                        {vendor?.name ? vendor.name : '--'}
                    </h1>
                    <p className='review-tag'>
                        {vendor?.avgRating ? vendor.avgRating : 'New Service! '}
                        <StarSvg className='review-star-svg' />
                        {vendor?.reviewCount} ratings
                    </p>
                </div>
            </div>
            <div className='phone-number-email-container'>
                <div className='phone-number-email-info-vendor'>
                    <a style={{textDecoration:'none'}}> <AddressIcon className='vendor-info-icon address-icon'/> St. George</a>
                    <a
                        style={{ textDecoration: 'none' }}
                        href={`tel:${vendor?.phoneNumber}`}
                    >
                        <PhoneNumberIcon className='vendor-info-icon' />
                        {formatPhoneNumber(vendor?.phoneNumber)}
                    </a>
                    <a
                        style={{ textDecoration: 'none' }}
                        href={`mailto:${vendor?.email}`}
                    >
                        <EmailIcon className='vendor-info-icon' />{' '}
                        {vendor?.email}
                    </a>
                </div>
            </div>
            <div className='service-description-cancellation-container'>
                <h3 className='service-description-header'>Description</h3>
                <p
                    style={
                        vendor?.serviceDescription
                            ? {}
                            : { color: 'var(--primary-gray)' }
                    }
                >
                    {vendor?.serviceDescription
                        ? vendor.serviceDescription
                        : 'This service provider has not written a service description yet.'}
                </p>
                <hr className='meta-hr'></hr>
                <h3 className='service-description-header'>Cancellation / Reschedule Policy</h3>
                <p className='cancellation-policy-text'>Appointments with {vendor?.name} can be automatically rescheduled or cancelled until {vendor?.cancelationPolicyHours} hour{vendor?.cancelationPolicyHours > 1 ? 's' : ''} before your appointment time.</p>
            </div>
        </>
    );
};

export default ProviderMeta;
