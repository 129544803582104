import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './SignInForm.css';
import { signIn } from '../../../../store/session';
import Errors from '../../../common/Errors';
import { closeModal } from '../../../../store/ui';
import useLoading from '../../../../hooks/useLoading';

const SignInForm = ({ props }) => {
    let history = useHistory();
    const dispatch = useDispatch();

    const guestInputs = useSelector((state) => state.session?.inputs);

    const { isLoading, startLoading, stopLoading } = useLoading();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const submitHandler = (e) => {
        e.preventDefault();

        if (isLoading) return;

        startLoading();

        let user = {
            email: email,
            password,
        };

        if (props?.isOnShowPage || props?.isOnGuestCheckout) {
            history = null;
        }

        if (guestInputs) user.userInputs = guestInputs;

        dispatch(
            signIn(
                user,
                history,
                props?.isOnGuestCheckout,
                props?.onGuestSignUpOrInSuccess
            )
        ).finally(() => stopLoading());
    };

    return (
        <form onSubmit={submitHandler} id='sign-in-form'>
            <input
                id='email'
                type='text'
                value={email}
                placeholder='Email'
                onChange={(e) => setEmail(e.target.value)}
            />
            <input
                id='new-password'
                type='password'
                placeholder='Password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <span
                className='fake-link'
                onClick={() => {
                    dispatch(closeModal());
                    history.push('/forgot-password');
                }}
            >
                Forgot password?
            </span>

            <Errors />
            <button
                type='submit'
                className='gray-out'
                disabled={email === '' || password === ''}
            >
                {isLoading ? 'Signing you in...' : 'Sign In'}
            </button>
            <p>
                By continuing with the sign in process, we may send you a
                one-time verification code via email to the email address number
                associated with your account.
            </p>
        </form>
    );
};

export default SignInForm;
