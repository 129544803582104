import { useState } from 'react';
import {
    handleEmailChange,
    handleNextStep,
    handlePhoneNumberChange,
} from '../../../../util/vendors/vendorSignUpHelpers';

const Step1 = ({ formData, handleChange, nextStep }) => {
    const { phoneNumber, firstName, lastName, email } = formData;
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [emailError, setEmailError] = useState('');

    return (
        <>
            <h2>New Vendor Account</h2>
            <h3>Account Admin</h3>
            <div className='step1-top-div'>
                <input
                    name='firstName'
                    value={firstName}
                    onChange={handleChange}
                    placeholder='First Name'
                    type='text'
                    required
                />
                <input
                    value={lastName}
                    onChange={handleChange}
                    name='lastName'
                    type='text'
                    placeholder='Last Name'
                    required
                />
            </div>
            <input
                value={email}
                onChange={(e) =>
                    handleEmailChange(
                        'email',
                        e.target.value,
                        handleChange,
                        setEmailError
                    )
                }
                placeholder='Login Email'
                name='email'
                type='text'
                required
            />
            <input
                value={phoneNumber}
                onChange={(e) =>
                    handlePhoneNumberChange(
                        'phoneNumber',
                        e.target.value,
                        handleChange,
                        setPhoneNumberError
                    )
                }
                type='tel'
                placeholder='Phone Number'
                pattern='[0-9]+'
                name='phoneNumber'
                required
            />
            {emailError && <p className='errors'>{emailError}</p>}
            {phoneNumberError && <p className='errors'>{phoneNumberError}</p>}
            <div className='button-div'>
                <button
                    onClick={() =>
                        handleNextStep(
                            phoneNumber,
                            setPhoneNumberError,
                            email,
                            setEmailError,
                            nextStep
                        )
                    }
                    disabled={
                        phoneNumber === '' ||
                        email === '' ||
                        firstName === '' ||
                        lastName === '' ||
                        !!phoneNumberError ||
                        !!emailError
                    }
                    className='gray-out'
                >
                    Next
                </button>
            </div>
            <p>Step 1 of 3</p>
        </>
    );
};

export default Step1;
