import './VendorOrderIndexItemDropdown.css';

const VendorOrderIndexItemDropdown = ({
    canRescheduleOrder,
    handleRescheduleClick,
    canMarkOrderAsComplete,
    handleMarkAsComplete,
    canRefundOrder,
    handleRefundClick,
    canCancelOrder,
    handleCancelClick,
    fulfillmentStatus,
}) => {

    return (
        <div
            className='vendor-order-index-item-dropdown'
            onClick={(e) => e.stopPropagation()}
        >
            <ul>
                <li
                    onClick={handleRescheduleClick}
                    className={!canRescheduleOrder() ? 'not-allowed' : ''}
                >
                    Reschedule
                </li>
                <li
                    onClick={handleRefundClick}
                    className={!canRefundOrder() ? 'not-allowed' : ''}
                >
                    Partial Refund
                </li>

                <li
                    className={!canCancelOrder() ? 'not-allowed' : ''}
                    onClick={handleCancelClick}
                >
                    {fulfillmentStatus === 'cancelled'
                        ? 'Cancelled'
                        : 'Cancel Order'}
                </li>
                <hr />
                <li
                    onClick={handleMarkAsComplete}
                    className={`${!canMarkOrderAsComplete() ? 'not-allowed' : ''} ${fulfillmentStatus === 'fulfilled' ? '' : 'complete'}`}
                >
                    {fulfillmentStatus !== 'fulfilled'
                        ? 'Complete'
                        : 'Completed'}
                </li>
            </ul>
        </div>
    );
};

export default VendorOrderIndexItemDropdown;
