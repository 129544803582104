import { useEffect, useRef, useState } from 'react';
import ToolTip from '../../common/ToolTip';
import './VendorFormComponents.css'
import '../../forms/formComponents/RangeSlider/RangeSlider.css'

const VendorRangeSlider = ({ name, stagedOptions, handleInputChange, toolTip }) => {
  const optionsArray = Object.values(stagedOptions);
  const max = optionsArray.find((item) => item.optionType === 'max')?.value;
  const min = optionsArray.find((item) => item.optionType === 'min')?.value;
  const step = optionsArray.find((item) => item.optionType === 'step')?.value;
  const defaultValue = optionsArray.find(
    (item) => item.optionType === 'default'
  )?.value;
  const [sliderValue, setSliderValue] = useState(defaultValue);
  const outputEl = useRef(null);
  const sliderEl = useRef(null);

  useEffect(() => {
    if (defaultValue) {
      handleInputChange({ value: defaultValue });
    }
    let percent = ((defaultValue - min) / (max - min)) * 100;
    outputEl.current.style.left = `calc((${percent}% - 30px) + 30px)`;
  }, [defaultValue]);

  const onSliderChange = (val) => {
    setSliderValue(val.value);
    handleInputChange({ value: Number(val.value) });
  };

  return (
    <div className='range-slider-container vendor-form-component'>
      <label className='name-label'>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {name}
          <ToolTip message={toolTip} />
        </div>
        <output
          ref={outputEl}
          className='slider-value'
          style={{ fontSize: '16px' }}
        >
          {sliderValue}
        </output>
      </label>
      <input
        className='range-slider'
        type='range'
        max={max}
        min={min}
        step={step}
        value={sliderValue}
        ref={sliderEl}
        onChange={(e) => {
          onSliderChange(e.target);
        }}
      ></input>
    </div>
  );
};

export default VendorRangeSlider;