import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './OrderIndexItem.css';
import { ReactComponent as ThreeDotsSvg } from '../../../../assets/svg/three-dots.svg';
import {
    capitalizeFirstLetter,
    parsedCategory,
} from '../../../../util/formatting';
import { format, parseISO, differenceInHours, isBefore } from 'date-fns';
import { openModal } from '../../../../store/ui';
import ExpandedOrderIndexItem from './ExpandedOrderIndexItem';
import { getStatusColor } from '../../../../util/orderHelpers';
import useDropdown from '../../../../hooks/useDropdown';
import OrderIndexItemDropdown from './OrderIndexItemDropdown';

const OrderIndexItem = ({ order, expanded, onToggle }) => {
    const dispatch = useDispatch();

    const currentUser = useSelector((state) => state.session.user);

    const vendor = order.vendor;
    const formattedPrice = order?.totalCollected;
    const vendorCategory = parsedCategory(vendor?.category);
    const [fulfillmentStatus, setFulfillmentStatus] = useState(
        order?.fulfillmentStatus
    );
    // const options = order?.optionsSnapshot ? JSON.parse(order.optionsSnapshot) : {}
    const options = order?.optionsSnapshot ? order.optionsSnapshot : {};
    const currentTime = new Date();
    const appointmentTime = new Date(order.appointmentAt);
    const { isDropdownOpen, toggleDropdown, dropdownRef } = useDropdown();

    useEffect(() => {
        setFulfillmentStatus(order?.fulfillmentStatus);
    }, [order]);

    const cancellationDeadline = new Date(
        appointmentTime.getTime() -
            vendor?.cancelationPolicyHours * 60 * 60 * 1000
    );
    const canCancelOrder =
        currentTime <= cancellationDeadline &&
        (order.fulfillmentStatus === 'unfulfilled' ||
            order.fulfillmentStatus === 'rescheduled');

    const handleCancelClick = () => {
        if (!canCancelOrder) return;
        dispatch(
            openModal('customer-order-cancellation-confirmation', {
                order,
                vendor,
                currentUser,
            })
        );
    };

    const isRescheduleDisabled = () => {
        if (order?.fulfillmentStatus === 'cancelled') {
            return true;
        } else if (order?.appointmentAt && vendor?.cancelationPolicyHours) {
            const appointmentDate = parseISO(order.appointmentAt);
            const now = new Date();
            const hoursDifference = differenceInHours(appointmentDate, now);
            return (
                hoursDifference < vendor.cancelationPolicyHours ||
                isBefore(appointmentDate, now)
            );
        }
        return false;
    };

    const handleRescheduleClick = () => {
        if (isRescheduleDisabled()) return;
        dispatch(openModal('reschedule-appointment', { order }));
    };

    let formattedDate = () => {
        const appointmentAtWithoutTimezone = order?.appointmentAt?.replace(
            /([-+]\d{2}:\d{2})/,
            ''
        );
        if (order?.appointmentAt) {
            return format(appointmentAtWithoutTimezone, 'MMM do, h:mmaaa');
        }
    };

    return (
        <div
            className={`order-index-item-container ${expanded ? 'active' : ''}`}
        >
            <div className='order-index-item' onClick={onToggle}>
                <div className='logo-div'>
                    <img
                        src={`${vendor?.logoImageUrl}`}
                        alt='Vendor Logo'
                    ></img>
                </div>
                <div className='category-and-name'>
                    <h3 style={{ marginTop: '0' }}>{vendorCategory}</h3>
                    <h2 style={{ fontSize: '18px', marginTop: '5px' }}>
                        {vendor?.name}
                    </h2>
                </div>
                <div className='order-index-item-appointment'>
                    <h3 style={{ marginTop: '0' }}>Service Date</h3>
                    <p>{formattedDate()}</p>
                </div>
                <div className='order-index-item-fulfillment'>
                    <h3 style={{ marginTop: '0' }}>Fulfillment Status</h3>
                    <p style={{ color: getStatusColor(fulfillmentStatus) }}>
                        {capitalizeFirstLetter(order.fulfillmentStatus)}
                    </p>
                </div>
                <div className='order-index-item-price'>
                    <p>${formattedPrice}</p>
                </div>
                <div
                    className='three-dots-container'
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown();
                    }}
                    ref={dropdownRef}
                >
                    <ThreeDotsSvg />
                    {isDropdownOpen && (
                        <OrderIndexItemDropdown
                            isDropdownOpen={isDropdownOpen}
                            handleRescheduleClick={handleRescheduleClick}
                            isRescheduleDisabled={isRescheduleDisabled}
                            handleCancelClick={handleCancelClick}
                            canCancelOrder={canCancelOrder}
                            fulfillmentStatus={fulfillmentStatus}
                        />
                    )}
                </div>
            </div>
            {expanded && (
                <ExpandedOrderIndexItem
                    order={order}
                    vendor={vendor}
                    options={options}
                    formattedDate={formattedDate}
                    formattedPrice={formattedPrice}
                />
            )}
        </div>
    );
};

export default OrderIndexItem;
