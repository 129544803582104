import csrfFetch from '../../../../../store/csrf';
import VendorAuthorization from './googleCalendar/VendorAuthorization';
import JobberAuthorization from './jobberCalendar/JobberAuthorization';
import { setVendorCalendar } from '../../../../../store/session';
import { ReactComponent as Close } from '../../../../../assets/svg/close.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../../../../../util/urlQueryParams';
import { useEffect } from 'react';
import { openModal } from '../../../../../store/ui';

const CalendarIntegration = ({ vendor }) => {
    const calendar = useSelector((state) => state.session.vendor.calendar);
    const dispatch = useDispatch();
    const history = useHistory();
    const queryParams = useQueryParams();
    const code = queryParams.code;

    useEffect(() => {
        if (code) {
            if (!calendar.apiIntegrated || calendar.integrationType !== 'jobber') {
                jobberCallback(code);
            } else {
                history.replace('/dashboard/scheduling');
            }
        }
    }, [code]);

    const jobberCallback = async (code) => {
        try {
            const response = await csrfFetch('/api/auth/jobber/callback', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({
                    code: code,
                }),
            });

            if (response.ok) {
                let data = await response.json();
                dispatch(setVendorCalendar(data.calendar));
                history.replace('/dashboard/scheduling');
            }
        } catch (error) {
            console.error(
                'Error sending authorization code to backend:',
                error
            );
        }
    };

    return (
        <div style={{ display: 'flex', gap: '30px' }}>
            {calendar.apiIntegrated ? (
                <div className='calendar-button-container'>
                    <div className='status-circle'></div>
                    <div className='connect-google-calendar--disconnect'>
                        Connected{' '}
                    </div>
                    <Close
                        className='calendar-disconnect-button'
                        onClick={() =>
                            dispatch(
                                openModal('disconnect-calendar', { vendor })
                            )
                        }
                        style={{ transform: 'scale(0.75)' }}
                    />
                </div>
            ) : (
                <>
                    <div>
                        <h4 style={{ margin: '0 0 10px', textAlign: 'center' }}>
                            Google
                        </h4>
                        <VendorAuthorization vendor={vendor} />
                    </div>
                    <p style={{ marginTop: '20px' }}>or</p>
                    <div>
                        <h4 style={{ margin: '0 0 10px', textAlign: 'center' }}>
                            Jobber
                        </h4>
                        <JobberAuthorization />
                    </div>
                </>
            )}
        </div>
    );
};

export default CalendarIntegration;
