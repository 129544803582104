import { useEffect, useRef, useState } from 'react'
import './RangeSlider.css'
import { ReactComponent as Tooltip } from '../../../../assets/svg/ToolTip.svg';

const RangeSlider = ({name, options, onChange, prefilledUserInput}) => {
    const optionsArray = Object.values(options)
    const max = optionsArray.find(item => item.optionType === "max")?.value
    const min = optionsArray.find(item => item.optionType === "min")?.value
    const step = optionsArray.find(item => item.optionType === "step")?.value
    const tooltip = optionsArray.find(item => item.optionType === "tooltip")?.name
    const defaultValue = optionsArray.find(item => item.optionType === "default")?.value
    const [sliderValue, setSliderValue] = useState(prefilledUserInput || defaultValue);
    const outputEl = useRef(null);
    const sliderEl = useRef(null);

    useEffect(()=>{
        if(sliderValue){
            onChange({label: name, value: sliderValue, name: sliderValue})
        }
        let percent = (sliderValue - min) / (max - min) * 100
        outputEl.current.style.left = `calc((${percent}% - 30px) + 30px)`
    },[sliderValue])

    const onSliderChange = (val) => {
        setSliderValue(val.value)
        onChange({label: name, value: val.value, name: val.value})
        // let percent = (val - min) / (max - min)
        // let currentSliderWidth = sliderEl.current.offsetWidth
        // let currentValueWidth = outputEl.current.offsetWidth
        // let adjustedLeft = percent * (currentSliderWidth - currentValueWidth)
        // let newLeft = adjustedLeft + (currentValueWidth / 2)
        // outputEl.current.style.left = newLeft + 'px'
    }

    const toolTipDiv = <div className="tooltip"><Tooltip />
                            <span className="tooltiptext">{tooltip}</span>
                        </div>

    return (
        <div className="range-slider-container">
            <label>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {name}{tooltip ? toolTipDiv : ''}
                </div>
                <output ref={outputEl} className="slider-value" style={{fontSize:"16px"}}>
                    {sliderValue}
                </output>
            </label>
            <input className="range-slider" 
                    type="range" max={max} 
                    min={min} step={step} 
                    value={sliderValue} 
                    ref={sliderEl}
                    onChange={(e) => {
                        onSliderChange(e.target)
                    }}>
            </input>
        </div>
    )
}

export default RangeSlider;