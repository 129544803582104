import { useSelector } from 'react-redux';
import VendorShowContent from '../VendorShow/VendorShowContent';

const SessionVendorShow = () => {
    const vendor = useSelector((state) => state.session?.vendor);

    const calendarData = useSelector(
        (state) => state.session?.vendor?.calendar?.calendarData
    );

    const galleryImageUrls = useSelector(
        (state) => state.session?.vendor?.galleryImageUrls
    );

    return (
        <VendorShowContent
            vendor={vendor}
            calendarData={calendarData}
            galleryImageUrls={galleryImageUrls}
        />
    );
};

export default SessionVendorShow;
