import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateVendorPicture } from '../../../../../store/vendor';
import './LogoStep.css';
import { useHistory } from 'react-router-dom';
import { ReactComponent as StarSvg } from '../../../../../assets/svg/reviewStar.svg';
import '../../../VendorIndexItem/VendorIndexItem.css';
import logoPlaceholder from '../../../../../assets/images/img-placeholder-small.png'
import thumbnailPlaceholder from '../../../../../assets/images/img-placeholder-large.png'
import loadingGif from '../../../../../assets/images/loading-gif.gif'
import { useEffect } from 'react';
import ToolTip from '../../../../common/ToolTip';

const LogoStep = ({ vendor }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const vendorId = vendor?.id;
  const [logoPreview, setLogoPreview] = useState(logoPlaceholder);
  const [bannerPreview, setBannerPreview] = useState(thumbnailPlaceholder);
  const [isUploading, setIsUploading] = useState(false);
  const logoStatus = logoPreview !== logoPlaceholder && bannerPreview !== thumbnailPlaceholder;

  useEffect(() => {
    if (vendor?.logoImageUrl) {
      setLogoPreview(vendor.logoImageUrl);
    }
    if (vendor?.thumbnailImageUrl) {
      setBannerPreview(vendor.thumbnailImageUrl);
    }
  }, [vendor]);

  const handleLogoImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoPreview(reader.result);  // Set preview immediately
        setIsUploading(true);           // Start uploading indication
  
        const formData = new FormData();
        formData.append('vendor[logoImageUrl]', file);
        formData.append('id', vendorId);
  
        dispatch(updateVendorPicture(formData)).then(() => {
          setIsUploading(false);        // End uploading indication once uploaded
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBannerImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBannerPreview(reader.result);  // Set preview immediately
        setIsUploading(true);             // Start uploading indication
        const formData = new FormData();
        formData.append('vendor[thumbnailImageUrl]', file);
        formData.append('id', vendorId);
  
        dispatch(updateVendorPicture(formData)).then(() => {
          setIsUploading(false);          // End uploading indication once uploaded
        });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className='logo-step'>
      <h2>Logo & Branding</h2>
      <div className='upload-all-div'>
        <div className='upload-logo-image-div'>
          <h3>Upload Your Logo</h3>
          <div className='logo-container'>
              {vendor?.logoImageUrl ? (
                <img
                  className='provider-logo'
                  // src={vendor.logoImageUrl ? vendor.logoImageUrl : logoPreview}
                  src={logoPreview}
                  alt={`${vendor?.name} logo`}
                  onClick={() => document.getElementById('logoFileInput').click()}
                />
              ) : <img
                  className='provider-logo'
                  src={logoPlaceholder}
                  alt={`${vendor?.name} logo`}
                  onClick={() =>
                    document.getElementById('logoFileInput').click()
                  }
                />}
              <input
                type='file'
                id='logoFileInput'
                accept='image/*'
                onChange={handleLogoImage}
                style={{ display: 'none' }}
              />
          </div>
        </div>

        <div className='upload-banner-image-div'>
          <div className='header'>
            <h3>Upload a Banner Image</h3>
            <ToolTip message='Choose an image that shows your service in action'/>
          </div>
          <li className='vendor-index-item'>
            <div className='vendor-index-image-container'>
              {vendor?.thumbnailImageUrl ? (
                <img
                  src={bannerPreview}
                  alt={`${vendor?.name} banner`}
                  onClick={() => document.getElementById('thumbnailFileInput').click()}
                />
              ) : 
                <img
                    src={thumbnailPlaceholder}
                    alt={`${vendor?.name} banner`}
                    onClick={() =>
                      document.getElementById('thumbnailFileInput').click()
                    }
                  />}
              <input
                type='file'
                id='thumbnailFileInput'
                accept='image/*'
                onChange={handleBannerImage}
                style={{ display: 'none' }}
              />
            </div>
            <div className='vendor-index-item-meta-info-container'>
              {vendor?.logoImageUrl ? <img src={vendor?.logoImageUrl} alt={`${vendor?.name} logo`} /> : <img src={logoPlaceholder} alt={`${vendor?.name} logo`} />}
              <div className='vendor-index-item-meta-info'>
                <h3>{vendor?.name}</h3>
                <span className='macro-review-container'>
                  <p>0.0</p>
                  <StarSvg className='review-star-svg' />
                  <p>(0)</p>
                </span>
              </div>
            </div>
          </li>
        </div>
      </div>

      {logoStatus && !isUploading ? 
        <button
          onClick={() => history.push(`/dashboard/setup?page=images`)}
          className='continue-button'
        >
          Continue
        </button>
      : 
        (isUploading ? 
          <button className='continue-button gray-out' disabled>
            <img src={loadingGif} className="loading-spinner" alt='loading spinner'></img>
          </button> 
        :
          <button className='continue-button gray-out' disabled>
            Continue
          </button> 
        )
      }
    </div>
  );
};

export default LogoStep;
