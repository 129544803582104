import { useEffect, useState } from 'react';
import ToolTip from '../../common/ToolTip';
import './VendorFormComponents.css';
import '../../forms/formComponents/RadioButton/RadioButton.css';

const VendorRadioButton = ({
  name,
  stagedOptions,
  handleInputChange,
  handleValueChange,
  toolTip,
}) => {
  const [selectedValue, setSelectedValue] = useState(
    Object.values(stagedOptions)[0].value
  );

  const handleChange = (e) => {
    const option = Object.values(stagedOptions).find(option => option.id === Number(e.target.id));
    setSelectedValue(option.value);
    handleInputChange({ value: option.value });
  };

  useEffect(() => {
    if (stagedOptions) {
      const defaultOption =
        selectedValue === ''
          ? Object.values(stagedOptions).find(
              (option) => option.default === true
            )
          : null;
      if (defaultOption) {
        setSelectedValue(defaultOption.value);
        handleInputChange({ value: defaultOption.value });
      }
    }
  }, [stagedOptions, handleInputChange, selectedValue]);

  return (
    <div className='radio-buttons vendor-form-component'>
      <label className='name-label'>
        {name}
        <ToolTip message={toolTip} />
      </label>
      {stagedOptions &&
        Object.values(stagedOptions).map((option, index) => {
          return (
            <div key={index} className='vendor-radio-button'>
              <div className='vendor-radio-button-div'>
                <input
                  type='radio'
                  id={option.id}
                  name={name}
                  value={option.value}
                  onChange={(e) => handleChange(e)}
                  checked={selectedValue.toString() === option.value.toString()}
                />
                <span htmlFor={`radio-${index}`} className='option-name'>
                  {option.name}
                </span>
              </div>
              <div className='min-price-div value-div'>
                <span>Value:</span>
                <input
                  type='number'
                  value={option.value}
                  step='0.01'
                  onChange={(e) => {
                    handleValueChange(option.id, e);
                    setSelectedValue(e.target.value);
                  }}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default VendorRadioButton;
