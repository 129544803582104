import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import NavigationBar from './components/common/Navigation';
import SplashPage from './components/pages/SplashPage';
import Home from './components/pages/Home';
import Orders from './components/orders/Order';
// import ErrorBanner from './components/common/ErrorBanner';
import Footer from './components/common/Footer';
import UserAccount from './components/pages/UserAccount';
// import RecurringOrders from './components/pages/RecurringOrders';
import { GoogleOAuthProvider } from '@react-oauth/google';
import PrivacyPolicy from './components/static/PrivacyPolicy';
import TermsOfService from './components/static/TermsOfService';
import AboutUs from './components/static/AboutUs';
import ContactUs from './components/static/ContactUs';
import VendorOnboarding from './components/static/VendorOnboarding';
import Modal from './components/common/Modal';
import VendorView from './components/vendors/VendorView/VendorView';
import VendorShow from './components/vendors/VendorShow';
import AuthRoute, { ProtectedRoute } from './components/Routes';
import ResetPassword from './components/pages/ForgotPassword/ResetPassword';
import ForgotPassword from './components/pages/ForgotPassword';
import ResetPasswordComplete from './components/pages/ForgotPassword/ResetPasswordComplete';

function App({ store }) {
    return (
        <Provider store={store}>
            <GoogleOAuthProvider clientId='206898763167-10uqs4cqvsmf3sosakbmr9pncd2l83gt.apps.googleusercontent.com'>
                <BrowserRouter>
                    <Modal />
                    <NavigationBar />
                    {/* <ErrorBanner /> */}
                    <Switch>
                        <Route
                            exact
                            path='/vendors/:id'
                            component={VendorShow}
                        />
                        {/* <Route exact path="/checkout" component={Checkout} /> */}
                        <Route
                            exact
                            path='/privacy-policy'
                            component={PrivacyPolicy}
                        />
                        <Route
                            exact
                            path='/terms-of-service'
                            component={TermsOfService}
                        />
                        <Route exact path='/about-us' component={AboutUs} />
                        <Route exact path='/contact-us' component={ContactUs} />
                        <Route
                            exact
                            path='/vendor-onboarding'
                            component={VendorOnboarding}
                        />
                        <Route
                            exact
                            path='/forgot-password'
                            component={ForgotPassword}
                        />
                        <Route
                            exact
                            path='/password/reset'
                            component={ResetPassword}
                        />
                        <ProtectedRoute exact path='/home' component={Home} />
                        <ProtectedRoute
                            exact
                            path='/password/reset/complete'
                            component={ResetPasswordComplete}
                        />
                        <ProtectedRoute
                            exact
                            path='/orders'
                            component={Orders}
                        />
                        <ProtectedRoute
                            exact
                            path='/account'
                            component={UserAccount}
                        />
                        {/* <Route exact path='/recurring-orders' component={RecurringOrders} /> */}
                        <ProtectedRoute
                            path='/dashboard'
                            component={VendorView}
                        />
                        <AuthRoute path='/' component={SplashPage} />
                    </Switch>
                    <Footer />
                </BrowserRouter>
            </GoogleOAuthProvider>
        </Provider>
    );
}

export default App;
