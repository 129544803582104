import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './Menu.css'
import { logout } from '../../../../store/session';
import { ReactComponent as CloseIcon } from '../../../../assets/svg/close.svg'
import SwitchViewToggle from '../../SwitchViewToggle';

const Menu = ({isMenuOpen, closeMenu}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const sessionUser = useSelector((state) => state.session?.user);
    const homeView = useSelector((state) => state.ui?.homeView);
    const vendorId = useSelector((state) => state.session.user?.vendorId);

    if(!isMenuOpen) return null;
    
    const handleSignOut = () => {
        closeMenu();
        dispatch(logout());
    }

    const handleMenuClick = (e) => {
        e.stopPropagation();
    }

    const handleHomeClick = () => {
        closeMenu();
        history.push('/home');
    }

    const handleOrdersClick = () => {
        closeMenu();
        history.push('/orders')
    }

    const handleAccountClick = () => {
        closeMenu();
        history.push('/account')
    }

    const handleRecurringClick = () => {
        closeMenu();
        history.push('/recurring-orders')
    }

    const handleHelpClick = () => {
        closeMenu();
        history.push('/contact-us')
    }

    return(
        <div className="menu-backdrop" onClick={closeMenu}>
            <div className="menu-main" onClick={handleMenuClick}>
                <div className='close-and-toggle-div'>
                    <div className="menu-close">
                        <CloseIcon onClick={closeMenu} />
                    </div>
                    {vendorId && <SwitchViewToggle homeView={homeView} side={"user"} vendorId={vendorId} closeMenu={closeMenu}/>}
                </div>
                <ul>
                    <li><h2 style={{fontSize: '14px'}}>Hello, <p style={{fontSize: '18px'}}>{sessionUser.firstName}!</p></h2></li>
                    <li onClick={handleHomeClick}>Home</li>
                    {/* <li onClick={handleRecurringClick}>Recurring</li> */}
                    <li onClick={handleOrdersClick}>Orders</li>
                    <li onClick={handleAccountClick}>Account</li>
                    <li onClick={handleHelpClick}>Help</li>
                    <li onClick={handleSignOut}>Logout</li>
                </ul>
            </div>
        </div>
    )
}

export default Menu;