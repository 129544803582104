import useDropdown from '../../../../../hooks/useDropdown';
import { ReactComponent as FilterSvg } from '../../../../../assets/svg/filter.svg';
import '../../../VendorDashboard/DashboardOrders/VendorOrderIndexItem/VendorOrderIndexItemDropdown/VendorOrderIndexItemDropdown.css';
import '../../../VendorDashboard/DashboardOrders/OrdersFilter/OrdersFilter.css';
import './ReviewsFilter.css';

export default function ReviewsFilter({ filter, setFilter }) {
    const { isDropdownOpen, toggleDropdown, dropdownRef, closeDropdown } =
        useDropdown();

    const handleItemClick = (filter) => {
        setFilter(filter);
        closeDropdown();
    };

    return (
        <div
            className='orders-filter reviews-filter'
            ref={dropdownRef}
            onClick={() => toggleDropdown()}
        >
            <FilterSvg id={filter !== 'all' ? 'active' : ''} />

            {isDropdownOpen && (
                <ul
                    id='orders-filter-dropdown'
                    className='vendor-order-index-item-dropdown'
                    onClick={(e) => e.stopPropagation()}
                >
                    <li
                        onClick={() => handleItemClick('servo')}
                        className={filter === 'servo' ? 'active-filter' : ''}
                    >
                        Servo Reviews
                    </li>
                    <li
                        onClick={() => handleItemClick('google')}
                        className={filter === 'google' ? 'active-filter' : ''}
                    >
                        Google Reviews
                    </li>
                    <hr />
                    <li onClick={() => handleItemClick('all')}>
                        Remove Filter
                    </li>
                </ul>
            )}
        </div>
    );
}
