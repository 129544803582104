import { useSelector } from 'react-redux';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { isLoggedIn } from '../../store/session';
import { useEffect } from 'react';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const userLoggedIn = useSelector(isLoggedIn);

  return (
    <Route
      {...rest} // Spread any other props like path and exact
      render={(props) =>
        // Render the specified Component if loggedIn is true, otherwise redirect to "/"
        userLoggedIn ? <Component {...props} /> : <Redirect to='/' />
      }
    />
  );
};

const AuthRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const user = useSelector((state) => state.session?.user);
  const userLoggedIn = useSelector(isLoggedIn);

  useEffect(() => {
    // When user's logged in and tries to go on this component redirect them to the appropriate page based on user_type.
    const destination = user?.userType === 'vendor' ? '/dashboard/orders' : '/home';
    if (userLoggedIn) history.push(destination);
  }, [user, userLoggedIn, history]);

  return (
    <Route
      {...rest}
      render={(props) => (!userLoggedIn ? <Component {...props} /> : null)}
    />
  );
};

export default AuthRoute;
