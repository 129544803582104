import Errors from '../../../common/Errors/index.jsx';

const Step3 = ({ formData, handleChange, handleSubmit, isLoading }) => {
    const { password, confirmPassword } = formData;

    return (
        <>
            <h2>Create a Password</h2>
            <input
                name='password'
                value={password}
                onChange={handleChange}
                placeholder='Password'
                type='password'
                required
            />
            <input
                name='confirmPassword'
                value={confirmPassword}
                onChange={handleChange}
                placeholder='Confirm Password'
                type='password'
                required
            />
            {password !== confirmPassword && (
                <p className='errors'>
                    Confirm Password field must be the same as the Password
                    field
                </p>
            )}
            <Errors />
            <div className='button-div'>
                <button
                    type='submit'
                    onClick={handleSubmit}
                    disabled={
                        password === '' ||
                        confirmPassword === '' ||
                        password !== confirmPassword
                    }
                    className='gray-out'
                >
                    {isLoading ? 'Submitting...' : 'Submit'}
                </button>
            </div>
            <p>Step 3 of 3</p>
        </>
    );
};

export default Step3;
