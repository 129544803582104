import { useDispatch, useSelector } from 'react-redux';
import './ReviewShow.css';
import { ReactComponent as GrayReviewStar } from '../../../../assets/svg/reviewStar.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/svg/close.svg';
import { closeModal } from '../../../../store/ui';
import { formatDate } from '../../../../util/formatting';
import GoogleIcon from '../../../../assets/images/google-icon.webp';
import { getServoNameColor } from '../../../../util/reviewsHelper';
import ConfirmModal from '../../../common/Modal/ConfirmModal';
import { deleteReview } from '../../../../store/reviews';

const ReviewShow = ({
    review,
    showConfirmDeleteReview,
    setShowConfirmDeleteReview,
    stopPropagation,
}) => {
    const dispatch = useDispatch();

    const sessionUserId = useSelector((state) => state.session.user)?.id;

    const { firstName, lastName } = review.author;
    const formattedAuthor = lastName ? `${firstName} ${lastName}` : firstName;
    const isUserReviewed = review.userId === sessionUserId;

    const handleDeleteReview = () => dispatch(deleteReview(review.id));

    const googleReviewImg = (
        <>
            <img
                src={review.authorImage}
                alt={review.firstName}
                className='google-author-img'
            />
            <img className='icon' src={GoogleIcon} alt='Google Logo' />
        </>
    );

    const servoReviewImg = (
        <>
            <div
                className='servo-name-circle'
                style={{
                    backgroundColor: getServoNameColor(review, formattedAuthor),
                }}
            >
                {formattedAuthor && formattedAuthor[0]}
            </div>
            <div className='icon' id='servo-icon-background'>
                <img
                    className='servo-icon'
                    src='https://spencerheywood.com/images/servo/icons/icons-07.png'
                    alt='Servo Logo'
                />
            </div>
        </>
    );

    const reviewHeader = (
        <div className='review-header'>
            <div className='review-header-left'>
                <div className='author-image-div'>
                    {review.reviewType === 'google'
                        ? googleReviewImg
                        : servoReviewImg}
                </div>

                {review.url ? (
                    <a
                        href={review.url}
                        target='_blank'
                        rel='noreferrer'
                        style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                        <h2 style={{ pointer: 'cursor' }}>{formattedAuthor}</h2>
                    </a>
                ) : (
                    <h2>{formattedAuthor}</h2>
                )}
            </div>

            <CloseIcon
                onClick={() => dispatch(closeModal())}
                className='close-icon'
            />
        </div>
    );

    const reviewShow = (
        <>
            {reviewHeader}

            {/* {review.reviewType === 'servo' && (
                        <div className='customer-verified-badge'>Verified Customer</div>
                    )} */}

            <div className='review-stars-container review-show-stars'>
                {Array.from({ length: review?.score }).map((_, idx) => (
                    <GrayReviewStar key={idx} />
                ))}
            </div>
            <p className='date'>{formatDate(review.createdAt)}</p>
            <p className='min-chars review-show-body'>
                {review.body ?? 'Rating only'}
            </p>

            {isUserReviewed && (
                <p className='delete-review'>
                    <span
                        onClick={() => setShowConfirmDeleteReview(true)}
                        className='fake-link'
                    >
                        Delete Your Review
                    </span>
                </p>
            )}
        </>
    );

    return (
        <div
            id='review-show'
            className='review-form'
            key={review.id}
            onClick={stopPropagation}
        >
            {!showConfirmDeleteReview ? (
                reviewShow
            ) : (
                <ConfirmModal
                    confirmationText='Are you sure you want to delete your review?'
                    headerText='Delete Review'
                    buttonText='Delete Review'
                    onButtonClick={handleDeleteReview}
                />
            )}
        </div>
    );
};

export default ReviewShow;
