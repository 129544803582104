import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createPartialRefund } from '../../../store/orders';
import './PartialRefundForm.css';
import { ModalHeader } from '../../common/Modal';
import Errors from '../../common/Errors';
import useLoading from '../../../hooks/useLoading';

const PartialRefundForm = ({ props }) => {
    const { order, customer } = props;

    const dispatch = useDispatch();

    const { isLoading, startLoading, stopLoading } = useLoading();
    const [refundAmount, setRefundAmount] = useState('');
    const [refundReason, setRefundReason] = useState('');

    const handleRefundAmountChange = (e) => {
        setRefundAmount(e.target.value);
    };

    const handleRefundReasonChange = (e) => {
        setRefundReason(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (isLoading) return;

        startLoading();

        dispatch(
            createPartialRefund(order.id, refundAmount, refundReason)
        ).finally(() => stopLoading());
    };

    return (
        <div className='partial-refund-modal' id='update-password-form'>
            <ModalHeader headerText={'Issue Partial Refund'} />
            <div className='order-info'>
                <p>
                    <strong>Order Number:</strong> {order.id}
                </p>
                <p>
                    <strong>Appointment:</strong>{' '}
                    {new Date(order.appointmentAt).toLocaleString('en-US', {
                        dateStyle: 'short',
                        timeStyle: 'short',
                    })}
                </p>
                <p>
                    <strong>Customer:</strong>{' '}
                    {`${customer?.firstName} ${customer?.lastName}`}
                </p>
                <p>
                    <strong>Total Paid:</strong> $
                    {parseFloat(order.totalCollected).toFixed(2)}
                </p>
                <p>
                    <strong>Available for Refund:</strong> $
                    {parseFloat(order.vendorPayoutAmount).toFixed(2)}
                </p>
            </div>
            <form onSubmit={handleSubmit}>
                <input
                    type='number'
                    value={refundAmount}
                    onChange={handleRefundAmountChange}
                    min='0'
                    max={order.vendorPayoutAmount}
                    step='0.01'
                    required
                    placeholder='Refund Amount'
                />
                <textarea
                    value={refundReason}
                    onChange={handleRefundReasonChange}
                    required
                    placeholder='Reason for Refund'
                />
                <Errors />
                <button
                    type='submit'
                    className='gray-out submit-button'
                    disabled={refundAmount === '' || refundReason === ''}
                >
                    {isLoading ? 'Submitting Refund...' : 'Submit Refund'}
                </button>
            </form>
        </div>
    );
};

export default PartialRefundForm;
