import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import CalendarIntegration from '../../DashboardScheduling/calendarIntegrations/CalendarIntegration';

const CalendarStep = ({ vendor }) => {
  const history = useHistory();
  let calendarSkipped = sessionStorage.getItem('calendarSkipped') === 'skipped' ? true : false
  let calendarStatus = useSelector(state => state?.session?.vendor.calendar?.apiIntegrated) || calendarSkipped

  const handleClick = () => {
    sessionStorage.setItem('calendarSkipped', 'skipped')
    history.push('/dashboard/setup?page=payouts')
  }

  return (
    <div className='next-steps'>
      <h2>Calendar Integration <span style={{fontSize:'18px'}}>(Recommended)</span></h2>
      <p>
        Connect your Calendar to automatically block out times when
        you're busy. This ensures customers can't book appointments during those
        periods. You can adjust these settings later if needed.
      </p>
      <div style={{marginTop:'30px'}}>
        <CalendarIntegration vendor={vendor} />
      </div>
      <br></br>
      <br></br>

      <div className='calendar-step-continue'>
      {calendarStatus ? 
        <button
          onClick={() => history.push('/dashboard/setup?page=payouts')}
          className='continue-button'
        >
        Continue
        </button> 
      :
        <a onClick={handleClick}>Skip</a>
      }
      </div>
    </div>
  );
};

export default CalendarStep;
