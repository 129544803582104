import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { cancelOrder } from '../../../store/orders';
import './ConfirmCancelOrderModal.css';
import { ModalHeader } from '../Modal';
import Errors from '../Errors';
import useLoading from '../../../hooks/useLoading';

const ConfirmCancelOrderModal = ({ props }) => {
    const { order, currentVendor, customerName } = props;

    const dispatch = useDispatch();

    const { isLoading, startLoading, stopLoading } = useLoading();

    const [cancelReason, setCancelReason] = useState('');
    const [isConfirmedWithCustomer, setIsConfirmedWithCustomer] =
        useState(false);

    const handleCancelReasonChange = (e) => setCancelReason(e.target.value);

    const handleCancelOrder = (e) => {
        e.stopPropagation();

        if (isLoading) return;

        startLoading();

        if (currentVendor.id === order.vendorId) {
            dispatch(cancelOrder(order, cancelReason)).finally(() =>
                stopLoading()
            );
        }
    };

    return (
        <div
            className='partial-refund-modal confirm-cancel-order-modal'
            id='update-password-form'
        >
            <ModalHeader headerText={'Confirm Order Cancellation'} />
            <p>
                Are you sure you want to cancel this order and issue a refund to
                the customer?
            </p>
            <div className='order-info'>
                <p>
                    <strong>Order Number:</strong> {order.id}
                </p>
                <p>
                    <strong>Customer:</strong> {customerName}
                </p>
                <p>
                    <strong>Appointment:</strong>{' '}
                    {new Date(order.appointmentAt).toLocaleString('en-US', {
                        dateStyle: 'short',
                        timeStyle: 'short',
                    })}
                </p>
            </div>
            <textarea
                value={cancelReason}
                onChange={handleCancelReasonChange}
                required
                placeholder='Reason for Cancellation'
            />
            <div id='is-confirmed-with-customer-div'>
                <input
                    type='checkbox'
                    id='checkbox'
                    checked={isConfirmedWithCustomer}
                    onChange={(e) =>
                        setIsConfirmedWithCustomer(e.target.checked)
                    }
                />
                <label>
                    I have discussed canceling this order with the customer
                </label>
            </div>
            <Errors />
            <button
                onClick={handleCancelOrder}
                className='gray-out submit-button'
                id='cancel-order-submit-button'
                disabled={!isConfirmedWithCustomer}
            >
                {isLoading ? 'Canceling Order...' : 'Cancel Order'}
            </button>
            {/* <p
                style={{
                    margin: '20px auto 0 auto',
                    fontSize: '13px',
                }}
            >
                The customer will be notified of this change
            </p> */}
        </div>
    );
};

export default ConfirmCancelOrderModal;
