import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import './ProviderScheduling.css';
import DatePicker from 'react-datepicker';
import { format, isSameDay, parseISO } from 'date-fns';
import { updateBooking } from '../../../../store/bookings';
import 'react-datepicker/dist/react-datepicker.css';
import loadingGif from '../../../../assets/images/loading-gif.gif';

const ProviderScheduling = ({
    schedulingOpen,
    calendarData,
    booking,
    onContinue,
    isCalendarLoading,
}) => {
    const dispatch = useDispatch();

    const [startDate, setStartDate] = useState(new Date());
    const [availableTimes, setAvailableTimes] = useState([]);
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    const availableDates = calendarData?.map((entry) =>
        parseISO(entry.start_time)
    );
    let isMobile =
        window.innerWidth < 1325 &&
        !(window.innerWidth <= 1000 && window.innerWidth >= 750);
    let startTimesList = calendarData?.map((entry) =>
        parseISO(entry.start_time)
    );
    let formattedDate = format(startDate, 'M/d/yy');
    let formattedHeader = isMobile
        ? format(startDate, 'EEE, MMM do @ h:mmaaa')
        : format(startDate, 'EEEE, MMMM do @ h:mmaaa');

    const handleDateChange = (date) => {
        if (calendarData?.length > 0) {
            if (isSameDay(startDate, date)) {
                setStartDate(date);
            } else {
                setStartDate(updateAvailableTimes(date)[0]);
            }
        } else {
            setStartDate(date);
        }
    };

    useEffect(() => {
        if (calendarData?.length > 0) {
            setStartDate(parseISO(calendarData[0].start_time));
            updateAvailableTimes(parseISO(calendarData[0].start_time));
        }
    }, [calendarData]);

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        };
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const updateAvailableTimes = (dateTime) => {
        const filteredData = startTimesList.filter((time) =>
            isSameDay(time, dateTime)
        );
        setAvailableTimes(filteredData);
        return filteredData;
    };

    const handleContinueClick = () => {
        const formattedMdtDate = format(startDate, 'yyyy-MM-dd HH:mm:ssXXX', {
            timeZone: 'America/Denver',
        }).replace(/([-+]\d{2}:\d{2})/, '-06:00');
        let bookingData = {
            ...booking,
            appointmentAt: formattedMdtDate,
            status: 'scheduled',
        };
        let bookingObject = {
            booking: bookingData,
        };

        bookingObject.booking.id = booking.id;

        dispatch(updateBooking(bookingObject));
        onContinue({ bypass: true });
    };

    return (
        <div
            className={`appointment-scheduling ${schedulingOpen ? '' : 'minimize'}`}
        >
            <div className='scheduling-container'>
                <div className='scheduling-output'>
                    <h3>Service Scheduling</h3>
                    {formattedHeader}
                </div>
                {isCalendarLoading ? (
                    <div className='loading-orders-gif-div loading-times'>
                        <img
                            src={loadingGif}
                            className='loading-spinner-orders'
                            alt='loading spinner'
                        ></img>
                    </div>
                ) : (
                    <DatePicker
                        inline
                        selected={startDate}
                        onChange={handleDateChange}
                        showTimeSelect
                        dateFormat='Pp'
                        placeholderText='Select a Date'
                        monthsShown={isMobile ? 1 : 2}
                        disabledKeyboardNavigation
                        timeIntervals={1}
                        includeDates={availableDates}
                        includeTimes={availableTimes}
                    />
                )}
                <button
                    className='scheduling-continue-button'
                    onClick={handleContinueClick}
                >
                    Continue - {formattedDate}
                </button>
            </div>
            <p
                style={{
                    textAlign: 'center',
                    color: 'var(--primary-gray)',
                    fontSize: '13px',
                    margin: '20px auto 10px auto',
                    maxWidth: '475px',
                }}
            >
                Note: As a new platform, scheduling systems are still being
                fine-tuned. If any changes are needed to your appointment, the
                vendor will contact you to arrange a new time. If a suitable
                time cannot be found, your booking will be refunded.
            </p>
        </div>
    );
};

export default ProviderScheduling;
