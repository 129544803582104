import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as CloseIcon } from '../../../assets/svg/close.svg';
import { ReactComponent as PrevIcon } from '../../../assets/svg/leftArrow.svg';
import { closeModal } from '../../../store/ui';
import { vendorSignUp } from '../../../store/session';
import '../UserSessionForm/SignUpForm/SignUpForm.css';
import './VendorSignUpForm.css';
import Step1 from './FormSteps/Step1';
import Step2 from './FormSteps/Step2';
import Step3 from './FormSteps/Step3';
import { toSnakeCase } from '../../../util/formatting';
import useLoading from '../../../hooks/useLoading';

const VendorSignUpForm = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { isLoading, startLoading, stopLoading } = useLoading();
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({
        name: '',
        businessEmail: '',
        businessPhoneNumber: '',
        category: '',
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        streetAddress: '',
        streetAddress2: '',
        city: '',
        state: 'UT',
        zipCode: '',
        password: '',
        confirmPassword: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const reformatFormData = (formData) => {
        return {
            email: formData.businessEmail,
            phone_number: formData.businessPhoneNumber,
            name: formData.name,
            category: toSnakeCase(formData.category),
            users_attributes: [
                {
                    email: formData.email,
                    first_name: formData.firstName,
                    last_name: formData.lastName,
                    phone_number: formData.phoneNumber,
                    password: formData.password,
                    user_type: 'vendor',
                    addresses_attributes: [
                        {
                            street_address: formData.streetAddress,
                            street_address_2: formData.streetAddress2,
                            city: formData.city,
                            state: formData.state,
                            zip_code: formData.zipCode,
                        },
                    ],
                },
            ],
            address_attributes: {
                street_address: formData.streetAddress,
                street_address_2: formData.streetAddress2,
                city: formData.city,
                state: formData.state,
                zip_code: formData.zipCode,
            },
        };
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (isLoading) return;

        startLoading();

        const formattedData = reformatFormData(formData);
        dispatch(vendorSignUp(formattedData, history)).finally(() =>
            stopLoading()
        );
    };

    const prevStep = () => setCurrentStep(currentStep - 1);
    const nextStep = () => setCurrentStep(currentStep + 1);

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return (
                    <Step1
                        formData={formData}
                        handleChange={handleChange}
                        nextStep={nextStep}
                    />
                );
            case 2:
                return (
                    <Step2
                        formData={formData}
                        handleChange={handleChange}
                        nextStep={nextStep}
                    />
                );
            case 3:
                return (
                    <Step3
                        formData={formData}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        isLoading={isLoading}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className='user-session-form' id='vendor-sign-up-form'>
            {currentStep === 1 ? (
                <CloseIcon
                    onClick={() => dispatch(closeModal())}
                    className='close-icon'
                />
            ) : (
                <PrevIcon onClick={prevStep} className='close-icon' />
            )}
            <form onSubmit={handleSubmit}>{renderStep()}</form>
        </div>
    );
};

export default VendorSignUpForm;
