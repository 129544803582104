import './Carousel.css';
import { ReactComponent as PrevIcon } from '../../../assets/svg/leftArrow.svg';
import { ReactComponent as NextIcon } from '../../../assets/svg/rightArrow.svg';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../../store/ui';

const Carousel = ({ props }) => {
    const dispatch = useDispatch();

    const { galleryImageUrls, idx } = props;
    const [selectedImageIdx, setSelectedImageIdx] = useState(idx);

    const handlePrev = () => {
        setSelectedImageIdx((prevIdx) =>
            prevIdx === 0 ? galleryImageUrls?.length - 1 : prevIdx - 1
        );
    };

    const handleNext = () => {
        setSelectedImageIdx((prevIdx) =>
            prevIdx === galleryImageUrls?.length - 1 ? 0 : prevIdx + 1
        );
    };

    return (
        <div
            className='carousel'
            key={selectedImageIdx}
            onClick={() => dispatch(closeModal())}
        >
            <button
                onClick={(e) => {
                    e.stopPropagation();
                    handlePrev();
                }}
                className='carousel-button'
            >
                <PrevIcon className='carousel-arrow' />
            </button>
            <div className='carousel-image'>
                <img
                    src={galleryImageUrls?.[selectedImageIdx]?.url}
                    alt=''
                    onClick={(e) => e.stopPropagation()}
                />
            </div>
            <button
                onClick={(e) => {
                    e.stopPropagation();
                    handleNext();
                }}
                className='carousel-button'
            >
                <NextIcon className='carousel-arrow' />
            </button>
        </div>
    );
};

export default Carousel;
