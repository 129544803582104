import './ReviewsCarousel.css';
import '../../../common/Carousel/Carousel.css';
import { ReactComponent as PrevIcon } from '../../../../assets/svg/leftArrow.svg';
import { ReactComponent as NextIcon } from '../../../../assets/svg/rightArrow.svg';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import ReviewShow from '../ReviewShow';
import { closeModal } from '../../../../store/ui';

const ReviewsCarousel = ({ props }) => {
    const { review, reviews } = props;

    const dispatch = useDispatch();

    const formattedReviews = Object.values(reviews).sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
    const reviewIndex = formattedReviews.findIndex((r) => r.id === review.id);

    const [reviewIdx, setReviewIdx] = useState(reviewIndex);
    const [showConfirmDeleteReview, setShowConfirmDeleteReview] =
        useState(false);

    const handlePrev = () => {
        setReviewIdx((prevIdx) =>
            prevIdx === 0 ? formattedReviews.length - 1 : prevIdx - 1
        );
    };

    const handleNext = () => {
        setReviewIdx((prevIdx) =>
            prevIdx === formattedReviews.length - 1 ? 0 : prevIdx + 1
        );
    };

    return (
        <div
            id='reviews-carousel'
            className={`carousel ${showConfirmDeleteReview ? 'remove-carousel-arrows' : ''}`}
            onClick={() => dispatch(closeModal())}
        >
            {formattedReviews.length > 1 && (
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        handlePrev();
                    }}
                    className='carousel-button'
                >
                    <PrevIcon className='carousel-arrow' />
                </button>
            )}

            <div className='carousel-image'>
                <ReviewShow
                    review={formattedReviews[reviewIdx]}
                    showConfirmDeleteReview={showConfirmDeleteReview}
                    setShowConfirmDeleteReview={setShowConfirmDeleteReview}
                    stopPropagation={(e) => {
                        e.stopPropagation();
                    }}
                />
            </div>

            {formattedReviews.length > 1 && (
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        handleNext();
                    }}
                    className='carousel-button'
                >
                    <NextIcon className='carousel-arrow' />
                </button>
            )}
        </div>
    );
};

export default ReviewsCarousel;
