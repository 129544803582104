import { useState } from 'react';
import { ModalHeader } from '../../../../../../common/Modal';
import { useDispatch } from 'react-redux';
import { markAsComplete } from '../../../../../../../store/orders';
import useLoading from '../../../../../../../hooks/useLoading';

export default function OrderCompletionModal({ props }) {
    const { order, currentVendor, customerName } = props;

    const dispatch = useDispatch();

    const { isLoading, startLoading, stopLoading } = useLoading();
    const [optionalNote, setOptionalNote] = useState('');
    const [isJobCompleted, setIsJobCompleted] = useState(false);

    const handleMarkOrderAsComplete = (e) => {
        e.stopPropagation();

        if (isLoading) return;

        startLoading();

        if (currentVendor.id === order.vendorId) {
            order.optional_note = optionalNote;

            try {
                dispatch(markAsComplete(order, currentVendor.id)).finally(() =>
                    stopLoading()
                );
            } catch (error) {
                console.error('Failed to update order: ', error);
            }
        }
    };

    return (
        <div
            className='partial-refund-modal confirm-cancel-order-modal'
            id='update-password-form'
        >
            <ModalHeader headerText={'Mark Order As Complete'} />
            <div className='order-info'>
                <p>
                    <strong>Order Number:</strong> {order.id}
                </p>
                <p>
                    <strong>Customer:</strong> {customerName}
                </p>
                <p>
                    <strong>Appointment:</strong>{' '}
                    {new Date(order.appointmentAt).toLocaleString('en-US', {
                        dateStyle: 'short',
                        timeStyle: 'short',
                    })}
                </p>
            </div>
            <label
                style={{ fontWeight: 'bold', margin: '15px 0 0 0' }}
                htmlFor='optional-note'
            >
                Notes (optional)
            </label>
            <textarea
                value={optionalNote}
                onChange={(e) => setOptionalNote(e.target.value)}
                id='optional-note'
                placeholder='Add a note to share with the customer'
            />
            <div
                id='is-confirmed-with-customer-div'
                style={{ marginTop: '15px' }}
            >
                <input
                    type='checkbox'
                    id='checkbox'
                    checked={isJobCompleted}
                    onChange={(e) => setIsJobCompleted(e.target.checked)}
                />
                <label>
                    I confirm work order #{order.id} has been successfully
                    completed
                </label>
            </div>
            <button
                onClick={handleMarkOrderAsComplete}
                className='gray-out submit-button'
                id='cancel-order-submit-button'
                disabled={!isJobCompleted}
            >
                {isLoading ? 'Completing Order...' : 'Complete Order'}
            </button>
        </div>
    );    
}
