import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createReview } from '../../../../store/reviews';
import { ModalHeader } from '../../../common/Modal';
import useLoading from '../../../../hooks/useLoading';
import './ReviewForm.css';
import { ReactComponent as GrayStarSvg } from '../../../../assets/svg/grayReviewStar.svg';
import { ReactComponent as YellowStarSvg } from '../../../../assets/svg/reviewStar.svg';

const ReviewForm = ({ props }) => {
    const { vendorId } = props;

    const dispatch = useDispatch();

    const vendorName = useSelector((state) => state.vendors[vendorId]?.name);
    const user = useSelector((state) => state.session?.user);

    const { isLoading, startLoading, stopLoading } = useLoading();
    const [reviewBody, setReviewBody] = useState('');
    const [score, setScore] = useState(undefined);
    const [error, setError] = useState(false);

    const handleInputChange = (e) => setReviewBody(e.target.value);

    const handleScoreChange = (rating) => setScore(rating);

    const validateReview = (reviewBody, score) => {
        if (reviewBody.length >= 10 && score !== undefined) {
            return null; // No errors
        } else if (reviewBody.length < 10 && score !== undefined) {
            return 'Please provide a review with at least 10 characters.';
        } else if (score === undefined && reviewBody.length >= 10) {
            return 'Please provide a score.';
        } else {
            return 'Please provide a score and a review with at least 10 characters.';
        }
    };

    const submitReviewForm = (e) => {
        e.preventDefault();

        if (isLoading) return;

        startLoading();

        let review = {
            review: {
                userId: user.id,
                body: reviewBody,
                score: score + 1, // Add one to account for index starting at 0
            },
        };

        const error = validateReview(reviewBody, score);
        if (error) {
            setError(error);
            stopLoading();
        } else {
            dispatch(createReview(vendorId, review)).finally(() =>
                stopLoading()
            );
        }
    };

    return (
        <div className='review-form'>
            <ModalHeader headerText={'Share Your Experience'} />
            <h3>{vendorName}</h3>
            <form>
                <div className='review-form-header'>
                    <p className='form-user'>
                        {user?.firstName} {user?.lastName[0]?.toUpperCase()}.
                    </p>
                    <p className='public-review-tag'>Public Review</p>
                </div>
                <div className='star-rating-div'>
                    {[...Array(5)].map((_, idx) => (
                        <span key={idx} onClick={() => handleScoreChange(idx)}>
                            {idx <= score ? (
                                <YellowStarSvg className='star' />
                            ) : (
                                <GrayStarSvg className='star' />
                            )}
                        </span>
                    ))}
                </div>
                <textarea
                    className='review-body'
                    value={reviewBody}
                    onChange={handleInputChange}
                    placeholder='Your review here'
                />
            </form>
            <p className='min-chars'>Min characters: 10</p>
            {error && <p className='error'>{error}</p>}
            <div className='review-form-footer'>
                <button onClick={submitReviewForm}>
                    {isLoading ? 'Submitting...' : 'Submit'}
                </button>
            </div>
        </div>
    );
};

export default ReviewForm;
