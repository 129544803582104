import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './UserAccount.css';
import UpdateAddressForm from './UpdateAddressForm';
import UpdateGeneralForm from './UpdateGeneralForm';
import { openModal } from '../../../store/ui';

const UserAccount = () => {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.session.user);
    const addresses = useSelector((state) => state.addresses);

    const [accountFormValues, setAccountFormValues] = useState({
        ...user,
        addresses: addresses,
    });

    return (
        <div className='user-account'>
            <div className='user-account-backdrop'>
                <h1>Account Settings</h1>
                <div className='update-forms'>
                    <UpdateGeneralForm
                        accountFormValues={accountFormValues}
                        setAccountFormValues={setAccountFormValues}
                    />
                    <UpdateAddressForm
                        addresses={accountFormValues.addresses}
                        setAccountFormValues={setAccountFormValues}
                        user={user}
                    />
                </div>
                <span
                    className='fake-link'
                    onClick={() =>
                        dispatch(openModal('update-password-form', { user }))
                    }
                >
                    Update Password
                </span>
            </div>
        </div>
    );
};

export default UserAccount;
