import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import './StatusBar.css';
import csrfFetch from '../../../../../store/csrf';
import { setCurrentVendor } from '../../../../../store/session';
import { snakeToTitleCase } from '../../../../../util/formatting';
import { ReactComponent as Checkmark } from '../../../../../assets/svg/checkmark.svg';

const StatusBar = ({ vendor }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const page = location.search.split('=')[1];
    const listingStatus = vendor?.listingStatus;

    const stripeIntegrationStatus = useSelector(
        (state) => state?.session?.vendor?.stripeIntegrated
    );

    const [isConnected, setIsConnected] = useState(
        vendor?.stripeIntegrated === 'active'
    );

    useEffect(() => {
        setIsConnected(stripeIntegrationStatus === 'active');
    }, [vendor, stripeIntegrationStatus]);

    const logoStatus =
        vendor?.logoImageUrl !== null && vendor?.thumbnailImageUrl !== null;
    const imagesStatus =
        vendor?.galleryImageUrls && vendor?.galleryImageUrls.length >= 4;
    const calendarSkipped =
        sessionStorage.getItem('calendarSkipped') === 'skipped' ? true : false;
    const calendarStatus =
        useSelector(
            (state) => state.session?.vendor?.calendar?.apiIntegrated
        ) || calendarSkipped;
    const payoutStatus = isConnected;
    const allStepsCompleted =
    logoStatus && imagesStatus && calendarStatus && payoutStatus;
    console.log('StatusBar 🩷 allStepsCompleted:', allStepsCompleted);

    const handleTabClick = (name) => {
        history.push(`/dashboard/setup?page=${name.toLowerCase()}`);
    };

    const stepStatusCircle = (stepStatus) => (
        <div>
            {stepStatus ? (
                <Checkmark className='step-status-circle-green' />
            ) : (
                <div className='display-nothing'></div>
            )}
        </div>
    );

    const handleReviewSubmission = async () => {
        if (!allStepsCompleted) return;

        try {
            const response = await csrfFetch(
                `/api/vendors/${vendor?.id}/submit-for-review`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.ok) {
                const data = await response.json();
                dispatch(setCurrentVendor(data));
                history.push(`/dashboard/setup?page=next-steps`);
            } else {
                throw new Error('Failed to submit for review.');
            }
        } catch (error) {
            console.error('Error submitting for review:', error);
            alert('Error submitting for review.');
        }
    };

    return (
        <div className='dashboard-setup-status-bar'>
            <div className='account-status-div'>
                <p>Account Status</p>
                <p
                    className={
                        listingStatus === 'under_review' ? 'active' : 'inactive'
                    }
                >
                    {snakeToTitleCase(listingStatus)}
                </p>
            </div>

            <div className='status-box'>
                {listingStatus === 'under_review' ? (
                    <ul className='under-review-status-bar'>
                        <div
                            className={`status-item ${
                                page === 'logo' && 'status-item-active'
                            }`}
                        >
                            <div className='status-item-content'>
                                {stepStatusCircle(logoStatus)}
                                <div className='name'>Logo</div>
                            </div>
                        </div>
                        <div
                            className={`status-item ${
                                page === 'images' && 'status-item-active'
                            }`}
                        >
                            <div className='status-item-content'>
                                {stepStatusCircle(imagesStatus)}
                                <div className='name'>Images</div>
                            </div>
                        </div>
                        <div
                            className={`status-item ${
                                page === 'calendar' && 'status-item-active'
                            }`}
                        >
                            <div className='status-item-content'>
                                {stepStatusCircle(calendarStatus)}
                                <div className='name'>Calendar</div>
                            </div>
                        </div>
                        <div
                            className={`status-item ${
                                page === 'payouts' && 'status-item-active'
                            }`}
                        >
                            <div className='status-item-content'>
                                {stepStatusCircle(payoutStatus)}
                                <div className='name'>Payouts</div>
                            </div>
                        </div>
                    </ul>
                ) : (
                    <ul>
                        <div
                            className={`status-item ${
                                page === 'logo' && 'status-item-active'
                            }`}
                            onClick={() => handleTabClick('logo')}
                        >
                            <div className='status-item-content'>
                                {stepStatusCircle(logoStatus)}
                                <div className='name'>Logo</div>
                            </div>
                        </div>
                        <div
                            className={`status-item ${
                                page === 'images' && 'status-item-active'
                            }`}
                            onClick={() => handleTabClick('images')}
                        >
                            <div className='status-item-content'>
                                {stepStatusCircle(imagesStatus)}
                                <div className='name'>Images</div>
                            </div>
                        </div>
                        <div
                            className={`status-item ${
                                page === 'calendar' && 'status-item-active'
                            }`}
                            onClick={() => handleTabClick('calendar')}
                        >
                            <div className='status-item-content'>
                                {stepStatusCircle(calendarStatus)}
                                <div className='name'>Calendar</div>
                            </div>
                        </div>
                        <div
                            className={`status-item ${
                                page === 'payouts' && 'status-item-active'
                            }`}
                            onClick={() => handleTabClick('payouts')}
                        >
                            <div className='status-item-content'>
                                {stepStatusCircle(payoutStatus)}
                                <div className='name'>Payouts</div>
                            </div>
                        </div>
                    </ul>
                )}

                <button
                    className={
                        !allStepsCompleted || listingStatus === 'under_review'
                            ? 'gray-out'
                            : ''
                    }
                    onClick={handleReviewSubmission}
                    disabled={
                        !allStepsCompleted || listingStatus === 'under_review'
                    }
                >
                    {listingStatus === 'under_review'
                        ? 'Submitted'
                        : 'Submit for Review'}
                </button>
            </div>
        </div>
    );
};

export default StatusBar;
