import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../Menu.css';
import { logout } from '../../../../../store/session';
import { ReactComponent as CloseIcon } from '../../../../../assets/svg/close.svg';
import SwitchViewToggle from '../../../SwitchViewToggle';
import { setHomeView } from '../../../../../store/ui';

const VendorMobileMenu = ({ isMobileMenuOpen, closeMobileMenu }) => {
  const homeView = useSelector((state) => state.ui?.homeView);
  const vendor = useSelector((state) => state.session.vendor);

  const dispatch = useDispatch();
  const history = useHistory();

  if (!isMobileMenuOpen) return null;

  const handleSignOut = () => {
    closeMobileMenu();
    dispatch(logout());
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
  };

  const handleTabClick = (name) => {
    closeMobileMenu();
    history.push(`/dashboard/${name.toLowerCase()}`);
    dispatch(setHomeView('vendor'));
  };

  return (
    <div className='menu-backdrop vendor-mobile-menu' onClick={closeMobileMenu}>
      <div className='menu-main' onClick={handleMenuClick}>
        <div className='close-and-toggle-div'>
          <div className='menu-close'>
            <CloseIcon onClick={closeMobileMenu} />
          </div>
          <SwitchViewToggle
            homeView={homeView}
            side={'user'}
            vendorId={vendor.id}
            closeMenu={closeMobileMenu}
          />
        </div>
        <ul>
          {vendor.listingStatus === 'active' || vendor.listingStatus === 'inactive' && (
            <>
              <li onClick={() => handleTabClick('scheduling')}>Scheduling</li>
              <li onClick={() => handleTabClick('pricing')}>Pricing</li>
              <li onClick={() => handleTabClick('general')}>General</li>
              <li onClick={() => handleTabClick('support')}>Support</li>
              <li onClick={() => handleTabClick('orders')}>Orders</li>
            </>
          )}
          {(vendor.listingStatus === 'awaiting_submission' ||
            vendor.listingStatus === 'under_review') && (
            <>
              <li onClick={() => handleTabClick('setup')}>Setup</li>
            </>
          )}
          <li onClick={handleSignOut}>Sign Out</li>
        </ul>
      </div>
    </div>
  );
};

export default VendorMobileMenu;
