import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ProviderDashboard from '../VendorDashboard';
import SessionVendorShow from './SessionVendorShow';
import { useEffect } from 'react';

const VendorView = () => {
    const history = useHistory();
    const homeView = useSelector((state) => state.ui?.homeView);

    const isVendor = useSelector((state) => state.session?.vendor);

    useEffect(() => {
        if (!isVendor) history.push('/home');
    }, [isVendor, history]);

    return (
        <div style={{ width: '100%', height: '100%' }}>
            {homeView === 'vendor' ? (
                <ProviderDashboard />
            ) : (
                <SessionVendorShow />
            )}
        </div>
    );
};

export default VendorView;
