import { setHomeView } from '../../../store/ui';
import './SwitchViewToggle.css';
import { useDispatch } from 'react-redux';

const SwitchViewToggle = ({ homeView, side, closeMenu }) => {
  const dispatch = useDispatch()

  const switchHomeView = () => {
      if (homeView === "vendor") {
          closeMenu?.()
          dispatch(setHomeView("user"));
      } else {
          closeMenu?.()
          dispatch(setHomeView("vendor"));
      }
  }

  return (
    <div className='toggle-div'>
      <span>Customer View</span>
      <div className={`toggle-switch ${homeView === side && "toggle-active"}`} onClick={switchHomeView}>
        <div className="slider"></div>
      </div>
    </div>
  );
};

export default SwitchViewToggle;
