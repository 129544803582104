import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { forgotPassword } from '../../../store/resetPassword';
import Errors from '../../common/Errors';
import './ForgotAndResetPassword.css';
import useLoading from '../../../hooks/useLoading';

const ForgotPassword = () => {
    const dispatch = useDispatch();

    const { isLoading, startLoading, stopLoading } = useLoading();
    const [email, setEmail] = useState('');
    const [successMsg, setSuccessMsg] = useState(null);

    const handleForgotPassword = () => {
        if (isLoading) return;

        startLoading();

        dispatch(forgotPassword(email, setSuccessMsg)).finally(() =>
            stopLoading()
        );
    };

    return (
        <div className='forgot-and-reset-password-container'>
            <div className='forgot-and-reset-password' id='forgot-password'>
                <h2>Reset Your Password</h2>
                <div className='email-input'>
                    <label>
                        Enter your email address and we'll send you a link to
                        get back into your account.
                    </label>
                    <input
                        type='text'
                        value={email}
                        placeholder='Email'
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                {successMsg && <p className='success-msg'>{successMsg}</p>}
                <Errors />
                <button
                    id='forgot-password-button'
                    onClick={handleForgotPassword}
                    disabled={email === ''}
                    className='gray-out'
                >
                    {isLoading ? 'Processing...' : 'Send Link'}
                </button>
            </div>
        </div>
    );
};

export default ForgotPassword;
