import { useDispatch } from 'react-redux';
import { ModalHeader } from '..';
import { closeModal } from '../../../../store/ui';
import './ConfirmModal.css';

export default function ConfirmModal({
    confirmationText,
    headerText,
    buttonText,
    onButtonClick,
}) {
    const dispatch = useDispatch();

    return (
        <div className='confirm-modal'>
            <div>
                <ModalHeader headerText={headerText} />
                <p className='confirmation-text'>{confirmationText}</p>
            </div>
            <div className='button-div'>
                <button className='cancel-button' onClick={() => dispatch(closeModal())}>Cancel</button>
                <button className='submit-action-button' onClick={onButtonClick}>{buttonText}</button>
            </div>
        </div>
    );
}
