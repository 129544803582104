import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import './VendorOrderIndexItem.css';
import { openModal } from '../../../../../store/ui';
import { ReactComponent as ThreeDotsSvg } from '../../../../../assets/svg/three-dots.svg';
import VendorOrderIndexItemDropdown from './VendorOrderIndexItemDropdown';
import VendorOrderIndexItemExpandedView from './VendorOrderIndexItemExpandedView';
import useDropdown from '../../../../../hooks/useDropdown';
import { snakeToTitleCase } from '../../../../../util/formatting';
import {
    getAvailableActions,
    getStatusColor,
} from '../../../../../util/orderHelpers';

const VendorOrderIndexItem = ({ order, expanded, onToggle }) => {
    const dispatch = useDispatch();

    const customer = order.customer;
    const formattedPrice = parseFloat(order?.vendorPayoutAmount).toFixed(2);
    const options = order?.optionsSnapshot ? order.optionsSnapshot : {};
    const currentVendor = useSelector((state) => state.session.vendor);
    const [fulfillmentStatus, setFulfillmentStatus] = useState(
        order?.fulfillmentStatus
    );
    const [paymentStatus, setPaymentStatus] = useState(order?.paymentStatus);
    const customerName = `${customer?.firstName} ${customer?.lastName}`;
    const phoneNumber = customer?.phoneNumber;
    const formattedPhoneNumber = phoneNumber
        ? phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
        : '';
    const { isDropdownOpen, toggleDropdown, dropdownRef } = useDropdown();

    let formattedDate = () => {
        const appointmentAtWithoutTimezone = order?.appointmentAt?.replace(
            /([-+]\d{2}:\d{2})/,
            ''
        );
        if (order?.appointmentAt) {
            return format(appointmentAtWithoutTimezone, 'MMM do, h:mmaaa');
        }
    };

    useEffect(() => {
        setFulfillmentStatus(order?.fulfillmentStatus);
        setPaymentStatus(order?.paymentStatus);
    }, [order]);

    const availableActions = getAvailableActions(
        paymentStatus,
        fulfillmentStatus,
        order.appointmentAt
    );

    const canMarkOrderAsComplete = () => availableActions.includes('complete');
    const canRescheduleOrder = () => availableActions.includes('reschedule');
    const canRefundOrder = () => availableActions.includes('partial_refund');
    const canCancelOrder = () => availableActions.includes('cancel');

    const handleRescheduleClick = () => {
        if (!canRescheduleOrder()) return;

        dispatch(openModal('reschedule-appointment', { order }));
    };

    const handleRefundClick = () => {
        if (!canRefundOrder()) return;

        dispatch(openModal('partial-refund', { order, customer }));
    };

    const handleCancelClick = () => {
        if (!canCancelOrder()) return;

        dispatch(
            openModal('order-cancellation-confirmation', {
                order,
                currentVendor,
                customerName,
            })
        );
    };

    const handleMarkAsComplete = () => {
        if (!canMarkOrderAsComplete() || fulfillmentStatus === 'fulfilled')
            return;

        dispatch(
            openModal('order-completion', {
                order,
                currentVendor,
                customerName,
            })
        );
    };

    return (
        <div
            id='vendor-order-index-item-container'
            className={`${expanded ? 'active' : ''}`}
        >
            <div className='vendor-order-index-item' onClick={onToggle}>
                <div className='vendor-order-index-item-column'>
                    <p>{order?.id}</p>
                </div>
                <div className='vendor-order-index-item-column'>
                    <p>{customerName}</p>
                </div>
                <div className='vendor-order-index-item-column'>
                    <p>{formattedDate()}</p>
                </div>
                <div className='vendor-order-index-item-column'>
                    <p style={{ color: getStatusColor(fulfillmentStatus) }}>
                        {snakeToTitleCase(fulfillmentStatus)}
                    </p>
                </div>
                <div className='vendor-order-index-item-column'>
                    <p style={{ color: getStatusColor(paymentStatus) }}>
                        {snakeToTitleCase(paymentStatus)}
                    </p>
                </div>
                <div className='vendor-order-index-item-column'>
                    <p>${formattedPrice}</p>
                </div>
                <div className='vendor-order-index-item-column'>
                    <div
                        className='three-dots-container'
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleDropdown(order.id);
                        }}
                        ref={dropdownRef}
                    >
                        <ThreeDotsSvg />
                        {isDropdownOpen && (
                            <VendorOrderIndexItemDropdown
                                canRescheduleOrder={canRescheduleOrder}
                                handleRescheduleClick={handleRescheduleClick}
                                canMarkOrderAsComplete={canMarkOrderAsComplete}
                                handleMarkAsComplete={handleMarkAsComplete}
                                canRefundOrder={canRefundOrder}
                                handleRefundClick={handleRefundClick}
                                canCancelOrder={canCancelOrder}
                                handleCancelClick={handleCancelClick}
                                fulfillmentStatus={fulfillmentStatus}
                            />
                        )}
                    </div>
                </div>
            </div>
            {expanded && (
                <VendorOrderIndexItemExpandedView
                    key={order.id}
                    order={order}
                    fulfillmentStatus={snakeToTitleCase(fulfillmentStatus)}
                    paymentStatus={snakeToTitleCase(paymentStatus)}
                    customerName={customerName}
                    formattedPrice={formattedPrice}
                    formattedDate={formattedDate}
                    formattedPhoneNumber={formattedPhoneNumber}
                    options={options}
                />
            )}
        </div>
    );
};

export default VendorOrderIndexItem;
