import { useState } from 'react';
import CheckoutForm from './CheckoutForm/CheckoutForm';
import './PaymentGateway.css';
import { useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import stripePromise from '../../../../util/stripe/stripeClient';
import csrfFetch from '../../../../store/csrf';
import PaymentConfirmation from '../PaymentConfirmation';
import { fetchCalendarData } from '../../../../store/vendor';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrderConfirmation } from '../../../../store/orders';
import { updateBooking } from '../../../../store/bookings';
import UserSessionForm from '../../../Session/UserSessionForm';
import { getActiveAddress } from '../../../../store/addresses';

const PaymentGateway = ({ props }) => {
    const { booking, resetComponents } = props;

    const dispatch = useDispatch();

    const isVendor = useSelector(
        (state) => state.session?.user?.userType === 'vendor'
    );
    const currentUser = useSelector((state) => state.session.user);
    const activeAddress = useSelector(getActiveAddress);

    const [clientSecret, setClientSecret] = useState('');
    const [price, setPrice] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState();
    const [loadingMessage, setLoadingMessage] = useState('Loading...');
    const [checkoutType, setCheckoutType] = useState('new');
    const [prevPaymentMethods, setPrevPaymentMethods] = useState([]);
    const [needsAccount, setNeedsAccount] = useState(false);

    useEffect(() => {
        if (currentUser) {
            const updatedBooking = {
                ...booking,
                userId: currentUser.id,
                addressId: activeAddress.id,
            };
            dispatch(updateBooking({ booking: updatedBooking }));
            getPaymentIntent(updatedBooking);
        }
    }, [dispatch, currentUser]);

    const getPaymentIntent = (booking) => {
        setLoadingMessage('Checking availability...');
        csrfFetch('/api/payments/create-payment-intent', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ booking }),
        })
            .then(async (response) => {
                if (!response.ok) {
                    const error_data = await response.json();
                    // throw new Error(res);
                    throw error_data;
                } else {
                    return response.json();
                }
            })
            .then((data) => {
                setClientSecret(data.clientSecret);
                setPrice(data.price);
                setLoadingMessage('Success');
                if (data.paymentMethods.length > 0) {
                    setCheckoutType('return');
                    setPrevPaymentMethods(data.paymentMethods);
                }
            })
            .catch((error) => {
                switch (error.error_type) {
                    case 'not_logged_in':
                        setLoadingMessage('Step 1 of 2');
                        setNeedsAccount(true);
                        break;
                    case 'appointment_unavailable':
                        setLoadingMessage(
                            'Appointment time is no longer available. Please select a different time.'
                        );
                        dispatch(
                            fetchCalendarData(
                                booking.vendorId,
                                isVendor,
                                booking.duration
                            )
                        );
                        break;
                    case 'invalid_price':
                        setLoadingMessage(error.message);
                        break;
                    default:
                        setLoadingMessage('Vendor Payment Setup Incomplete');
                }
                console.error('Failed to initialize payment gateway:', error);
            });
    };

    useEffect(() => {
        getPaymentIntent(booking);
    }, []);

    const handleStatusUpdate = (status) => {
        setPaymentStatus(status);
        if (status === 'succeeded') {
            dispatch(fetchOrderConfirmation(booking.id));
            resetComponents();
        }
    };

    const appearance = {
        theme: 'flat',

        variables: {
            colorPrimary: 'var(--primary-blue)',
            colorBackground: 'var(--secondary-white)',
            colorText: 'var(--primary-blue)',
            colorDanger: '#df1b41',
            fontFamily: 'Ideal Sans, system-ui, sans-serif',
            spacingUnit: '2px',
            borderRadius: '4px',
        },
        labels: 'floating',
        rules: {
            '.Label--floating': {
                color: 'var(--secondary-white)',
            },
            '.Input': {
                borderRadius: '4px',
                margin: '7px 0',
                boxSizing: 'border-box',
                padding: '5px 10px',
            },
            '.TermsText': {
                color: 'transparent',
                fontSize: '0px',
            },
        },
    };
    const options = {
        clientSecret,
        appearance,
    };

    return (
        <>
            {paymentStatus === 'succeeded' ? (
                <PaymentConfirmation confirmationId={booking.id} />
            ) : clientSecret ? (
                <>
                    <Elements options={options} stripe={stripePromise}>
                        <CheckoutForm
                            price={price}
                            booking={booking}
                            onStatusChange={handleStatusUpdate}
                            checkoutType={checkoutType}
                            prevPaymentMethods={prevPaymentMethods}
                            clientSecret={clientSecret}
                            changeCheckoutType={(type) => setCheckoutType(type)}
                        />
                    </Elements>
                </>
            ) : (
                <div id={`${needsAccount ? 'guest-sign-up-form' : ''}`}>
                    {!needsAccount && (
                        <div
                            className='checkout-form'
                            style={{
                                padding: '30px',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <h2>{loadingMessage}</h2>
                        </div>
                    )}
                    {needsAccount && (
                        <UserSessionForm
                            props={{
                                sessionType: 'Sign Up',
                                isOnGuestCheckout: true,
                                onGuestSignUpOrInSuccess: () =>
                                    setNeedsAccount(false),
                            }}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default PaymentGateway;
